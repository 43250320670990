import React, { useContext, useMemo } from 'react';
import { Platform } from 'react-native';
import Carousel from 'react-native-snap-carousel';
import { rem } from 'design-system/values';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import { triggerOnScrollEndHapticFeedback } from 'utils/hapticFeedback';
import { WalkthroughContext } from './walkthrough/context';
const FeedCardCarousel = ({ data, renderItem, onPositionChange, keyExtractor, rightOffset = 65, }) => {
    const { width: frameWidth, paddingHorizontal } = useAppFrameDimensions(true);
    const isInWalkthrough = useContext(WalkthroughContext);
    const padding = isInWalkthrough ? -rem(8) : paddingHorizontal;
    const width = frameWidth + (padding || 0) * 2;
    const itemWidth = useMemo(() => {
        const rightMargin = rem(rightOffset);
        return Platform.OS === 'android'
            ? frameWidth - rightMargin + rem(16)
            : frameWidth - rightMargin;
    }, [rightOffset, frameWidth]);
    return (<Carousel data={data} renderItem={renderItem} sliderWidth={width} firstItem={0} itemWidth={itemWidth} inactiveSlideOpacity={1} vertical={false} onSnapToItem={onPositionChange} containerCustomStyle={{
            paddingHorizontal: (padding || 0) + rem(16),
        }} onScrollIndexChanged={triggerOnScrollEndHapticFeedback} activeSlideAlignment="start" inactiveSlideScale={Platform.OS === 'android' ? 1 : 0.9} callbackOffsetMargin={Platform.OS === 'ios' ? 5 : 10} keyExtractor={keyExtractor} 
    // @ts-ignore
    disableIntervalMomentum/>);
};
export default FeedCardCarousel;
