import React from 'react';
import { StyleSheet, View } from 'react-native';
import Text from 'design-system/Text';
import Amount from 'design-system/Amount';
import { rem } from 'design-system/values';
import Squircle from 'design-system/Squircle';
import IconCloseSquircle from 'design-system/icons/IconCloseSquircle';
import FastImageCommon from 'common/FastImageCommon';
import IconCheck from 'features/feed/icons/IconCheck';
import useColors from 'hooks/useColors';
import useColorScheme from 'hooks/useColorScheme';
import { useAppSelector } from 'store/hooks';
import { interpolate } from 'utils/interpolations';
import { AnalyticsCardHeader } from './AnalyticsCardHeader';
import BudgetingCircleChart from './BudgetingCircleChart';
import BudgetingSetupButton from './BudgetingSetupButton';
const BudgetingCard = ({ thisMonth }) => {
    const paydayCountdown = useAppSelector((store) => store.feed.feed.cards.paydayCountdown);
    const thisMonthTotal = (thisMonth?.committed.amount || 0) + (thisMonth?.spending.amount || 0);
    const budget = thisMonth?.budget
        ? {
            amount: thisMonth?.budget,
            currency: thisMonth.income.currency,
        }
        : undefined;
    const spending = thisMonth?.spending;
    const totalSpending = {
        amount: thisMonthTotal,
        currency: thisMonth?.spending.currency,
    };
    const colors = useColors();
    const isDarkMode = useColorScheme() === 'dark';
    if (!budget) {
        return (<View style={styles.setupContainer} testID="budget-card">
        <Squircle width={rem(40)} height={rem(40)} color={colors.background.dark}>
          <FastImageCommon imageStyle={styles.helix} url={isDarkMode
                ? require('../../img/helix_dark.png')
                : require('../../img/helix.png')}/>
        </Squircle>
        <Text TextThin-12 Gray>
          Track your spending and save more, set up a budget.
        </Text>
        <BudgetingSetupButton />
      </View>);
    }
    let title = 'Budgeting';
    if (paydayCountdown) {
        const textDays = paydayCountdown.days === 1 ? 'day' : 'days';
        title = `${paydayCountdown.days} ${textDays} left`;
    }
    const isOnTarget = budget.amount >= totalSpending.amount;
    const interpolateConfig = {
        inputRange: [0, budget.amount],
        outputRange: [0, 100],
    };
    const budgetingCommitted = interpolate(totalSpending.amount, interpolateConfig);
    const budgetingSpent = interpolate(spending?.amount || 0, interpolateConfig);
    const amount = (<Amount Numbers-14 OffBlack showFraction={false} removeZero value={{
            value: Math.ceil(Math.abs(budget.amount - totalSpending.amount)),
            currency: totalSpending.currency,
        }}/>);
    return (<View testID="budget-card">
      <AnalyticsCardHeader title={title} icon={isOnTarget ? <IconCheck /> : <IconCloseSquircle alt/>} subTitle={isOnTarget ? <>{amount} Left of</> : <>{amount} Over by</>}/>
      <View style={styles.content}>
        <BudgetingCircleChart spendingPercent={budgetingSpent} committedPercent={budgetingCommitted}/>
        <View style={styles.totalContainer}>
          <Amount Numbers-16 showFraction={false} centered value={{
            value: Math.round(budget.amount),
            currency: budget.currency,
        }} removeZero/>
          <Text TextThin-12 Gray centered>
            Budget
          </Text>
        </View>
      </View>
    </View>);
};
const styles = StyleSheet.create({
    setupContainer: {
        flex: 1,
        justifyContent: 'space-between',
    },
    helix: {
        width: rem(25.78),
        height: rem(25.78),
    },
    content: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    totalContainer: {
        position: 'absolute',
    },
});
export default BudgetingCard;
