import React, { memo, useCallback, useMemo } from 'react';
import { Platform, View, } from 'react-native';
import Animated from 'react-native-reanimated';
import { Button } from 'design-system/Button';
import { SettingsItem, SettingsItemRightContent, SettingsItemSwitch, } from 'design-system/SettingsItem';
import { Indicator } from 'design-system/Indicator';
import { CardView } from 'design-system/CardView';
import { HelpCallToAction } from 'design-system/HelpCallToAction';
import { SCROLL_INDICATOR_INSETS, rem } from 'design-system/values';
import { Spacer } from 'design-system/Spacer';
import ListEmptyView from 'design-system/ListEmptyView';
import { IconArrow, IconArrowBack } from 'design-system/icons';
import useStyles from 'hooks/useStyles';
import { useAppSelector } from 'store/hooks';
import createStyleSheets from 'utils/createStyleSheets';
import { formatPlural } from 'utils/formatting';
import TouchableBounceCard from 'utils/packages/TouchableBounceCard';
import useShouldShowUpgrade from 'features/premium/hooks/useShouldShowUpgrade';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import { useMarginBottom } from 'hooks/useMarginBottom';
import { useMainStackNavigation } from 'utils/types/navigationV6';
import { IconBell } from 'design-system/IconBell';
import { selectInactiveSubscriptionsLength } from '../selectors';
import RowSubscription from './row/RowSubscription';
import SwitchingButton from './row/SwitchingButton';
import { isWeb } from '../../../constants';
import UpgradeToSeeMoreCard from './UpgradeToSeeMoreCard';
import { SUBSCRIPTIONS_ON_FREE_TIER } from '../constants';
const SCROLL_EVENT_THROTTLE = 10;
const keyExtractor = (item) => item.id.toString();
const SubscriptionsSection = ({ data, openSearch, openInfo, onOpenCompare, onOpenSubscription, onScroll, fetching, billRemindersOn, setBillRemindersOn, }) => {
    const guessedHomeCountry = useAppSelector((store) => store.user.user.guessedHomeCountry);
    const styles = useStyles(styleSet);
    const navigation = useMainStackNavigation();
    const { paddingHorizontalStyle } = useAppFrameDimensions(isWeb);
    const paddingBottom = useMarginBottom('paddingBottom', undefined, paddingHorizontalStyle, styles.contentContainer);
    const inactiveSubscriptionsLength = useAppSelector(selectInactiveSubscriptionsLength);
    const onInactiveSubsPress = useCallback(() => {
        navigation.navigate('InactivePayments');
    }, []);
    const { isUnlockedForSpace, onPressUpgrade } = useShouldShowUpgrade({
        benefitId: 'recurringPayments',
    });
    const inactiveSubscription = useMemo(() => {
        if (!inactiveSubscriptionsLength)
            return null;
        return (<>
        <Spacer h={16}/>
        <TouchableBounceCard onDark noMarginTop noMarginBottom onPress={onInactiveSubsPress} style={styles.inactivePaymentsCard}>
          <SettingsItem icon={<IconArrowBack gradientKey="red"/>} title={`${inactiveSubscriptionsLength} inactive ${formatPlural('payment', 'payments', inactiveSubscriptionsLength)}`} disabled>
            <SettingsItemRightContent>
              <View style={styles.row}>
                <IconArrow />
                <Spacer w={8}/>
              </View>
            </SettingsItemRightContent>
          </SettingsItem>
        </TouchableBounceCard>
      </>);
    }, [
        inactiveSubscriptionsLength,
        onInactiveSubsPress,
        styles.inactivePaymentsCard,
        styles.row,
    ]);
    const renderEmpty = useMemo(() => (<CardView noMarginBottom noMarginTop>
        <Spacer h={24}/>
        <ListEmptyView compact title="Nothing to show" subTitle="You don’t have any upcoming transactions">
          <Button hug medium brandReversed title="Add new" onPress={openSearch}/>
        </ListEmptyView>
        <Spacer h={24}/>
      </CardView>), [openSearch]);
    const renderFooter = useMemo(() => data.length ? (<View>
          {inactiveSubscription}
          {!!setBillRemindersOn && (<CardView noMarginBottom webPadding>
              <SettingsItemSwitch icon={<IconBell />} title="Bill reminders" value={billRemindersOn} onValueChange={setBillRemindersOn} description={'Receive reminders about \nupcoming payments'}/>
              <Spacer h={rem(8)}/>
            </CardView>)}
          <Spacer h={16}/>
          <HelpCallToAction onDark title="Need to amend your recurring payments?" subTitle="Learn how to do it" onPress={openInfo}/>
        </View>) : (<>
          {inactiveSubscription}
          <Spacer h={16}/>
          <HelpCallToAction onDark title="Need to amend your recurring payments?" subTitle="Learn how to do it" onPress={openInfo}/>
        </>), [
        data.length,
        setBillRemindersOn,
        billRemindersOn,
        inactiveSubscription,
        openInfo,
    ]);
    const showUpgradeCard = !isUnlockedForSpace && data.length > SUBSCRIPTIONS_ON_FREE_TIER;
    const renderItem = useCallback(({ item, index }) => {
        const card = SUBSCRIPTIONS_ON_FREE_TIER === index && showUpgradeCard;
        return (<>
          {card && <UpgradeToSeeMoreCard onPressUpgrade={onPressUpgrade}/>}
          <View style={[
                styles.rowSubscription,
                (index === 0 || card) && styles.itemContainerFirst,
                (index === data.length - 1 ||
                    (index === SUBSCRIPTIONS_ON_FREE_TIER - 1 &&
                        showUpgradeCard)) &&
                    styles.itemContainerLast,
            ]}>
            <RowSubscription data={item} onPress={onOpenSubscription} switchingButton={<SwitchingButton data={item} onOpenCompare={onOpenCompare} guessedHomeCountry={guessedHomeCountry}/>} blurValues={!isUnlockedForSpace && index >= SUBSCRIPTIONS_ON_FREE_TIER}/>
          </View>
        </>);
    }, [
        showUpgradeCard,
        styles.rowSubscription,
        styles.itemContainerFirst,
        styles.itemContainerLast,
        onPressUpgrade,
        data.length,
        onOpenSubscription,
        onOpenCompare,
        guessedHomeCountry,
        isUnlockedForSpace,
    ]);
    if (fetching && !data.length) {
        return <Indicator dark/>;
    }
    return (<Animated.FlatList onScroll={onScroll} renderItem={renderItem} data={data} keyExtractor={keyExtractor} ListEmptyComponent={renderEmpty} ListFooterComponent={renderFooter} scrollEventThrottle={SCROLL_EVENT_THROTTLE} scrollIndicatorInsets={SCROLL_INDICATOR_INSETS} contentContainerStyle={paddingBottom}/>);
};
const styleSet = createStyleSheets((colors) => ({
    rowSubscription: {
        marginHorizontal: rem(16),
        backgroundColor: colors.cards.onDark,
        ...(isWeb && {
            paddingHorizontal: rem(8),
        }),
    },
    inactivePaymentsCard: {
        ...(isWeb && {
            paddingHorizontal: rem(8),
        }),
    },
    itemContainerFirst: {
        borderTopLeftRadius: rem(16),
        borderTopRightRadius: rem(16),
        ...(Platform.OS !== 'android' && {
            overflow: 'hidden',
        }),
        ...(isWeb && {
            paddingTop: rem(8),
        }),
    },
    itemContainerLast: {
        borderBottomLeftRadius: rem(16),
        borderBottomRightRadius: rem(16),
        ...(Platform.OS !== 'android' && {
            overflow: 'hidden',
        }),
        ...(isWeb && {
            paddingBottom: rem(8),
        }),
    },
    row: {
        flexDirection: 'row',
    },
    addNewBtn: {
        paddingHorizontal: rem(22),
    },
    contentContainer: {
        paddingTop: rem(16),
    },
}));
export default memo(SubscriptionsSection);
