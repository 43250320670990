import React, { useLayoutEffect, useCallback } from 'react';
import { View, StyleSheet } from 'react-native';
import Animated from 'react-native-reanimated';
import Text from 'design-system/Text';
import Modal from 'design-system/Modal';
import Spacer from 'design-system/Spacer';
import { AppView } from 'design-system/AppView';
import HeaderImage from 'design-system/HeaderImage';
import Button, { HEIGHT } from 'design-system/Button';
import { ButtonGroup } from 'design-system/ButtonGroup';
import { rem, SCROLL_INDICATOR_INSETS } from 'design-system/values';
import withAnimatedNavigationHeader from 'design-system/hoc/withAnimatedNavigationHeader';
import { useMarginBottom } from 'hooks/useMarginBottom';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import AccountHelpButton from 'features/invest/components/AccountHelpButton';
import { useBudgetRollover } from 'features/budgeting/hooks/useBudgetRollover';
import { isWeb } from '../../../constants';
const strings = {
    title: 'Would you like your budgets to rollover?',
    description: 'The money you have not spent will be added to the next period.',
    rollingBudgets: 'Rolling Budgets',
    rollingBudgetsDescription: "Rolling budgets are a premium feature that makes every budget roll to the next period if they haven't been filled. This means if you haven't spent an amount in a cycle, it will be included in the next.",
};
const image = require('../img/rollover.png');
export default withAnimatedNavigationHeader(({ navigation, route, scrollHandler }) => {
    const { paddingHorizontalStyle } = useAppFrameDimensions(isWeb);
    useLayoutEffect(() => {
        if (!isWeb) {
            navigation.setOptions({
                headerRight: () => <AccountHelpButton black/>,
            });
        }
    }, []);
    const { editedMonthlyBudget, budgetState, shouldResetRollingAmount } = route.params;
    const { shouldUpgrade, setRollover } = useBudgetRollover();
    const navigateToConfirm = useCallback((shouldRollover) => {
        navigation.navigate('EditBudget', {
            flow: 'Confirm',
            keyBack: route.key,
            editedMonthlyBudget,
            shouldRollover,
            budgetState,
            shouldResetRollingAmount,
            period: route.params.period,
        });
    }, [
        budgetState,
        editedMonthlyBudget,
        route.key,
        route.params.period,
        shouldResetRollingAmount,
    ]);
    const onPress = useCallback(async () => {
        navigateToConfirm(false);
    }, [navigateToConfirm]);
    const onPressYes = useCallback(async () => {
        setRollover(true);
        if (!shouldUpgrade) {
            navigateToConfirm(true);
        }
    }, [setRollover, shouldUpgrade]);
    const showModal = () => {
        Modal.show(<>
          <Spacer h={8}/>
          <Text TextThin-14 Gray centered>
            {strings.rollingBudgetsDescription}
          </Text>
          <Spacer h={40}/>
          <Button brand title="Got it" onPress={() => {
                Modal.hide(undefined, { top: true });
            }}/>
        </>, { title: strings.rollingBudgets, top: true });
    };
    const paddingBottom = useMarginBottom('paddingBottom', HEIGHT * 2 + rem(16), styles.contentContainer, paddingHorizontalStyle);
    return (<AppView withFrame={false}>
        <Animated.ScrollView onScroll={scrollHandler} scrollEventThrottle={16} contentContainerStyle={paddingBottom} scrollIndicatorInsets={SCROLL_INDICATOR_INSETS}>
          <HeaderImage style={styles.headerImgBg} source={image} imgStyle={styles.headerImage} onDark/>
          <View style={styles.headerContainer}>
            <Text Text-24 style={styles.title}>
              {strings.title}
            </Text>
            <Text TextThin-14 Gray>
              {strings.description}{' '}
              <Text Text-14 Primary onPress={showModal}>
                Learn more
              </Text>
            </Text>
          </View>
        </Animated.ScrollView>
        <ButtonGroup type="floating">
          <Button brandReversed title="Yes, rollover" onPress={onPressYes}/>
          <Button brand title="No, I prefer fresh start each month" onPress={onPress}/>
        </ButtonGroup>
      </AppView>);
}, ({ colors }) => ({
    pageTitle: isWeb ? 'Set Rollover' : undefined,
    headerColorOutputRange: [
        colors.background.dark,
        colors.cards.onDark,
    ],
}));
const styles = StyleSheet.create({
    contentContainer: {
        paddingHorizontal: rem(16),
    },
    headerContainer: {
        paddingVertical: rem(16),
    },
    headerImgBg: {
        height: undefined,
    },
    headerImage: {
        width: '100%',
        aspectRatio: 1,
        height: undefined,
    },
    title: {
        marginBottom: rem(9),
    },
});
