import emoji from 'node-emoji';
import React, { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Platform, View } from 'react-native';
import { AppView } from 'design-system/AppView';
import Button from 'design-system/Button';
import Spacer from 'design-system/Spacer';
import { rem } from 'design-system/values';
import StatusBar from 'design-system/StatusBar';
import { styles as textStyles } from 'design-system/Text';
import TextInput from 'utils/packages/TextInput';
import useColors from 'hooks/useColors';
import { useMarginBottom } from 'hooks/useMarginBottom';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import { useAutoFocusInput } from 'hooks/useAutoFocusInput';
import KeyboardAvoidingView from 'design-system/KeyboardAvoidingView';
import AlertWeb from 'utils/packages/Alert';
import { isWeb } from '../../../constants';
const strings = {
    twitterTitle: 'X Handle',
    twitterPlaceholder: '@X_handle',
    next: 'Next',
    errorTitle: 'Slow down :face_with_rolling_eyes:',
    errorMessage: 'You must enter a X handle.',
};
const DummyAccountLogoScreen = ({ navigation, route }) => {
    const colors = useColors();
    const styles = useStyles(styleSet);
    useLayoutEffect(() => {
        navigation.setOptions({
            title: strings.twitterTitle,
            headerTintColor: colors.text.primary,
        });
    }, [colors.text.primary]);
    const [value, setValue] = useState('');
    const textInputRef = useRef(null);
    useAutoFocusInput(textInputRef);
    const onSubmit = useCallback(async () => {
        if (value.length < 2) {
            AlertWeb.alert(emoji.emojify(strings.errorTitle), strings.errorMessage);
            return;
        }
        const { params } = route;
        navigation.navigate('DummyAccountCreated', {
            ...params,
            iconProvider: 'TWITTER',
            iconProviderHandle: value,
        });
    }, [route, value]);
    const onChangeText = useCallback((text) => {
        setValue((text.charAt(0) === '@' ? text : `@${text}`).toLowerCase());
    }, []);
    useAutoFocusInput(textInputRef);
    const textInput = useMemo(() => (<TextInput ref={textInputRef} style={styles.textInput} value={value} placeholder={strings.twitterPlaceholder} placeholderTextColor={colors.text.placeholder} selectionColor={colors.text.selection} underlineColorAndroid="transparent" textAlign="center" autoCapitalize="none" onChangeText={onChangeText} onSubmitEditing={onSubmit} focusStyle={styles.textInputFocus}/>), [colors, styles, value, onChangeText, onSubmit]);
    const paddingBottom = useMarginBottom('paddingBottom');
    const button = useMemo(() => (<View style={[styles.buttonContainer, paddingBottom]}>
        <Button brand title={strings.next} onPress={onSubmit}/>
      </View>), [styles.buttonContainer, paddingBottom, onSubmit]);
    return (<AppView type={isWeb ? 'secondary' : 'primary'} isInTab={isWeb} avoidHeader={isWeb}>
      <StatusBar barStyle="dark-content" animated/>
      {Platform.OS === 'android' ? (<View style={styles.container}>
          <Spacer flex/>
          {textInput}
          <Spacer flex/>
          {button}
        </View>) : (<KeyboardAvoidingView style={styles.container} behavior="padding">
          {isWeb ? <Spacer h={72}/> : <Spacer flex/>}
          {textInput}
          <Spacer flex/>
          {button}
        </KeyboardAvoidingView>)}
    </AppView>);
};
const styleSet = createStyleSheets((colors) => ({
    container: {
        flex: 1,
        ...(isWeb && {
            paddingHorizontal: rem(16),
        }),
    },
    header: {
        backgroundColor: 'transparent',
    },
    buttonContainer: {
        paddingHorizontal: isWeb ? 0 : rem(16),
    },
    textInputFocus: {
        color: colors.text.primary,
    },
    textInput: {
        ...(isWeb
            ? {
                paddingVertical: rem(16),
                color: colors.text.secondary,
            }
            : {
                ...textStyles.TextThin_20,
                alignSelf: 'stretch',
                color: colors.text.primary,
                fontSize: rem(26),
                lineHeight: undefined,
            }),
    },
    centered: {
        alignSelf: 'center',
    },
}));
export default DummyAccountLogoScreen;
