import React, { memo } from 'react';
import { StyleSheet, Text } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import { rem } from 'design-system';
import { API_DEFAULT_CATEGORY_COLOR, useCategories, useCustomCategoryColors, } from 'hooks/useCategory';
import IconCategoryBusiness from './categories/IconCategoryBusiness';
import IconCategoryCash from './categories/IconCategoryCash';
import IconCategoryCharity from './categories/IconCategoryCharity';
import IconCategoryEatingOut from './categories/IconCategoryEatingOut';
import IconCategoryEducation from './categories/IconCategoryEducation';
import IconCategoryEntertainment from './categories/IconCategoryEntertainment';
import IconCategoryIncome from './categories/IconCategoryIncome';
import IconCategoryFamily from './categories/IconCategoryFamily';
import IconCategoryGeneral from './categories/IconCategoryGeneral';
import IconCategoryGroceries from './categories/IconCategoryGroceries';
import IconCategoryPersonalCare from './categories/IconCategoryPersonalCare';
import IconCategoryHolidays from './categories/IconCategoryHolidays';
import IconCategoryHousing from './categories/IconCategoryHousing';
import IconCategoryInvestments from './categories/IconCategoryInvestments';
import IconCategoryShopping from './categories/IconCategoryShopping';
import IconCategoryTransport from './categories/IconCategoryTransport';
import IconCategoryExcluded from './categories/IconCategoryExcluded';
import IconCategoryBills from './categories/IconCategoryBills';
const IconTransactionCategory = ({ category, style, width = rem(24), height = rem(24), color, emojiBackgroundColor, children, }) => {
    const categories = useCategories();
    const customCategoryColors = useCustomCategoryColors();
    const currentCategory = category
        ? categories[category.id]
        : undefined;
    const props = {
        width,
        height,
        style,
        viewBox: '0 0 25 24',
        fill: color || currentCategory?.gradientColors?.[0],
    };
    switch (category?.id) {
        case 'bills':
            return <IconCategoryBills {...props}/>;
        case 'business':
            return <IconCategoryBusiness {...props}/>;
        case 'cash':
            return <IconCategoryCash {...props}/>;
        case 'charity':
            return <IconCategoryCharity {...props}/>;
        case 'eatingout':
            return <IconCategoryEatingOut {...props} viewBox="0 0 25 25"/>;
        case 'education':
            return <IconCategoryEducation {...props}/>;
        case 'entertainment':
            return <IconCategoryEntertainment {...props}/>;
        case 'family':
            return <IconCategoryFamily {...props}/>;
        case 'general':
            return <IconCategoryGeneral {...props}/>;
        case 'groceries':
            return <IconCategoryGroceries {...props}/>;
        case 'health':
            return <IconCategoryPersonalCare {...props}/>;
        case 'holidays':
            return <IconCategoryHolidays {...props}/>;
        case 'housing':
            return <IconCategoryHousing {...props}/>;
        case 'income':
            return <IconCategoryIncome {...props}/>;
        case 'internal':
            return <IconCategoryExcluded {...props}/>;
        case 'investments':
            return <IconCategoryInvestments {...props}/>;
        case 'shopping':
            return <IconCategoryShopping {...props}/>;
        case 'transport':
            return <IconCategoryTransport {...props}/>;
        case 'custom1':
            return null;
        default:
            let backgroundColor = emojiBackgroundColor
                ? [emojiBackgroundColor, emojiBackgroundColor]
                : undefined;
            if (!backgroundColor && category?.color) {
                backgroundColor = customCategoryColors.find((c) => c[0] === category.color)?.[2];
                // Server default to #4481eb if no color set, we want gray instead on new app
                if (!backgroundColor &&
                    category?.color !== API_DEFAULT_CATEGORY_COLOR) {
                    backgroundColor = [category.color, category.color];
                }
            }
            return (<>
          {backgroundColor && (<LinearGradient style={styles.background} colors={backgroundColor}/>)}
          {category?.emoji ? (<Text style={[
                        styles.emoji,
                        {
                            fontSize: Number(width) * 0.8,
                            lineHeight: Number(width),
                        },
                    ]}>
              {category.emoji}
            </Text>) : null}
          {children}
        </>);
    }
};
const styles = StyleSheet.create({
    emoji: {
        fontFamily: 'System',
        textAlign: 'center',
        color: 'black',
    },
    background: {
        width: '100%',
        position: 'absolute',
        height: '100%',
        borderRadius: 100,
    },
});
export default memo(IconTransactionCategory);
