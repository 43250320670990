import { REHYDRATE } from 'redux-persist';
import { unique } from 'utils/unique';
import { LOGGED_OUT } from 'actions/types';
import { DELETE_RENT_REPORTING_BY_ID_SUCCESS, DIMISSED_UPGRADE_FOR_MORE_RENT_REPORTING_AGENCIES, GET_RENT_REPORTING_BY_ID_REQUEST, GET_RENT_REPORTING_BY_ID_SUCCESS, GET_RENT_REPORTING_REQUEST, GET_RENT_REPORTING_SUCCESS, GET_RENT_REPORTING_SUGGESTED_PAYMENTS_FAILURE, GET_RENT_REPORTING_SUGGESTED_PAYMENTS_REQUEST, GET_RENT_REPORTING_SUGGESTED_PAYMENTS_SUCCESS, PATCH_RENT_REPORTING_BY_ID_SUCCESS, SET_UP_RENT_REPORTING_SUCCESS, } from '../actions/types';
const initialState = {
    data: undefined,
    hasDismissedUpgradeForMoreAgencies: undefined,
};
const rentReportingReducer = (state = initialState, action) => {
    switch (action.type) {
        case REHYDRATE: {
            if (action.payload && action.payload.rentReporting) {
                return {
                    ...initialState,
                    ...action.payload.rentReporting,
                    suggestedPayments: undefined,
                    data: action.payload.rentReporting.data
                        ? {
                            ...action.payload.rentReporting.data,
                            current: action.payload.rentReporting.data.current
                                ? {
                                    ...action.payload.rentReporting.data.current,
                                    rentTransactions: undefined,
                                }
                                : null,
                            past: undefined,
                        }
                        : undefined,
                    isLoadingRentReportingData: undefined,
                };
            }
            return state;
        }
        case LOGGED_OUT: {
            return initialState;
        }
        case GET_RENT_REPORTING_REQUEST:
            return {
                ...state,
                isLoadingRentReportingData: true,
            };
        case GET_RENT_REPORTING_SUCCESS:
            if (action.payload.current) {
                const currentFromState = state.data?.current || undefined;
                const current = {
                    ...currentFromState,
                    ...action.payload.current,
                    rentTransactions: !action.extra.withRentTransactions
                        ? currentFromState?.rentTransactions
                        : action.payload.current.rentTransactions,
                };
                return {
                    ...state,
                    data: {
                        ...state.data,
                        ...action.payload,
                        current,
                        past: action.extra.isActive
                            ? state.data?.past
                            : action.payload.past,
                    },
                    suggestedPayments: undefined,
                    isLoadingRentReportingData: false,
                };
            }
            return {
                ...state,
                data: {
                    ...state.data,
                    ...action.payload,
                },
                suggestedPayments: undefined,
                isLoadingRentReportingData: false,
            };
        case GET_RENT_REPORTING_BY_ID_REQUEST:
            return {
                ...state,
                data: {
                    ...state.data,
                    current: state.data?.current?.id === action.extra.id
                        ? { ...state.data?.current, rentTransactions: [] }
                        : state.data?.current || null,
                },
            };
        case GET_RENT_REPORTING_BY_ID_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    current: state.data?.current?.id === action.payload?.id
                        ? action.payload
                        : state.data?.current || null,
                    past: state.data?.past?.map((data) => data.id === action.payload?.id ? action.payload : data),
                },
            };
        case DELETE_RENT_REPORTING_BY_ID_SUCCESS: {
            return {
                ...state,
                data: {
                    ...state.data,
                    current: null,
                    past: state.data?.current
                        ? [state.data.current, ...(state.data.past || [])]
                        : state.data?.past,
                },
                suggestedPayments: undefined,
            };
        }
        case SET_UP_RENT_REPORTING_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    current: action.payload.rentReporting,
                    past: state.data?.current
                        ? [state.data.current, ...(state.data.past || [])]
                        : state.data?.past,
                },
            };
        case PATCH_RENT_REPORTING_BY_ID_SUCCESS: {
            return {
                ...state,
                data: {
                    ...state.data,
                    current: {
                        ...state.data?.current,
                        ...action.payload.rentReporting,
                    },
                },
            };
        }
        case GET_RENT_REPORTING_SUGGESTED_PAYMENTS_REQUEST:
            return {
                ...state,
                suggestedPayments: action.extra.page === 1
                    ? {
                        isLoading: true,
                    }
                    : {
                        ...state.suggestedPayments,
                        isLoading: true,
                    },
            };
        case GET_RENT_REPORTING_SUGGESTED_PAYMENTS_FAILURE:
            return {
                ...state,
                suggestedPayments: {
                    ...state.suggestedPayments,
                    isLoading: false,
                },
            };
        case GET_RENT_REPORTING_SUGGESTED_PAYMENTS_SUCCESS:
            return {
                ...state,
                suggestedPayments: {
                    isLoading: false,
                    transactions: action.payload.paging.page === 1 ||
                        !state.suggestedPayments?.transactions
                        ? action.payload.transactions
                        : unique(state.suggestedPayments.transactions.concat(action.payload.transactions), 'id'),
                    paging: action.payload.paging,
                },
            };
        case DIMISSED_UPGRADE_FOR_MORE_RENT_REPORTING_AGENCIES:
            return {
                ...state,
                hasDismissedUpgradeForMoreAgencies: true,
            };
        default:
            return state;
    }
};
export default rentReportingReducer;
