import React from 'react';
import Svg, { Defs, LinearGradient, Path, Stop } from 'react-native-svg';
import useColors from 'hooks/useColors';
const IconSaveTab = ({ color, notificationDot }) => {
    const colors = useColors();
    return (<Svg width={24} height={24} viewBox="0 0 24 24">
      <Path d="M10.3669 2.34777C9.7127 1.93188 8.9364 1.69094 8.10384 1.6908C8.69521 0.964166 9.59669 0.5 10.6066 0.5C10.9141 0.5 11.2116 0.543047 11.4934 0.623446C10.9875 1.09163 10.5963 1.68205 10.3669 2.34777Z" fill={color ?? 'url(#orange)'}/>
      <Path d="M10.1373 3.72518C10.1373 3.62317 10.1409 3.52201 10.148 3.42181C9.59145 2.96499 8.8793 2.6908 8.1031 2.6908C6.70834 2.6908 5.52037 3.57616 5.07053 4.81554C5.10953 4.81413 5.1487 4.81342 5.18803 4.81342H10.2787C10.1864 4.46621 10.1373 4.10144 10.1373 3.72518Z" fill={color ?? 'url(#orange)'}/>
      <Path d="M17.3994 4.81342C17.5212 4.47344 17.5876 4.10706 17.5876 3.72518C17.5876 1.94396 16.1437 0.5 14.3624 0.5C12.5812 0.5 11.1373 1.94396 11.1373 3.72518C11.1373 4.10706 11.2036 4.47344 11.3255 4.81342H17.3994Z" fill={color ?? 'url(#orange)'}/>
      <Path d="M3.17973 10.4775C2.62348 11.5171 2.31303 12.6756 2.31303 13.8969C2.31303 16.2443 3.45991 18.3596 5.29454 19.8448L4.6273 21.6056C4.40895 22.1818 4.6453 22.8311 5.18299 23.1321C5.73801 23.4428 6.438 23.2848 6.80578 22.7658L7.8346 21.314C9.01404 21.7767 10.3235 22.0343 11.7023 22.0343C13.0842 22.0343 14.3964 21.7756 15.5778 21.311L16.2038 22.7516C16.4494 23.3167 17.0717 23.617 17.6669 23.4575C18.2813 23.2928 18.6726 22.6913 18.574 22.0629L18.2128 19.7604C19.9874 18.28 21.0916 16.2002 21.0916 13.8969C21.0916 12.6111 20.7475 11.395 20.1348 10.3139C19.5632 10.8566 18.7905 11.1895 17.9402 11.1895H5.18804C4.42682 11.1895 3.72792 10.9227 3.17973 10.4775Z" fill={color ?? 'url(#yellow)'}/>
      <Path d="M5.18803 5.81342H17.9402C19.1486 5.81342 20.1282 6.79303 20.1282 8.00145C20.1282 9.20986 19.1486 10.1895 17.9402 10.1895H5.18804C3.97962 10.1895 3 9.20986 3 8.00145C3 6.79303 3.97962 5.81342 5.18803 5.81342Z" fill={color ?? 'url(#yellow)'}/>
      {notificationDot && (<Path d="M19 9.5C21.4853 9.5 23.5 7.48528 23.5 5C23.5 2.51472 21.4853 0.5 19 0.5C16.5147 0.5 14.5 2.51472 14.5 5C14.5 7.48528 16.5147 9.5 19 9.5Z" fill={colors.text.negative} stroke={colors.background.light}/>)}

      <Defs>
        <LinearGradient id="orange" x1="11.3291" y1="0.5" x2="11.3291" y2="4.81554" gradientUnits="userSpaceOnUse">
          <Stop stopColor={colors.gradients.orange.colors[0]}/>
          <Stop offset="1" stopColor={colors.gradients.orange.colors[1]}/>
        </LinearGradient>
        <LinearGradient id="yellow" x1="2.99738" y1="23.5" x2="21.0916" y2="23.5" gradientUnits="userSpaceOnUse">
          <Stop stopColor={colors.gradients.plus.colors[0]}/>
          <Stop offset="1" stopColor={colors.gradients.plus.colors[1]}/>
        </LinearGradient>
      </Defs>
    </Svg>);
};
export default IconSaveTab;
