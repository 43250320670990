import { NavigationContainer, getPathFromState as defaultGetPathFromState, } from '@react-navigation/native';
import { Provider } from 'react-redux';
import React, { useEffect } from 'react';
import { StyleSheet, View } from 'react-native';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { PersistGate } from 'redux-persist/integration/react';
import Modal from 'design-system/ModalWeb';
import AlertModalWeb from 'design-system/AlertModalWeb';
import PopUpMenuModal from 'design-system/PopUpMenuModal.web';
import WebNavigationBar from 'design-system/WebNavigationBar';
import MultiSelectAccountModal from 'design-system/MultiSelectAccountModal/index.web';
import WebStack from 'navigators/WebNavigator';
import configureStore from 'store/configureStore';
import { navigationRef } from 'utils/navigationv6';
import { setCurrentRouteName } from 'actions/utils';
import { initializeApi } from 'features/invest/api/account';
import { linking as mobileAppLinking, parseId } from 'hooks/useAppLink';
import ErrorModal from './common/ErrorModal';
const { store, persistor } = configureStore();
const getTransactionRoutePath = (state, routeName = 'Item') => {
    const transactionRoute = state.routes.find((route) => route.name === routeName);
    if (transactionRoute?.path) {
        return transactionRoute?.path;
    }
    return '';
};
const linking = {
    prefixes: [],
    config: {
        screens: {
            Feed: {
                path: '',
            },
            Analytics: mobileAppLinking.config?.screens.Analytics,
            Budgeting: mobileAppLinking.config?.screens.Budgeting,
            Support: mobileAppLinking.config?.screens.Support,
            AccountsOverview: mobileAppLinking.config?.screens.AccountsOverview,
            Subscriptions: mobileAppLinking.config?.screens.Subscriptions,
            Transactions: mobileAppLinking.config?.screens.Transactions,
            CommittedList: mobileAppLinking.config?.screens.CommittedList,
            NotificationsList: mobileAppLinking.config?.screens.NotificationsList,
            DailyBalanceNotifications: mobileAppLinking.config?.screens.DailyBalanceNotifications,
            TransactionsList: mobileAppLinking.config?.screens.TransactionsList,
            Item: mobileAppLinking.config?.screens.Item,
            Category: mobileAppLinking.config?.screens.Category,
            MonthlyCategory: mobileAppLinking.config?.screens.MonthlyCategory,
            InactivePayments: mobileAppLinking.config?.screens.InactivePayments,
            Subscription: mobileAppLinking.config?.screens.Subscription,
            AccountSettings: mobileAppLinking.config?.screens.AccountSettings,
            AccountSettingsList: mobileAppLinking.config?.screens.AccountSettingsList,
            PreferenceSettingsList: mobileAppLinking.config?.screens.PreferenceSettingsList,
            AboutUs: mobileAppLinking.config?.screens.AboutUs,
            ImportantStuff: mobileAppLinking.config?.screens.ImportantStuff,
            PersonalDetails: mobileAppLinking.config?.screens.PersonalDetails,
            ChangePasscode: mobileAppLinking.config?.screens.ChangePasscode,
            Notifications: mobileAppLinking.config?.screens.Notifications,
            RenameTransaction: {
                path: 'transactions/:id/rename',
                parse: parseId,
            },
            EditDate: {
                path: 'transactions/:id/edit-date',
                parse: parseId,
            },
            AddNote: {
                path: 'transactions/:id/add-note',
                parse: parseId,
            },
            SplitTransaction: {
                path: 'transactions/:id/split',
                parse: parseId,
            },
            SearchTransactions: mobileAppLinking.config?.screens.SearchTransactions,
            EditAccountName: mobileAppLinking.config?.screens.EditAccountName,
            EditOverdraft: mobileAppLinking.config?.screens.EditOverdraft,
            SavingGoalEdit: mobileAppLinking.config?.screens.SavingGoalEdit,
            AnalyticsIntro: mobileAppLinking.config?.screens.AnalyticsIntro,
            SelectMerchant: mobileAppLinking.config?.screens.SelectMerchant,
            EditMerchantBudgets: mobileAppLinking.config?.screens.EditMerchantBudgets,
            EditMerchantBudget: mobileAppLinking.config?.screens.EditMerchantBudget,
            UnbudgetedSpend: mobileAppLinking.config?.screens.UnbudgetedSpend,
            DummyAccountSetup: mobileAppLinking.config?.screens.DummyAccountSetup,
            DummyAccountCreated: mobileAppLinking.config?.screens.DummyAccountCreated,
            DummyAccountLogo: mobileAppLinking.config?.screens.DummyAccountLogo,
            DummyAccountLogoType: mobileAppLinking.config?.screens.DummyAccountLogoType,
            AddBalance: mobileAppLinking.config?.screens.AddBalance,
            AddAnEmoji: mobileAppLinking.config?.screens.AddAnEmoji,
            OfflineAccounts: mobileAppLinking.config?.screens.OfflineAccounts,
            PickAccountType: mobileAppLinking.config?.screens.PickAccountType,
            Property: mobileAppLinking.config?.screens.Property,
            NotFound: '*',
        },
    },
    getPathFromState(state, options) {
        if (state?.routes && state?.index && state?.routes.length > 1) {
            const currentRoute = state.routes[state.routes.length - 1];
            if (currentRoute.name === 'SuccessSplit' ||
                currentRoute.name === 'Success') {
                return '/success';
            }
            if (currentRoute.name === 'AddTags') {
                const basePath = getTransactionRoutePath(state);
                return `${basePath}?edit-tags=true`;
            }
            if (currentRoute.name === 'EditMultipleTransactionsTags') {
                const basePath = getTransactionRoutePath(state);
                return `${basePath}?edit-tags=true&selectTransactions=true`;
            }
            if (currentRoute.name === 'EditMultipleTransactionsRename') {
                const basePath = getTransactionRoutePath(state, 'EditMultipleTransactionsRename');
                return `${basePath}?select-transactions=true`;
            }
            if (currentRoute.name === 'LearningScreen') {
                const basePath = getTransactionRoutePath(state);
                return `${basePath}?success=true`;
            }
            if (currentRoute.name === 'EditCategory') {
                return `/pick-category`;
            }
            if (currentRoute.name === 'EditMultipleTransactions') {
                return `/pick-category?select-transactions=true`;
            }
            if (currentRoute.name === 'ManageCategories') {
                return `/manage-categories`;
            }
            if (currentRoute.name === 'CustomCategoryEdit') {
                const transactionRoute = state.routes.find((route) => route.name === 'CustomCategoryEdit');
                if (transactionRoute?.params) {
                    return `/manage-categories/edit`;
                }
                return `/manage-categories/create`;
            }
            if (currentRoute.name === 'CustomCategoryCreated') {
                return `/manage-categories/created`;
            }
            if (currentRoute.name === 'SubscriptionsSearch') {
                return '/search/recurring-payment';
            }
            if (currentRoute.name === 'CorrectedAmount') {
                return '/adjust-amount';
            }
            if (currentRoute.name === 'CorrectedDate') {
                return '/adjust-date';
            }
            if (currentRoute.name === 'PredictionFixed') {
                return '/confirm-prediction';
            }
            if (currentRoute.name === 'RenameSubscription') {
                return '/recurring-payment/rename';
            }
            if (currentRoute.name === 'AdjustSubscription') {
                return '/recurring-payment/add';
            }
            if (currentRoute.name === 'SearchTransactions') {
                return '/search-transactions';
            }
            if (currentRoute.name === 'OneMillionNetWorth') {
                return '/one-million-net-worth';
            }
            if (currentRoute.name === 'EditBudgetFrequency') {
                return '/select-budgeting-frequency';
            }
            if (currentRoute.name === 'HolidaySelection') {
                return '/select-budgeting-frequency';
            }
            if (currentRoute.name === 'SelectDate') {
                return '/select-date';
            }
            if (currentRoute.name === 'SelectPeriod') {
                return '/select-budgeting-period';
            }
            if (currentRoute.name === 'EditBudget') {
                const routeParams = currentRoute.params;
                if (routeParams.flow === 'Set') {
                    return '/set-budget';
                }
                if (routeParams.flow === 'Confirm') {
                    return '/confirm-budget';
                }
                return '/edit-budget';
            }
            if (currentRoute.name === 'Rollover') {
                return '/set-rollover';
            }
            if (currentRoute.name === 'Budgets') {
                const routeParams = currentRoute.params;
                if (routeParams.flow === 'Set') {
                    return '/set-category-budgets';
                }
                if (routeParams.flow === 'Confirm') {
                    return '/confirm-category-budgets';
                }
                return '/edit-category-budget';
            }
            if (currentRoute.name === 'TrueBalance') {
                // Adding there as this shouldnt be accessible to everyone
                return '/true-balance';
            }
        }
        // Default behavior for all other cases
        return defaultGetPathFromState(state, options);
    },
};
const WebRoot = () => {
    useEffect(() => {
        initializeApi(store.dispatch);
    }, []);
    const handleNavStateChange = (navigationState) => {
        const route = navigationState?.routes[navigationState?.index];
        if (route) {
            const { routeName, routeParams } = (() => {
                if (route.state) {
                    return route.state.index && route.state.routes
                        ? {
                            routeName: route.state.routes[route.state.index].name,
                            routeParams: route.state.routes[route.state.index].params,
                        }
                        : { routeName: route.name, routeParams: route.params };
                }
                return { routeName: route.name, routeParams: route.params };
            })();
            store.dispatch(setCurrentRouteName(routeName));
            if (__DEV__) {
                const msg = `CURRENT ROUTE: ${routeName}`;
                const params = `CURRENT PARAMS: ${JSON.stringify(routeParams, null, 2)}`;
                log('====================ROUTE===CHANGE===START====================', false, 'blue');
                log(msg, false, 'blue');
                log(params, false, 'blue');
                log('=====================ROUTE===CHANGE===END=====================', false, 'blue');
            }
        }
    };
    return (<Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <SafeAreaProvider>
          <NavigationContainer linking={linking} ref={navigationRef} documentTitle={{
            formatter: (options, route) => `${options?.title || route?.name} - Emma App`,
        }} onStateChange={handleNavStateChange}>
            <View style={styles.main}>
              <WebNavigationBar />
              <WebStack />

              <MultiSelectAccountModal />
              <PopUpMenuModal />
              <Modal />
              <Modal top/>
              <AlertModalWeb />
              <ErrorModal />
              <Modal top/>
            </View>
          </NavigationContainer>
        </SafeAreaProvider>
      </PersistGate>
    </Provider>);
};
const styles = StyleSheet.create({
    main: {
        flex: 1,
        flexDirection: 'row',
    },
});
export default WebRoot;
