import { FlashList } from '@shopify/flash-list';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, } from 'react';
import { FlatList, StyleSheet, View } from 'react-native';
import { AppView } from 'design-system/AppView';
import ListLoader from 'design-system/ListLoader';
import SectionHeader from 'design-system/SectionHeader';
import ListEmptyView from 'design-system/ListEmptyView';
import { IconSettings } from 'design-system/icons/IconSettings';
import { rem, SCROLL_INDICATOR_INSETS } from 'design-system/values';
import withAnimatedNavigationHeader from 'design-system/hoc/withAnimatedNavigationHeader';
import NavigationHeaderRightIconButton from 'design-system/NavigationHeaderRightIconButton';
import useAppLink from 'hooks/useAppLink';
import { useMarginBottom } from 'hooks/useMarginBottom';
import useEstimatedListSize from 'hooks/useEstimatedListSize';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import { useAppDispatch, useAppSelector, useAppStore } from 'store/hooks';
import NotificationBadge from '../components/NotificationBadge';
import NotificationItem from '../components/NotificationItem';
import { selectNotificationsListIsFetching, selectNotificationsListPage, selectNotificationsListPageCount, selectNotificationsListSections, } from '../selectors';
import { isWeb } from '../../../constants';
import { getNotifications, resetExternalUnreadNotifications } from '../actions';
const keyExtractor = (item) => item.id.toString();
const getItemType = (item) => 'sectionTitle' in item ? 'sectionHeader' : 'row';
const NotificationsListScreen = withAnimatedNavigationHeader(({ navigation, onScroll }) => {
    useLayoutEffect(() => {
        const onOpenNotifications = () => navigation.navigate('Notifications');
        navigation.setOptions({
            headerRight: () => (<NavigationHeaderRightIconButton onPress={onOpenNotifications}>
              <IconSettings colorKey="primary"/>
            </NavigationHeaderRightIconButton>),
        });
    }, []);
    const store = useAppStore();
    const notifications = useAppSelector(selectNotificationsListSections);
    const isFetching = useAppSelector(selectNotificationsListIsFetching);
    const shouldLoadSecondPage = useRef(false);
    const initialNotificationLength = useRef(notifications?.length);
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(resetExternalUnreadNotifications());
        dispatch(getNotifications(1));
    }, []);
    const loadMoreData = useCallback(() => {
        const page = selectNotificationsListPage(store.getState());
        const pageCount = selectNotificationsListPageCount(store.getState());
        if (page < pageCount) {
            dispatch(getNotifications(page + 1));
        }
    }, []);
    useEffect(() => {
        if (!isFetching && shouldLoadSecondPage.current) {
            shouldLoadSecondPage.current = false;
            loadMoreData();
        }
    }, [isFetching, loadMoreData]);
    const onEndReached = useCallback(() => {
        if (isFetching) {
            if (initialNotificationLength.current) {
                shouldLoadSecondPage.current = true;
            }
        }
        else {
            loadMoreData();
        }
    }, [isFetching, loadMoreData]);
    const emptyOrLoadingView = useMemo(() => isFetching ? (<ListLoader />) : (<View style={styles.sectionContainer}>
              <ListEmptyView happy onCard title="All clear!" subTitle="You have no notifications!"/>
            </View>), [isFetching]);
    const { paddingHorizontalStyle } = useAppFrameDimensions(isWeb);
    const contentContainerStyles = useMarginBottom('paddingBottom', 0, styles.contentContainerStyle, paddingHorizontalStyle);
    const renderHeader = useCallback(() => {
        if (isWeb) {
            return null;
        }
        return <NotificationBadge />;
    }, []);
    const { appLinkTo } = useAppLink();
    const renderItem = useCallback(({ item }) => {
        if ('sectionTitle' in item) {
            return (<SectionHeader horizontalMargins style={styles.sectionHeader}>
                {item.sectionTitle}
              </SectionHeader>);
        }
        return (<NotificationItem item={item} style={styles.notificationRow} onPress={appLinkTo}/>);
    }, [appLinkTo]);
    const estimatedListSize = useEstimatedListSize(notifications);
    const baseProps = {
        onScroll,
        data: notifications,
        renderItem,
        scrollEventThrottle: 16,
        keyExtractor,
        onEndReached,
        ListHeaderComponent: renderHeader,
        ListEmptyComponent: emptyOrLoadingView,
        contentContainerStyle: contentContainerStyles,
        scrollIndicatorInsets: SCROLL_INDICATOR_INSETS,
    };
    return (<AppView withFrame={false}>
          {isWeb ? (<FlatList {...baseProps}/>) : (<FlashList {...baseProps} estimatedItemSize={50} getItemType={getItemType} estimatedListSize={estimatedListSize}/>)}
        </AppView>);
}, ({ colors }) => ({
    title: 'Notifications',
    headerColorOutputRange: [
        colors.background.dark,
        colors.cards.onDark,
    ],
}));
const styles = StyleSheet.create({
    sectionContainer: {
        flex: 1,
        width: '100%',
    },
    sectionHeader: {
        marginBottom: rem(16),
    },
    notificationRow: {
        marginBottom: rem(16),
        marginHorizontal: rem(16),
    },
    contentContainerStyle: {
        paddingTop: rem(16),
    },
});
export default NotificationsListScreen;
