import React from 'react';
import Svg, { Defs, LinearGradient, Path, Stop } from 'react-native-svg';
import useColors from 'hooks/useColors';
const IconInvestTab = ({ size, color, notificationDot, gradientColors: gradientColorProps }) => {
    const colors = useColors();
    const fill = color ?? 'url(#gradient)';
    const gradientColor = gradientColorProps ?? colors.gradients.darkPurple.colors;
    const iconSize = size || 24;
    return (<Svg width={iconSize} height={iconSize} viewBox="0 0 24 24">
      {notificationDot ? (<>
          <Path fillRule="evenodd" clipRule="evenodd" d="M12 1C13.1723 1 14.2478 1.08737 15.228 1.26359C14.7648 2.06948 14.5 3.00381 14.5 4C14.5 5.34787 14.9849 6.5825 15.7897 7.53899C14.9555 7.64946 13.5611 8.12841 12.9514 8.47175C12.9467 8.4681 12.5613 8.68594 12.4369 8.83553L12.421 8.85603C12.238 9.09155 12.2013 9.41601 12.3309 9.6973C12.3664 9.77417 12.4597 9.89403 12.5444 9.99582L12.7059 10.186C12.9802 10.473 13.4321 10.8732 13.9538 11.2787L14.0798 11.3757C14.581 11.7583 15.0486 12.0736 15.3704 12.2406C15.3754 12.2445 15.6985 12.4051 15.8194 12.4413C15.9976 12.4893 16.191 12.4758 16.3627 12.4039C16.4939 12.3415 16.6037 12.2543 16.6911 12.1418C16.7558 12.0501 16.8247 11.8696 16.8683 11.7365L16.9023 11.6242C17.0976 10.9516 17.2043 9.53627 17.1314 8.69356C17.9666 9.20512 18.9488 9.5 20 9.5C20.9962 9.5 21.9305 9.23515 22.7364 8.77203C22.9126 9.75218 23 10.8277 23 12C23 19.4612 19.4612 23 12 23C4.53882 23 1 19.4612 1 12C1 4.53882 4.53882 1 12 1ZM7.1684 12.8043C7.41633 12.5015 7.8565 12.4424 8.17547 12.6692L10.4028 14.2531L11.9625 12.3478C12.2228 12.0298 12.6916 11.983 13.0096 12.2434C13.3277 12.5037 13.3744 12.9725 13.1141 13.2906L11.1141 15.7338C10.8661 16.0367 10.426 16.0958 10.107 15.8689L7.87963 14.2851L6.32001 16.1903C6.05968 16.5084 5.59084 16.5551 5.27282 16.2948C4.9548 16.0345 4.90803 15.5656 5.16836 15.2476L7.1684 12.8043Z" fill={fill}/>
          <Path d="M24 4C24 6.20914 22.2091 8 20 8C17.7909 8 16 6.20914 16 4C16 1.79086 17.7909 0 20 0C22.2091 0 24 1.79086 24 4Z" fill={colors.text.negative}/>
        </>) : (<Path fillRule="evenodd" clipRule="evenodd" d="M1 12C1 4.53882 4.53882 1 12 1C19.4612 1 23 4.53882 23 12C23 19.4612 19.4612 23 12 23C4.53882 23 1 19.4612 1 12ZM15.0798 11.3757L14.9538 11.2786C14.4321 10.8732 13.9802 10.473 13.7059 10.186L13.5444 9.99581C13.4597 9.89401 13.3664 9.77416 13.3309 9.69728C13.2013 9.416 13.2379 9.09154 13.421 8.85601L13.4369 8.83552C13.5613 8.68593 13.9467 8.46809 13.9514 8.47173C14.5725 8.12197 16.0079 7.63146 16.8359 7.53315L17.081 7.50809C17.1465 7.50253 17.2223 7.49806 17.2766 7.50085C17.4489 7.50324 17.6151 7.56664 17.7494 7.67104C17.8993 7.78759 18.003 7.94196 18.0494 8.11759L18.0911 8.37147L18.0989 8.42711C18.2277 9.2003 18.1201 10.8741 17.9023 11.6242L17.8682 11.7365C17.8246 11.8696 17.7558 12.0501 17.6911 12.1418C17.6037 12.2543 17.4939 12.3415 17.3627 12.4039C17.191 12.4758 16.9976 12.4893 16.8194 12.4413C16.6985 12.4051 16.3754 12.2445 16.3703 12.2406C16.0486 12.0736 15.581 11.7583 15.0798 11.3757ZM8.16839 12.8043C8.41631 12.5014 8.85648 12.4424 9.17546 12.6692L11.4028 14.2531L12.9624 12.3478C13.2228 12.0298 13.6916 11.983 14.0096 12.2434C14.3276 12.5037 14.3744 12.9725 14.1141 13.2905L12.114 15.7338C11.8661 16.0367 11.426 16.0957 11.107 15.8689L8.87962 14.2851L7.32 16.1903C7.05967 16.5084 6.59083 16.5551 6.27281 16.2948C5.95479 16.0345 5.90802 15.5656 6.16835 15.2476L8.16839 12.8043Z" fill={fill}/>)}

      <Defs>
        <LinearGradient id="gradient" x1="12" y1="1" x2="12" y2="23" gradientUnits="userSpaceOnUse">
          <Stop stopColor={gradientColor[0]}/>
          <Stop offset="1" stopColor={gradientColor[1]}/>
        </LinearGradient>
      </Defs>
    </Svg>);
};
export default IconInvestTab;
