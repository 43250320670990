/**
 * Reducer for holding data related to the static collections.
 *
 * This reducer should not be persisted.
 *
 * If we decide to make it persist between app sessions, please move it to invest.
 */
import { LOGGED_OUT } from 'actions/types';
import { assertUnreachable } from 'utils/types';
const initialState = {
    highEarners: [],
    mostWatched: [],
    dividendYield: [],
    highestMarketCap: [],
    biggestEmployers: [],
};
const collectionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_STATIC_COLLECTION_STOCKS_SUCCESS': {
            const { type } = action.extra;
            const { assets } = action.payload;
            let key = '';
            switch (type) {
                case 'HIGHEST_EARNERS':
                    key = 'highEarners';
                    break;
                case 'HIGHEST_NUMBER_OF_EMPLOYEES':
                    key = 'biggestEmployers';
                    break;
                case 'HIGHEST_DIVIDEND_YIELD':
                    key = 'dividendYield';
                    break;
                case 'HIGHEST_MARKET_CAP':
                    key = 'highestMarketCap';
                    break;
                case 'MOST_WATCHED':
                    key = 'mostWatched';
                    break;
                default:
                    break;
            }
            if (key) {
                return {
                    ...state,
                    [key]: assets.map((asset) => asset.symbol),
                };
            }
            return state;
        }
        case LOGGED_OUT: {
            return initialState;
        }
        default:
            assertUnreachable(action);
            return state;
    }
};
export default collectionsReducer;
