import React, { useLayoutEffect } from 'react';
import { ScrollView, StyleSheet } from 'react-native';
import { Indicator } from 'design-system/Indicator';
import { rem } from 'design-system/values';
import { SettingsSection } from 'design-system/SettingsSection';
import { SettingsItem } from 'design-system/SettingsItem';
import { AppView } from 'design-system/AppView';
import withAnimatedNavigationHeader from 'design-system/hoc/withAnimatedNavigationHeader';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import { useAppSelector } from 'store/hooks';
import { selectFeatureFlag } from 'reducers/selectors';
import Links from 'utils/links';
import { HelpButton } from './AccountSettingsScreen';
import { isWeb } from '../../../constants';
const strings = {
    sectionName: 'Important stuff',
    policy: {
        title: 'Privacy Policy',
    },
    terms: {
        title: 'Main T&Cs',
    },
    support: {
        title: 'Support',
    },
};
const EMPTY_LINKS = [];
export const ImportantStuff = withAnimatedNavigationHeader(({ navigation, onScroll }) => {
    const userId = useAppSelector((state) => state.user.user.id);
    useLayoutEffect(() => {
        if (!isWeb) {
            navigation.setOptions({
                headerRight: () => <HelpButton />,
            });
        }
    }, []);
    const extraTerms = useAppSelector((store) => selectFeatureFlag(store, 'terms').extra?.links || EMPTY_LINKS);
    const { paddingHorizontalStyle } = useAppFrameDimensions(isWeb);
    if (userId === -1) {
        return (<AppView type="secondary" withFrame={false}>
          <Indicator dark/>
        </AppView>);
    }
    return (<AppView withFrame={false}>
        <ScrollView onScroll={onScroll} style={paddingHorizontalStyle} scrollEventThrottle={16}>
          <SettingsSection noMarginTop style={styles.marginTop}>
            <SettingsItem title={strings.terms.title} onPress={Links.openEmmaTerms}/>
            <SettingsItem title={strings.policy.title} onPress={Links.openEmmaPrivacy}/>
            {extraTerms.map((term) => (<SettingsItem title={term.title} onPress={() => Links.openLink(term.url)}/>))}
          </SettingsSection>
        </ScrollView>
      </AppView>);
}, ({ colors }) => ({
    title: strings.sectionName,
    headerColorOutputRange: [
        colors.background.dark,
        colors.cards.onDark,
    ],
}));
const styles = StyleSheet.create({
    marginTop: {
        marginTop: rem(16),
    },
});
