import { SET_DID_ONBOARD_NETWORTH, SET_FEED_PERSONALIZATION, SET_LAST_REVIEW_SESSION_COUNT, SET_DID_CLOSE_POTS_CTA_FROM_POTS, SET_DID_CLOSE_PUSH_NOTIFICATION_CARD, SET_DID_CLOSE_POTS_CTA_FROM_ANALYTICS, SET_DID_CLOSE_POTS_CTA_FROM_BUDGETING, SET_DID_CLOSE_POTS_CTA_FROM_TRANSACTIONS, SET_DEVICE_SETTINGS_KEY, SET_DID_CLOSE_UPGRADE_NETWORTH, SET_TRANSACTION_FILTERS, } from 'actions/types/deviceSettings';
import { BUDGETING_PERIOD_CHANGED, LOGGED_OUT } from 'actions/types';
import { CREATE_TRADING_ACCOUNT_SUCCESS } from 'features/invest/actions/types';
export const initialState = {
    didClosePushNotificationCard: false,
    lastReviewSessionCount: undefined,
    didCloseOpenPotsCTAFromPots: false,
    didCloseOpenPotsCTAFromAnalytics: false,
    didCloseOpenPotsCTAFromBudgeting: false,
    didCloseOpenPotsCTAFromTransactions: false,
    feedPersonalization: undefined,
    didViewEmmaWalletIntro: false,
    didViewFraudDetectionIntro: false,
    didViewSmartRulesIntro: false,
    didCloseSmartRulesCard: false,
    didViewTrueBalanceIntro: false,
    didOnboardNetworth: false,
    didCloseUpgradeNetWorthCTA: false,
    didOpenPayTab: false,
    didOpenSaveTab: false,
    didOpenInvestTab: false,
    didOpenUpgradeTab: false,
    didOpenBorrowTab: false,
    didCloseConsolidateDebtBanner: false,
    didCloseUpgradeFXCTAInInvest: false,
    didCloseUpgradePotsInterestCTAInInvest: false,
    didSetPeriod: false,
    hasTriedSettingsUpPots: false,
    hasTriedSettingsUpInvest: false,
    didViewSellStopOrderIntro: false,
    didViewSellLimitOrderIntro: false,
    transactionFilters: {
        selectedAccountsObj: {},
        showExcludedTxns: true,
    },
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_TRADING_ACCOUNT_SUCCESS:
        case 'CLOSE_USER_TRADING_ACCOUNT_SUCCESS_ACTION': {
            return {
                ...state,
                hasTriedSettingsUpInvest: false,
            };
        }
        case SET_DID_CLOSE_PUSH_NOTIFICATION_CARD:
            return { ...state, didClosePushNotificationCard: true };
        case SET_DID_ONBOARD_NETWORTH:
            return { ...state, didOnboardNetworth: true };
        case SET_DID_CLOSE_UPGRADE_NETWORTH:
            return { ...state, didCloseUpgradeNetWorthCTA: true };
        case SET_LAST_REVIEW_SESSION_COUNT:
            return {
                ...state,
                lastReviewSessionCount: action.lastReviewSessionCount,
            };
        case SET_DID_CLOSE_POTS_CTA_FROM_ANALYTICS: {
            return {
                ...state,
                didCloseOpenPotsCTAFromAnalytics: true,
            };
        }
        case SET_DID_CLOSE_POTS_CTA_FROM_BUDGETING: {
            return {
                ...state,
                didCloseOpenPotsCTAFromBudgeting: true,
            };
        }
        case SET_DID_CLOSE_POTS_CTA_FROM_TRANSACTIONS: {
            return {
                ...state,
                didCloseOpenPotsCTAFromTransactions: true,
            };
        }
        case SET_DID_CLOSE_POTS_CTA_FROM_POTS: {
            return {
                ...state,
                didCloseOpenPotsCTAFromPots: true,
            };
        }
        case SET_FEED_PERSONALIZATION: {
            return {
                ...state,
                feedPersonalization: action.feedPersonalization,
            };
        }
        case SET_DEVICE_SETTINGS_KEY: {
            if (action.key && action.value !== undefined) {
                return {
                    ...state,
                    [action.key]: action.value,
                };
            }
            return state;
        }
        case BUDGETING_PERIOD_CHANGED: {
            return {
                ...state,
                didSetPeriod: true,
            };
        }
        case SET_TRANSACTION_FILTERS:
            return {
                ...state,
                transactionFilters: action.filters,
            };
        case LOGGED_OUT:
            return initialState;
        default:
            return state;
    }
};
export default reducer;
