import React from 'react';
import { StyleSheet } from 'react-native';
import Animated, { useAnimatedScrollHandler } from 'react-native-reanimated';
import { AppView } from 'design-system/AppView';
import { rem } from 'design-system/values';
import StatusBar from 'design-system/StatusBar';
import { Indicator } from 'design-system/Indicator';
import withAnimatedNavigationHeader from 'design-system/hoc/withAnimatedNavigationHeader';
import { ContactDetailsSection } from 'features/settings/personal-details/sections/ContactDetailsSection';
import { PersonalDetailsSection } from 'features/settings/personal-details/sections/PersonalDetailsSection';
import { SocialLoginSection } from 'features/settings/personal-details/sections/SocialLoginSection';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import { useAppSelector } from 'store/hooks';
import { isWeb } from '../../../constants';
import { Header } from './sections/PersonalDetailsHeader';
const strings = {
    headerTitle: 'Edit Profile',
};
const sections = [
    PersonalDetailsSection,
    ContactDetailsSection,
    SocialLoginSection,
];
const webSections = [PersonalDetailsSection, ContactDetailsSection];
export const PersonalDetailsScreen = withAnimatedNavigationHeader(({ scrollY }) => {
    const userId = useAppSelector((state) => state.user.user.id);
    const scrollHandler = useAnimatedScrollHandler((handle) => {
        // eslint-disable-next-line no-param-reassign
        scrollY.value = handle.contentOffset.y;
    });
    const { paddingHorizontalStyle } = useAppFrameDimensions(isWeb);
    if (userId === -1) {
        return (<AppView type="secondary" withFrame={false}>
          <Indicator dark/>
        </AppView>);
    }
    return (<AppView type="secondary" withFrame={false}>
        <StatusBar barStyle="dark-content" animated/>
        <Animated.FlatList data={isWeb ? webSections : sections} scrollEventThrottle={16} ListHeaderComponent={<Header />} contentContainerStyle={[styles.listContainer, paddingHorizontalStyle]} renderItem={({ item: Section }) => <Section />} onScroll={scrollHandler} keyExtractor={(item) => item.name}/>
      </AppView>);
}, ({ colors }) => ({
    title: strings.headerTitle,
    hideTitleOnScroll: true,
    headerColorOutputRange: [
        colors.background.dark,
        colors.cards.onDark,
    ],
}));
const styles = StyleSheet.create({
    listContainer: {
        paddingBottom: rem(24),
    },
});
