import React, { useCallback } from 'react';
import { View } from 'react-native';
import { IconEmail, IconHome, IconPhone, IconShieldDone, } from 'design-system/icons';
import { SettingsItemTitle, SettingsItemContent, SettingsItemNavigate, SettingsItemDescription, } from 'design-system/SettingsItem';
import { SettingsSection } from 'design-system/SettingsSection';
import VerificationStatusView from 'design-system/VerificationStatusView';
import { formatAddress } from 'utils';
import { useUserAddress } from 'hooks';
import useColors from 'hooks/useColors';
import { useAppSelector } from 'store/hooks';
import { useReSelector } from 'utils/hooksApi';
import useTrustedContacts, { getDisplayName, } from 'features/invest/hooks/useTrustedContacts';
import { selectIsGBUser } from 'reducers/selectors';
import { useMainStackNavigation } from 'utils/types/navigationV6';
import AddressVerificationStatusView from 'design-system/AddressVerificationStatusView';
import { Spacer } from 'design-system';
import { isWeb } from '../../../../constants';
const strings = {
    contactDetails: 'Contact Details',
    email: 'Email',
    emailVerified: 'Email Verified',
    addressVerified: 'Address Verified',
    verifyYourEmail: 'Verify your email',
    verifyYourAddress: 'Verify your address',
    enterEmail: 'Choose..',
    phoneNumber: 'Phone Number',
    addPhoneNumber: 'Choose..',
    addAddress: 'Choose..',
    address: 'Address',
    trustedContact: 'Trusted Contact',
    add: 'Add',
};
export const ContactDetailsSection = () => {
    const colors = useColors();
    const { navigate } = useMainStackNavigation();
    const userCountry = useAppSelector((state) => state.user.user.guessedHomeCountry);
    const { primaryAddress } = useUserAddress();
    const isGBUser = useAppSelector(selectIsGBUser);
    const [trustedContacts] = useTrustedContacts();
    const { email, phoneNumber, isEmailVerified } = useReSelector((store) => ({
        email: store.user.user.email,
        phoneNumber: store.user.user.phoneNumber,
        isEmailVerified: store.user.user.isEmailVerified,
    }));
    const changeEmail = useCallback(() => {
        navigate('ChangeDetail', { key: 'email' });
    }, []);
    const changePhoneNumber = useCallback(() => {
        navigate('PhoneVerification', {});
    }, []);
    const changeAddress = useCallback(() => {
        if (isGBUser) {
            if (primaryAddress) {
                navigate('AddressInfoWithStatusScreen');
            }
            else {
                navigate('ChangeAddressProgressScreen', {
                    flow: 'ADD',
                });
            }
        }
        else {
            navigate('EditAddress');
        }
    }, [isGBUser, primaryAddress]);
    const changeTrustedContact = useCallback(() => {
        navigate('EditTrustedContact');
    }, []);
    const addressName = primaryAddress ? formatAddress(primaryAddress) : null;
    if (isWeb &&
        !email &&
        !phoneNumber &&
        (!(userCountry === 'GB' || userCountry === 'US') || !primaryAddress) &&
        !trustedContacts) {
        return null;
    }
    return (<SettingsSection name={strings.contactDetails}>
      {!!(!isWeb || email) && (<SettingsItemNavigate disabled={isWeb} icon={<IconEmail />} onPress={changeEmail}>
          <SettingsItemContent>
            <SettingsItemDescription>{strings.email}</SettingsItemDescription>
            <SettingsItemTitle>{email || strings.enterEmail}</SettingsItemTitle>
            <VerificationStatusView verified={isEmailVerified} verifiedText={strings.emailVerified} verifyText={strings.verifyYourEmail}/>
          </SettingsItemContent>
        </SettingsItemNavigate>)}

      {!!(!isWeb || phoneNumber) && (<SettingsItemNavigate disabled={isWeb} icon={<IconPhone gradientKey="green"/>} description={strings.phoneNumber} title={phoneNumber || strings.addPhoneNumber} onPress={changePhoneNumber}/>)}

      {(userCountry === 'GB' || userCountry === 'US') &&
            !(isWeb && !primaryAddress) && (<View testID="AddressView">
            <SettingsItemNavigate disabled={isWeb} icon={<IconHome gradientKey="ultimate"/>} onPress={changeAddress}>
              <SettingsItemContent>
                <SettingsItemDescription>
                  {strings.address}
                </SettingsItemDescription>
                <SettingsItemTitle numberOfLines={1}>
                  {addressName || strings.addAddress}
                </SettingsItemTitle>
                {isGBUser && !!primaryAddress && (<>
                    <Spacer h={4}/>
                    <AddressVerificationStatusView testID="AddressVerificationStatus" status={primaryAddress.kycStatus}/>
                  </>)}
              </SettingsItemContent>
            </SettingsItemNavigate>
          </View>)}
      {trustedContacts !== undefined &&
            (!isWeb || trustedContacts.length > 0) && (<SettingsItemNavigate disabled={isWeb} icon={<IconShieldDone color={colors.gradients.green.colors[0]}/>} description={strings.trustedContact} onPress={changeTrustedContact} title={trustedContacts && trustedContacts[0]
                ? getDisplayName(trustedContacts[0])
                : strings.add}/>)}
    </SettingsSection>);
};
