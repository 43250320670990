import React, { useEffect } from 'react';
import { StyleSheet, View } from 'react-native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { Crisp } from 'crisp-sdk-web';
import ErrorBoundaries from 'common/ErrorBoundaries';
import withPrivateRoute from 'design-system/hoc/withPrivateRoute';
import { SelectDateScreen } from 'design-system/screens/SelectDateScreen';
import { SuccessScreen } from 'design-system/screens/SuccessScreen';
import { useNativeStackScreenOptions } from 'hooks/navigation/useStackNavigatorScreenOptions';
import { TrueBalanceScreen } from 'features/accounts';
import AccountsOverviewScreen from 'features/accounts/screens/AccountsOverviewScreen';
import AccountTransactionsScreen from 'features/accounts/screens/AccountTransactionsScreen';
import AddBalanceScreen from 'features/accounts/screens/AddBalanceScreen';
import DummyAccountCreatedScreen from 'features/accounts/screens/DummyAccountCreatedScreen';
import DummyAccountLogoScreen from 'features/accounts/screens/DummyAccountLogoScreen';
import DummyAccountLogoTypeScreen from 'features/accounts/screens/DummyAccountLogoTypeScreen';
import DummyAccountSetupScreen from 'features/accounts/screens/DummyAccountSetupScreen';
import EditAccountNameScreen from 'features/accounts/screens/EditAccountNameScreen';
import EditOverdraftScreen from 'features/accounts/screens/EditOverdraftScreen';
import OfflineAccountsScreen from 'features/accounts/screens/OfflineAccountsScreen';
import PickAccountTypeScreen from 'features/accounts/screens/PickAccountTypeScreen';
import PickPropertyTypeScreen from 'features/accounts/screens/PickPropertyTypeScreen';
import AnalyticsIntroScreen from 'features/analytics/screens/AnalyticsIntroScreen';
import AnalyticsScreen from 'features/analytics/screens/AnalyticsScreen';
import CategoryDetailsScreen from 'features/analytics/screens/CategoryDetailsScreen';
import BudgetingScreen from 'features/budgeting/screens/BudgetingScreen';
import EditBudgetFrequencyScreen from 'features/budgeting/screens/EditBudgetFrequencyScreen';
import EditBudgetScreen from 'features/budgeting/screens/EditBudgetScreen';
import EditMerchantBudgetScreen from 'features/budgeting/screens/EditMerchantBudgetScreen';
import EditMerchantBudgetsScreen from 'features/budgeting/screens/EditMerchantBudgetsScreen';
import HolidaySelectionScreen from 'features/budgeting/screens/HolidaySelectionScreen';
import MonthlyCategoryScreen from 'features/budgeting/screens/MonthlyCategoryScreen';
import RolloverScreen from 'features/budgeting/screens/RolloverScreen';
import SelectMerchantScreen from 'features/budgeting/screens/SelectMerchantScreen';
import SelectPeriodScreen from 'features/budgeting/screens/SelectPeriodScreen';
import SetCategoryBudgetsScreen from 'features/budgeting/screens/SetCategoryBudgetsScreen';
import UnbudgetedSpendScreen from 'features/budgeting/screens/UnbudgetedSpendScreen';
import AddAnEmojiScreen from 'features/emoji-picker/screens/AddAnEmojiScreen';
import OneMillionNetWorth from 'features/net-worth/screens/OneMillionNetWorthScreen';
import NotificationsListScreen from 'features/notifications/screens/NotificationsListScreen';
import SavingGoalEditScreen from 'features/saving-goals/SavingGoalEditScreen';
import SearchTransactions from 'features/search/SearchTransactions';
import { AboutUs } from 'features/settings/accounts-settings/AboutUs';
import { AccountSettingsList } from 'features/settings/accounts-settings/AccountSettingsList';
import { AccountSettingsScreen } from 'features/settings/accounts-settings/AccountSettingsScreen';
import ChangePasscode from 'features/settings/accounts-settings/ChangePasscode';
import { ImportantStuff } from 'features/settings/accounts-settings/ImportantStuff';
import { PreferenceSettingsList } from 'features/settings/accounts-settings/PreferenceSettingsList';
import { NotificationsScreen } from 'features/settings/notifications/NotificationsScreen';
import DailyBalanceNotificationsScreen from 'features/settings/notifications/screens/DailyBalanceNotificationsScreen';
import { PersonalDetailsScreen } from 'features/settings/personal-details/PersonalDetailsScreen';
import AdjustSubscriptionScreen from 'features/subscriptions/screens/AdjustSubscriptionScreen';
import CommittedSpendingScreen from 'features/subscriptions/screens/CommittedSpendingScreen';
import CorrectedAmountScreen from 'features/subscriptions/screens/CorrectedAmountScreen';
import CorrectedDateScreen from 'features/subscriptions/screens/CorrectedDateScreen';
import CorrectedPredictionScreen from 'features/subscriptions/screens/CorrectedPredictionScreen';
import InactiveSubscriptionsScreen from 'features/subscriptions/screens/InactiveSubscriptionsScreen';
import RenameSubscriptionScreen from 'features/subscriptions/screens/RenameSubscriptionScreen';
import SubscriptionScreen from 'features/subscriptions/screens/SubscriptionScreen';
import SubscriptionSearchModalScreen from 'features/subscriptions/screens/SubscriptionSearchModalScreen';
import SubscriptionsScreen from 'features/subscriptions/screens/SubscriptionsScreen';
import SupportModal from 'features/support/screens/SupportModal';
import AddNoteScreen from 'features/transactions/screens/AddNoteScreen';
import AddTagsScreen from 'features/transactions/screens/AddTagsScreen';
import CustomCategoryCreatedScreen from 'features/transactions/screens/CustomCategoryCreatedScreen';
import CustomCategoryEditScreen from 'features/transactions/screens/CustomCategoryEditScreen';
import EditCategoryScreen from 'features/transactions/screens/EditCategoryScreen';
import EditCompleteScreen from 'features/transactions/screens/EditCompleteScreen';
import EditDateScreen from 'features/transactions/screens/EditDateScreen';
import EditMultipleTransactionsRename from 'features/transactions/screens/EditMultipleTransactionsRename';
import EditMultipleTransactionsScreen from 'features/transactions/screens/EditMultipleTransactionsScreen';
import EditMultipleTransactionsTagsScreen from 'features/transactions/screens/EditMultipleTransactionsTagsScreen';
import ManageCategoriesScreen from 'features/transactions/screens/ManageCategoriesScreen';
import RenameTransactionScreen from 'features/transactions/screens/RenameTransactionScreen';
import SplitTransactionScreen from 'features/transactions/screens/SplitTransactionScreen';
import SuccessSplitScreen from 'features/transactions/screens/SuccessSplitScreen';
import TransactionScreen from 'features/transactions/screens/TransactionScreen';
import TransactionsScreen from 'features/transactions/screens/TransactionsScreen';
import NotFound from 'features/web/NotFound';
import HomeScreen from 'features/web/screens/HomeScreen';
import Config from 'utils/packages/configs';
const Stack = createNativeStackNavigator();
const getId = ({ params }) => Number(params.id).toFixed();
const WebStack = () => {
    useEffect(() => {
        Crisp.configure(Config.CRISP_ID, { autoload: false });
    }, []);
    const screenOptions = useNativeStackScreenOptions();
    return (<ErrorBoundaries>
      <View style={styles.flex}>
        <Stack.Navigator screenOptions={screenOptions}>
          <Stack.Group>
            <Stack.Screen component={withPrivateRoute(HomeScreen, { requiresRedirect: false })} name="Feed"/>
            <Stack.Screen component={withPrivateRoute(BudgetingScreen)} name="Budgeting"/>
            <Stack.Screen component={withPrivateRoute(NotificationsListScreen)} name="NotificationsList"/>
            <Stack.Screen component={withPrivateRoute(MonthlyCategoryScreen)} name="MonthlyCategory"/>
            <Stack.Screen component={withPrivateRoute(CommittedSpendingScreen)} name="CommittedList"/>
            <Stack.Screen component={withPrivateRoute(TransactionsScreen)} name="TransactionsList"/>
            <Stack.Screen component={withPrivateRoute(TransactionScreen)} name="Item" getId={getId}/>
            <Stack.Screen component={withPrivateRoute(SupportModal)} name="Support"/>
            <Stack.Screen component={withPrivateRoute(AccountTransactionsScreen)} name="Transactions"/>
            <Stack.Screen component={withPrivateRoute(CategoryDetailsScreen)} name="Category"/>
            <Stack.Screen component={withPrivateRoute(AnalyticsScreen)} name="Analytics"/>
            <Stack.Screen name="SelectPeriod" component={withPrivateRoute(SelectPeriodScreen)} getId={({ params }) => params.flow}/>
            <Stack.Screen name="AnalyticsIntro" component={withPrivateRoute(AnalyticsIntroScreen)}/>
            <Stack.Screen name="AccountsOverview" component={withPrivateRoute(AccountsOverviewScreen)} getId={({ params }) => String(params?.title)}/>
            <Stack.Screen name="EditAccountName" component={withPrivateRoute(EditAccountNameScreen)}/>
            <Stack.Screen name="EditOverdraft" component={withPrivateRoute(EditOverdraftScreen)}/>
            <Stack.Screen name="SavingGoalEdit" component={withPrivateRoute(SavingGoalEditScreen)}/>
            <Stack.Screen name="EditBudgetFrequency" component={withPrivateRoute(EditBudgetFrequencyScreen)} getId={({ params }) => params.flow}/>
            <Stack.Screen name="HolidaySelection" component={withPrivateRoute(HolidaySelectionScreen)} getId={({ params }) => params.flow}/>
            <Stack.Screen name="SelectDate" component={withPrivateRoute(SelectDateScreen)} getId={({ params }) => params.id}/>
            <Stack.Screen name="OneMillionNetWorth" component={withPrivateRoute(OneMillionNetWorth)}/>
            <Stack.Screen name="SearchTransactions" component={withPrivateRoute(SearchTransactions)}/>
            <Stack.Screen name="Notifications" component={withPrivateRoute(NotificationsScreen)}/>
            <Stack.Screen name="DailyBalanceNotifications" component={withPrivateRoute(DailyBalanceNotificationsScreen)}/>
            <Stack.Screen name="Subscriptions" component={withPrivateRoute(SubscriptionsScreen)}/>
            <Stack.Screen name="InactivePayments" component={withPrivateRoute(InactiveSubscriptionsScreen)}/>
            <Stack.Screen name="Subscription" component={withPrivateRoute(SubscriptionScreen)} getId={getId}/>
            <Stack.Screen name="RenameTransaction" component={withPrivateRoute(RenameTransactionScreen)} getId={getId}/>
            <Stack.Screen name="AddTags" component={withPrivateRoute(AddTagsScreen)}/>
            <Stack.Screen name="EditDate" component={withPrivateRoute(EditDateScreen)} getId={getId}/>
            <Stack.Screen name="AddNote" component={withPrivateRoute(AddNoteScreen)} getId={getId}/>
            <Stack.Screen name="SplitTransaction" component={withPrivateRoute(SplitTransactionScreen)}/>
            <Stack.Screen name="EditMultipleTransactionsTags" component={withPrivateRoute(EditMultipleTransactionsTagsScreen)}/>
            <Stack.Screen name="LearningScreen" component={withPrivateRoute(EditCompleteScreen)}/>
            <Stack.Screen name="SuccessSplit" component={withPrivateRoute(SuccessSplitScreen)}/>
            <Stack.Screen name="EditMultipleTransactionsRename" component={withPrivateRoute(EditMultipleTransactionsRename)}/>
            <Stack.Screen name="EditCategory" component={withPrivateRoute(EditCategoryScreen)}/>
            <Stack.Screen name="EditMultipleTransactions" component={withPrivateRoute(EditMultipleTransactionsScreen)}/>
            <Stack.Screen name="CustomCategoryEdit" component={withPrivateRoute(CustomCategoryEditScreen)}/>
            <Stack.Screen name="ManageCategories" component={withPrivateRoute(ManageCategoriesScreen)}/>
            <Stack.Screen name="CustomCategoryCreated" component={withPrivateRoute(CustomCategoryCreatedScreen)}/>
            <Stack.Screen name="UnbudgetedSpend" component={withPrivateRoute(UnbudgetedSpendScreen)}/>

            <Stack.Screen name="AccountSettings" component={withPrivateRoute(AccountSettingsScreen)}/>
            <Stack.Screen name="PersonalDetails" component={withPrivateRoute(PersonalDetailsScreen)}/>
            <Stack.Screen name="ChangePasscode" component={withPrivateRoute(ChangePasscode)}/>
            <Stack.Screen name="AccountSettingsList" component={withPrivateRoute(AccountSettingsList)}/>
            <Stack.Screen name="PreferenceSettingsList" component={withPrivateRoute(PreferenceSettingsList)}/>
            <Stack.Screen name="AboutUs" component={withPrivateRoute(AboutUs)}/>
            <Stack.Screen name="ImportantStuff" component={withPrivateRoute(ImportantStuff)}/>

            <Stack.Screen name="Success" component={withPrivateRoute(SuccessScreen)}/>
            <Stack.Screen name="CorrectedDate" component={withPrivateRoute(CorrectedDateScreen)}/>
            <Stack.Screen name="CorrectedAmount" component={withPrivateRoute(CorrectedAmountScreen)}/>
            <Stack.Screen name="PredictionFixed" component={withPrivateRoute(CorrectedPredictionScreen)}/>
            <Stack.Screen name="RenameSubscription" component={withPrivateRoute(RenameSubscriptionScreen)}/>
            <Stack.Screen name="SubscriptionsSearch" component={withPrivateRoute(SubscriptionSearchModalScreen)}/>
            <Stack.Screen name="AdjustSubscription" component={withPrivateRoute(AdjustSubscriptionScreen)}/>

            <Stack.Screen name="SelectMerchant" component={withPrivateRoute(SelectMerchantScreen)}/>
            <Stack.Screen name="EditMerchantBudgets" component={withPrivateRoute(EditMerchantBudgetsScreen)}/>
            <Stack.Screen name="EditMerchantBudget" component={withPrivateRoute(EditMerchantBudgetScreen)}/>
            <Stack.Screen name="EditBudget" component={withPrivateRoute(EditBudgetScreen)} getId={({ params }) => params.flow}/>
            <Stack.Screen name="Budgets" component={withPrivateRoute(SetCategoryBudgetsScreen)} getId={({ params }) => params.flow}/>
            <Stack.Screen name="Rollover" component={withPrivateRoute(RolloverScreen)}/>
            <Stack.Screen name="TrueBalance" component={withPrivateRoute(TrueBalanceScreen)}/>
            <Stack.Screen name="DummyAccountSetup" component={withPrivateRoute(DummyAccountSetupScreen)}/>
            <Stack.Screen name="DummyAccountCreated" component={withPrivateRoute(DummyAccountCreatedScreen)}/>
            <Stack.Screen name="DummyAccountLogo" component={withPrivateRoute(DummyAccountLogoScreen)}/>
            <Stack.Screen name="DummyAccountLogoType" component={withPrivateRoute(DummyAccountLogoTypeScreen)}/>
            <Stack.Screen name="AddBalance" component={withPrivateRoute(AddBalanceScreen)}/>
            <Stack.Screen name="AddAnEmoji" component={withPrivateRoute(AddAnEmojiScreen)}/>
            <Stack.Screen name="OfflineAccounts" component={withPrivateRoute(OfflineAccountsScreen)}/>
            <Stack.Screen name="PickAccountType" component={withPrivateRoute(PickAccountTypeScreen)}/>
            <Stack.Screen name="Property" component={withPrivateRoute(PickPropertyTypeScreen)}/>
          </Stack.Group>

          <Stack.Screen name="NotFound" component={withPrivateRoute(NotFound)}/>

          {/* Protected routes */}
        </Stack.Navigator>
      </View>
    </ErrorBoundaries>);
};
const styles = StyleSheet.create({
    flex: {
        flex: 1,
    },
});
export default WebStack;
