import React from 'react';
import { createSelector } from 'reselect';
import { IconBank, IconDiamondNew, IconHome, IconShieldDone, } from 'design-system/icons';
import IconPieChart from 'design-system/icons/IconPieChart';
import IconInvestTab from 'features/invest/icons/IconInvestTab';
import IconSaveTab from 'features/pots/icons/IconSaveTab';
import { selectMaximumInterest } from 'features/pots/reducers/selectors';
import { FREE_TRIAL_TIER, selectRentReportingAgenciesForOnboarding, selectShouldSeePremiumTrialScreen, } from 'features/premium/selectors';
import { selectHasRentReporting, selectHasRentReportingData, } from 'features/rent-reporting/selectors';
import { formatRentReportingAgencies } from 'features/rent-reporting/utils';
import { selectDidViewFraudDetectionIntro } from 'features/quests/store/selectors';
import { CompleteSetupStepId } from './entities';
export const selectCompleteSetupSteps = (hasBudgets, hasCompletedFreeTrial, isEligibleForFreeTrial, bankConnectionsCount, hasSetupInvest, hasSetupPots, investAndPotsAvailable, potsAer, rentReportingAvailable, hasSetupRentReporting, didViewFraudDetectionIntro, rentReportingAgencies, isRenting) => [
    {
        // 1 quest is completed by default
        id: CompleteSetupStepId.DEFAULT,
        analyticsTag: '',
        isCompleted: true,
        icon: null,
        title: '',
        description: '',
    },
    ...(hasCompletedFreeTrial || isEligibleForFreeTrial
        ? [
            {
                id: CompleteSetupStepId.ACTIVATE_TRIAL,
                analyticsTag: 'premium',
                isCompleted: hasCompletedFreeTrial,
                icon: <IconDiamondNew />,
                title: 'Activate premium trial',
                description: 'Try our full experience',
            },
        ]
        : []),
    {
        id: CompleteSetupStepId.SECOND_BANK_ACCOUNT,
        analyticsTag: 'bank',
        isCompleted: bankConnectionsCount >= 2,
        icon: <IconBank gradientKey="lightPurple"/>,
        title: `Add a ${bankConnectionsCount === 1 ? 'second ' : ''}bank account`,
        description: 'Make Emma work smarter',
    },
    {
        id: CompleteSetupStepId.SET_UP_BUDGETING,
        analyticsTag: 'budgeting',
        isCompleted: hasBudgets,
        icon: <IconPieChart gradientKey="deepPink"/>,
        title: 'Set up budgeting',
        description: 'Track your day to day spending and set limits',
    },
    ...(investAndPotsAvailable
        ? [
            {
                id: CompleteSetupStepId.START_INVESTING,
                analyticsTag: 'invest',
                isCompleted: hasSetupInvest,
                icon: <IconInvestTab notificationDot={false}/>,
                title: 'Start investing',
                description: 'Invest in the companies you love. Capital at risk',
            },
            {
                id: CompleteSetupStepId.START_SAVING,
                analyticsTag: 'pot',
                isCompleted: hasSetupPots,
                icon: <IconSaveTab notificationDot={false}/>,
                ...(potsAer
                    ? {
                        title: 'Grow your savings',
                        description: `Earn up to ${potsAer}% AER by opening an Interest Pot. FSCS protected`,
                    }
                    : {
                        title: 'Start saving with Pots',
                        description: 'Boost your savings and reach your financial goals',
                    }),
            },
        ]
        : []),
    ...(rentReportingAvailable && isRenting !== false
        ? [
            {
                id: CompleteSetupStepId.RENT_REPORTING,
                analyticsTag: 'rent_reporting',
                isCompleted: hasSetupRentReporting,
                icon: <IconHome gradientKey="orange"/>,
                title: 'Improve your credit history',
                description: `Start reporting your rent payments to ${formatRentReportingAgencies(rentReportingAgencies)}`,
            },
        ]
        : []),
    {
        id: CompleteSetupStepId.FRAUD_DETECTION,
        analyticsTag: 'fraud_detection',
        isCompleted: didViewFraudDetectionIntro,
        icon: <IconShieldDone />,
        title: 'Protect your identity',
        description: `Monitor your personal details and receive alerts if they leak`,
    },
];
export const completeSetupStepsSelector = createSelector([
    (state) => Boolean(state.feed.feed.cards.thisMonth?.budget),
    (state) => Boolean(state.user.user.emmaProStatus.usedFreeTrial),
    (state) => selectShouldSeePremiumTrialScreen(state, FREE_TRIAL_TIER),
    (state) => state.user.connections.length,
    (state) => state.invest.status === 'ACTIVE',
    (state) => state.pots.account?.status === 'ACTIVE' && state.pots.pots.length > 0,
    (state) => state.user.user.guessedHomeCountry === 'GB',
    selectMaximumInterest,
    selectHasRentReporting,
    selectHasRentReportingData,
    selectDidViewFraudDetectionIntro,
    selectRentReportingAgenciesForOnboarding,
    (store) => store.user.userAdditionalInfo?.isRenting,
], selectCompleteSetupSteps);
export const hasCompletedSetupSelector = createSelector([completeSetupStepsSelector], (steps) => steps.filter((step) => !step.isCompleted).length === 0);
