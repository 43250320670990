import React, { useCallback, useEffect } from 'react';
import TitledView from 'design-system/TitledView';
import { DaysOfTheMonthSelector } from 'design-system/date';
export const TwiceAMonthView = ({ days, onChange, ...props }) => {
    useEffect(() => {
        onChange([days?.[0] || 1, days?.[1] || 31]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleChange = useCallback((days) => {
        if (days[0] !== days[1]) {
            onChange(days);
        }
    }, [onChange]);
    return (<TitledView title="Pick two dates">
      <DaysOfTheMonthSelector onChange={handleChange} days={days} maxNumberOfDays={2} {...props}/>
    </TitledView>);
};
