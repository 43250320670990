import React, { useCallback, useEffect, useLayoutEffect, useRef, useState, } from 'react';
import { View } from 'react-native';
import { rem } from 'design-system/values';
import { AppView } from 'design-system/AppView';
import { Spacer } from 'design-system/Spacer';
import Button from 'design-system/Button';
import { styles as textStyles } from 'design-system/Text';
import KeyboardAvoidingContainer from 'design-system/KeyboardAvoidingContainer';
import { setAccountName } from 'actions';
import useColors from 'hooks/useColors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useAutoFocusInput } from 'hooks/useAutoFocusInput';
import StatusBar from 'design-system/StatusBar';
import createStyleSheets from 'utils/createStyleSheets';
import useStyles from 'hooks/useStyles';
import { selectAccountById } from 'reducers/selectors';
import TextInput from 'utils/packages/TextInput';
import { isWeb } from '../../../constants';
const EditAccountNameScreen = ({ navigation, route, }) => {
    const colors = useColors();
    const styles = useStyles(styleSet);
    useLayoutEffect(() => {
        navigation.setOptions({
            title: 'Rename',
            headerTintColor: colors.text.primary,
        });
    }, [colors.text.primary]);
    const account = useAppSelector((store) => selectAccountById(store, route.params.id));
    const [name, setName] = useState(account?.name || '');
    useEffect(() => {
        if (name === '' && account?.name) {
            setName(account?.name);
        }
    }, [account?.name]);
    const dispatch = useAppDispatch();
    const onPressSubmit = useCallback(() => {
        dispatch(setAccountName(route.params.id, name));
        if (navigation.canGoBack()) {
            navigation.goBack();
        }
        else {
            navigation.navigate('Transactions', { id: route.params.id });
        }
    }, [name, route.params.id]);
    const textInputRef = useRef(null);
    useAutoFocusInput(textInputRef);
    return (<AppView type={isWeb ? 'secondary' : 'primary'} isInTab={isWeb} avoidHeader={isWeb}>
      <StatusBar barStyle="dark-content" animated/>
      <KeyboardAvoidingContainer>
        <View style={styles.container}>
          {isWeb && <Spacer h={72}/>}
          <TextInput ref={textInputRef} autoCorrect={false} allowFontScaling={false} value={name} onChangeText={setName} placeholderTextColor={colors.text.placeholder} selectionColor={colors.text.selection} underlineColorAndroid="transparent" style={styles.textInput} textAlign="center" returnKeyType="done" onSubmitEditing={onPressSubmit} focusStyle={styles.textInputFocus}/>
        </View>
        <Button brand onBrand onPress={onPressSubmit} title="Save" disabled={name?.length === 0}/>
      </KeyboardAvoidingContainer>
    </AppView>);
};
const styleSet = createStyleSheets((colors) => ({
    container: {
        flex: 1,
        ...(!isWeb && {
            justifyContent: 'center',
            alignItems: 'center',
        }),
    },
    textInputFocus: {
        color: colors.text.primary,
    },
    textInput: {
        ...(isWeb
            ? {
                paddingVertical: rem(16),
                color: colors.text.secondary,
            }
            : {
                ...textStyles.TextThin_20,
                alignSelf: 'stretch',
                color: colors.text.primary,
                fontSize: rem(26),
                lineHeight: undefined,
            }),
    },
}));
export default EditAccountNameScreen;
