import React, { memo, useEffect } from 'react';
import { clamp } from 'lodash';
import { View, Platform } from 'react-native';
import Animated, { Extrapolation, interpolate, useSharedValue, useAnimatedStyle, } from 'react-native-reanimated';
import LinearGradient from 'react-native-linear-gradient';
import { rem, ProgressBar, Spacer } from 'design-system';
import Text from 'design-system/Text';
import useColors from 'hooks/useColors';
import useStyles from 'hooks/useStyles';
import { selectIsScrambled } from 'hooks';
import { useAppSelector } from 'store/hooks';
import { formatAmount } from 'utils/formatting';
import useColorScheme from 'hooks/useColorScheme';
import useHeaderHeight from 'hooks/useHeaderHeight';
import createStyleSheets from 'utils/createStyleSheets';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import { isWeb } from '../../../constants';
const SPACE = rem(16);
const SetCategoryBudgetsAllocation = ({ currency, totalBudget, totalAllocated, scrollY, headerInfoHeight, }) => {
    const colors = useColors();
    const styles = useStyles(styleSet);
    const headerHeight = useHeaderHeight();
    const colorScheme = useColorScheme();
    const percentAllocated = (totalAllocated / totalBudget) * 100;
    const amountRemaining = totalBudget - totalAllocated;
    const scrambled = useAppSelector(selectIsScrambled);
    const value = useSharedValue(percentAllocated);
    const { paddingHorizontal } = useAppFrameDimensions(isWeb);
    const headerBackground = useAnimatedStyle(() => ({
        transform: [
            {
                translateY: interpolate(scrollY.value, [0, headerInfoHeight, headerInfoHeight + 1], [headerInfoHeight, 0, 0]),
            },
        ],
    }));
    const animatedContainer = useAnimatedStyle(() => ({
        paddingHorizontal: interpolate(scrollY.value, [headerInfoHeight - 20, headerInfoHeight], [SPACE, (paddingHorizontal || 0) + SPACE], Extrapolation.CLAMP),
        shadowOpacity: colorScheme === 'dark'
            ? 0
            : interpolate(scrollY.value, [headerInfoHeight - 20, headerInfoHeight], [0, 1]),
        borderRadius: interpolate(scrollY.value, [headerInfoHeight - 20, headerInfoHeight], [SPACE, 0], Extrapolation.CLAMP),
        marginHorizontal: interpolate(scrollY.value, [headerInfoHeight - 20, headerInfoHeight], [SPACE + (paddingHorizontal || 0), 0], Extrapolation.CLAMP),
    }), [headerInfoHeight, paddingHorizontal, colorScheme]);
    useEffect(() => {
        value.value = percentAllocated;
    }, [percentAllocated, value]);
    const androidShadowOpacity = useAnimatedStyle(() => ({
        opacity: interpolate(scrollY.value, [headerInfoHeight - 20, headerInfoHeight], [0, 1], Extrapolation.CLAMP),
    }));
    return (<Animated.View style={[styles.card, headerBackground, { marginTop: headerHeight }]}>
      <View style={styles.cardContainer}>
        <Animated.View style={[
            styles.container,
            isWeb
                ? { marginHorizontal: paddingHorizontal + rem(16) - rem(32) }
                : animatedContainer,
        ]}>
          <View style={styles.titleRow}>
            {Boolean(totalBudget) && (<Text Numbers-16>{Math.round(percentAllocated)}% set</Text>)}
            <Text Numbers-16>
              {formatAmount(totalAllocated, currency, 2, scrambled)}/
              {formatAmount(totalBudget, currency, 2, scrambled)}
            </Text>
          </View>
          <Spacer.H8 />
          <ProgressBar position={percentAllocated} animateProgress={false} barBackgroundColor={colors.background.dark} colors={[colors.elements.brand, colors.elements.brand]}/>
          <Spacer.H8 />
          <LeftToAllocate currency={currency} scrambled={scrambled} amountRemaining={amountRemaining}/>
        </Animated.View>
      </View>
      {Platform.OS === 'android' && (<Animated.View style={androidShadowOpacity}>
          <LinearGradient pointerEvents="none" style={[styles.androidShadow]} colors={[
                colors.shadows.androidShadowGradientStart,
                colors.shadows.androidShadowGradientEnd,
            ]}/>
        </Animated.View>)}
    </Animated.View>);
};
const LeftToAllocate = ({ amountRemaining, currency, scrambled, }) => {
    const toAllocate = clamp(amountRemaining, 0, Infinity);
    return toAllocate === 0 ? (<Text TextThin-14 Red>
      {formatAmount(toAllocate, currency, 0, scrambled)} left to allocate
    </Text>) : (<Text TextThin-14 Gray>
      {formatAmount(toAllocate, currency, 0, scrambled)} left to allocate
    </Text>);
};
const styleSet = createStyleSheets((colors) => ({
    card: {
        position: 'absolute',
        left: 0,
        right: 0,
        zIndex: 1,
        height: rem(95),
    },
    container: {
        padding: rem(16),
        backgroundColor: colors.cards.onDark,
        ...colors.shadows.extendedHeader,
        ...(isWeb && {
            borderRadius: rem(16),
        }),
    },
    cardContainer: {
        ...(isWeb && {
            paddingVertical: rem(1),
            marginHorizontal: rem(32),
            backgroundColor: colors.background.dark,
        }),
    },
    titleRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    androidShadow: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        height: 10,
    },
}));
export const allocationCardHeight = rem(95);
export default memo(SetCategoryBudgetsAllocation);
