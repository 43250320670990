import React, { useCallback } from 'react';
import Animated from 'react-native-reanimated';
import { StyleSheet, View, TouchableWithoutFeedback } from 'react-native';
import { useOpenSearch } from 'features/transactions/hooks/useOpenSearch';
import Spacer from 'design-system/Spacer';
import Indicator from 'design-system/Indicator';
import { AppView } from 'design-system/AppView';
import withAnimatedNavigationHeader from 'design-system/hoc/withAnimatedNavigationHeader';
import FakeSearchBar from 'design-system/FakeSearchBar';
import { useAppSelector } from 'store/hooks';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import Amplitude from 'utils/amplitude';
import FeedHeader from '../components/FeedHeader';
import FeedContent from '../components/FeedContent';
import Overview from '../components/overview/Overview';
const Feed = withAnimatedNavigationHeader(({ scrollHandler }) => {
    const feed = useAppSelector((store) => store.feed.feed);
    const openSearchScreen = useOpenSearch();
    const openSearch = useCallback(() => {
        openSearchScreen();
        Amplitude.logEvent('Accounts.OpenSearch', { source: 'feed' });
    }, [openSearchScreen]);
    const { paddingHorizontalStyle } = useAppFrameDimensions(true);
    return (<AppView withFrame={false}>
        {!feed.cards.overview ? (<View style={styles.indicatorContainer}>
            <Indicator dark/>
          </View>) : (<Animated.ScrollView scrollEventThrottle={16} onScroll={scrollHandler}>
            <View style={paddingHorizontalStyle}>
              <TouchableWithoutFeedback onPress={openSearch} style={styles.searchBarBtn}>
                <View pointerEvents="box-only" style={styles.flex}>
                  <FakeSearchBar />
                </View>
              </TouchableWithoutFeedback>
            </View>
            <Overview data={feed.cards.overview}/>
            <FeedContent latestTransactions={feed.cards.latestTransactions}/>
            <Spacer h={16}/>
          </Animated.ScrollView>)}
      </AppView>);
}, ({ colors }) => ({
    headerHeight: 64,
    renderContent: () => <FeedHeader />,
    headerColorOutputRange: [
        colors.background.dark,
        colors.cards.onDark,
    ],
}));
const styles = StyleSheet.create({
    flex: {
        flex: 1,
    },
    searchBarBtn: {
        flex: 1,
        // @ts-ignore
        cursor: 'pointer',
    },
    indicatorContainer: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
});
export default Feed;
