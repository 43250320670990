import React, { useLayoutEffect, useCallback } from 'react';
import StatusBar from 'design-system/StatusBar';
import { AppView } from 'design-system/AppView';
import withAnimatedNavigationHeader from 'design-system/hoc/withAnimatedNavigationHeader';
import NavigationHeaderRightIconButton from 'design-system/NavigationHeaderRightIconButton';
import { IconEdit } from 'design-system/icons/IconEdit';
import useColors from 'hooks/useColors';
import AnalyticsList from '../components/AnalyticsList';
import { SelectedCategoryContextProvider } from '../hooks/useSelectedCategory';
// This used to be called 'Categories' in the old app
const AnalyticsScreen = withAnimatedNavigationHeader(({ scrollY, navigation }) => {
    const colors = useColors();
    const openEditPeriod = useCallback(() => {
        navigation.navigate('SelectPeriod', { flow: 'SetAnalytics' });
    }, []);
    useLayoutEffect(() => {
        navigation.setOptions({
            headerRight: () => (<NavigationHeaderRightIconButton onPress={openEditPeriod}>
            <IconEdit color={colors.buttons.primary}/>
          </NavigationHeaderRightIconButton>),
        });
    }, [colors.buttons.primary, openEditPeriod]);
    return (<SelectedCategoryContextProvider>
        <AppView withFrame={false}>
          <StatusBar barStyle="dark-content" animated/>
          <AnalyticsList scrollY={scrollY}/>
        </AppView>
      </SelectedCategoryContextProvider>);
}, ({ colors }) => ({
    title: 'Analytics',
    headerColorOutputRange: [
        colors.background.dark,
        colors.cards.onDark,
    ],
}));
export default AnalyticsScreen;
