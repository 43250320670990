import isEqual from 'lodash/isEqual';
import { REHYDRATE } from 'redux-persist';
import { SAVE_BUDGETS_SUCCESS } from 'features/budgeting/actions/types';
import { getTransactionsIdObj } from 'utils/getTransactions';
import * as types from '../actions/types';
import { AnalyticsLoadingState, isLoadedState, } from './types';
export const initialState = {
    isFetchingTotals: false,
    isFetchingCategoryExpenses: false,
    isFetching: false,
    hasLoadedMerchantExpenses: false,
    analyticsPosition: 0,
    budgetingPosition: 0,
    analyticsFirstItem: 0,
    monthlyTotals: {},
    monthlyTotalsFiltered: undefined,
    isSwitchingTotalsStep: false,
    categoryTotals: {},
    monthlyCategories: {},
    monthlyMerchants: {},
    categoryExpenses: [],
    bankFees: [],
    forceRefresh: false,
};
const emptyCategoriesState = {
    isValid: false,
    transactionsCount: 0,
    loadingState: AnalyticsLoadingState.INITIAL,
    categories: [],
};
const emptyMerchantsState = {
    isValid: false,
    transactionsCount: 0,
    loadingState: AnalyticsLoadingState.INITIAL,
    merchants: [],
};
const expenses = (state = initialState, action) => {
    switch (action.type) {
        case REHYDRATE:
            const { expenses } = action.payload || {};
            if (expenses)
                return { ...initialState };
            return state;
        case types.FORCE_REFRESH_ANALYTICS: {
            const { includeTotals } = action.payload;
            return {
                ...state,
                forceRefresh: true,
                monthlyCategories: includeTotals !== undefined ? state.monthlyCategories : {},
                monthlyMerchants: includeTotals !== undefined ? state.monthlyMerchants : {},
                monthlyTotals: state.monthlyTotals,
                categoryTotals: {},
            };
        }
        case types.GET_BANK_FEES_REQUEST:
            return { ...state, isFetching: true };
        case types.GET_BANK_FEES_FAILURE:
            return { ...state, isFetching: false };
        case types.GET_BANK_FEES_SUCCESS:
            return { ...state, bankFees: action.payload.bankFees, isFetching: false };
        case types.SET_ANALYTICS_POSITION:
            return {
                ...state,
                analyticsPosition: action.payload.position,
                analyticsFirstItem: action.payload.firstItem !== undefined
                    ? action.payload.firstItem
                    : state.analyticsFirstItem,
                monthlyTotalsFiltered: action.payload.resetFilter
                    ? undefined
                    : state.monthlyTotalsFiltered,
            };
        case types.SET_BUDGETING_POSITION:
            return { ...state, budgetingPosition: action.payload };
        case types.IS_FETCHING_TOTALS:
            return { ...state, isFetchingTotals: true };
        case types.IS_FETCHING_CATEGORY_EXPENSES:
            return { ...state, isFetchingCategoryExpenses: true };
        case types.GET_MERCHANT_EXPENSES_SUCCESS:
        case types.GET_CATEGORY_EXPENSES_SUCCESS:
            return {
                ...state,
                categoryExpenses: action.payload.transactions,
                isFetchingCategoryExpenses: false,
            };
        case types.MONTHLY_CATEGORIES_REQUEST:
            return {
                ...state,
                monthlyCategories: {
                    ...state.monthlyCategories,
                    [action.extra]: {
                        ...(state.monthlyCategories[action.extra] ?? emptyCategoriesState),
                        loadingState: isLoadedState(state.monthlyCategories[action.extra]?.loadingState)
                            ? AnalyticsLoadingState.RELOADING
                            : AnalyticsLoadingState.LOADING,
                    },
                },
            };
        case types.MONTHLY_MERCHANTS_REQUEST:
            return {
                ...state,
                monthlyMerchants: {
                    ...state.monthlyMerchants,
                    [action.extra]: {
                        ...(state.monthlyMerchants[action.extra] ?? emptyMerchantsState),
                        loadingState: isLoadedState(state.monthlyMerchants[action.extra]?.loadingState)
                            ? AnalyticsLoadingState.RELOADING
                            : AnalyticsLoadingState.LOADING,
                    },
                },
            };
        case types.MONTHLY_CATEGORIES_SUCCESS: {
            return {
                ...state,
                monthlyCategories: {
                    ...state.monthlyCategories,
                    [action.extra]: {
                        categories: action.payload.categories,
                        transactionsCount: action.payload.transactionsCount,
                        loadingState: AnalyticsLoadingState.LOADED,
                        isValid: true,
                    },
                },
                forceRefresh: false,
            };
        }
        case types.MONTHLY_MERCHANTS_SUCCESS:
            return {
                ...state,
                monthlyMerchants: {
                    ...state.monthlyMerchants,
                    [action.extra]: {
                        merchants: action.payload.merchants,
                        transactionsCount: action.payload.transactionsCount,
                        isValid: true,
                        loadingState: AnalyticsLoadingState.LOADED,
                    },
                },
                forceRefresh: false,
                hasLoadedMerchantExpenses: true,
            };
        case types.MONTHLY_CATEGORIES_FAILURE:
            return {
                ...state,
                monthlyCategories: {
                    ...state.monthlyCategories,
                    [action.extra]: {
                        ...(state.monthlyCategories[action.extra] ?? emptyCategoriesState),
                        loadingState: AnalyticsLoadingState.ERROR,
                    },
                },
            };
        case types.MONTHLY_MERCHANTS_FAILURE:
            return {
                ...state,
                monthlyMerchants: {
                    ...state.monthlyMerchants,
                    [action.extra]: {
                        ...(state.monthlyMerchants[action.extra] ?? emptyMerchantsState),
                        loadingState: AnalyticsLoadingState.ERROR,
                    },
                },
            };
        case SAVE_BUDGETS_SUCCESS:
            const newMonthlyBudget = action.extra.data['overall.monthly'];
            let paydayFrom;
            if (newMonthlyBudget?.baseLimit === null) {
                return {
                    ...state,
                    monthlyTotals: {},
                    monthlyCategories: {},
                    monthlyMerchants: {},
                };
            }
            const monthlyTotals = Object.keys(state.monthlyTotals).reduce((prev, curr) => ({
                ...prev,
                [curr]: state.monthlyTotals[curr].map((total) => {
                    if (total.hasCircle) {
                        paydayFrom = `${total.from}|${total.to}`;
                        if (newMonthlyBudget !== undefined) {
                            return {
                                ...total,
                                totalBudget: newMonthlyBudget.baseLimit,
                                shouldRollover: newMonthlyBudget.shouldRollover,
                                ...(newMonthlyBudget.resetRollingAccumulated
                                    ? { rollingAccumulatedLimit: null }
                                    : {}),
                            };
                        }
                    }
                    return total;
                }),
            }), {});
            return {
                ...state,
                monthlyTotals,
                monthlyCategories: paydayFrom && state.monthlyCategories[paydayFrom]
                    ? {
                        ...state.monthlyCategories,
                        [paydayFrom]: {
                            ...(state.monthlyCategories[paydayFrom] ??
                                emptyCategoriesState),
                            categories: (state.monthlyCategories[paydayFrom] ?? emptyCategoriesState).categories.map((category) => {
                                const modifiedBudget = action.extra.data[`category.${category.id}`];
                                return modifiedBudget
                                    ? {
                                        ...category,
                                        budgetLimit: modifiedBudget.baseLimit || 0,
                                    }
                                    : category;
                            }),
                        },
                    }
                    : state.monthlyCategories,
                monthlyMerchants: paydayFrom && state.monthlyMerchants[paydayFrom]
                    ? {
                        ...state.monthlyMerchants,
                        [paydayFrom]: {
                            ...(state.monthlyMerchants[paydayFrom] ??
                                emptyMerchantsState),
                            merchants: (state.monthlyMerchants[paydayFrom]?.merchants ?? []).map((merchant) => {
                                const modifiedBudget = action.extra.data[`merchant.${merchant.id}`];
                                return modifiedBudget
                                    ? {
                                        ...merchant,
                                        budgetLimit: modifiedBudget.baseLimit || 0,
                                        color: modifiedBudget.color || merchant.color,
                                    }
                                    : merchant;
                            }),
                        },
                    }
                    : state.monthlyMerchants,
            };
        case types.CREATE_CATEGORY_SUCCESS:
            let periodKey;
            let currency;
            Object.values(state.monthlyTotals).find((period) => period.find((monthlyTotal) => {
                if (monthlyTotal.hasCircle) {
                    periodKey = `${monthlyTotal.from}|${monthlyTotal.to}`;
                    currency = monthlyTotal.currency;
                    return true;
                }
                return false;
            }));
            if (!periodKey || !currency) {
                return state;
            }
            const categoriesForThisPeriod = state.monthlyCategories[periodKey];
            if (!categoriesForThisPeriod) {
                return state;
            }
            // todo this illustrates well how we could normalise categories, we have data duplicated all over
            return {
                ...state,
                monthlyCategories: {
                    ...state.monthlyCategories,
                    [periodKey]: {
                        ...categoriesForThisPeriod,
                        categories: [
                            ...categoriesForThisPeriod.categories,
                            {
                                id: action.payload.category.id,
                                displayName: action.payload.category.displayName,
                                color: action.payload.category.color ?? undefined,
                                emoji: action.payload.category.emoji ?? undefined,
                                currency,
                                budgetLimit: 0,
                                total: 0,
                                transactionsCount: 0,
                                rollingAccumulatedLimit: null,
                            },
                        ],
                    },
                },
            };
        case types.RENAME_TRANSACTION_SUCCESS: {
            const { data } = action.extra;
            const idObj = getTransactionsIdObj(data);
            return {
                ...state,
                categoryExpenses: state.categoryExpenses.map((item) => {
                    if (idObj[item.id]) {
                        return { ...item, customName: action.extra.counterPartName };
                    }
                    return item;
                }),
            };
        }
        case types.SET_TAGS_SUCCESS:
        case types.SAVE_NOTE_SUCCESS:
            let categoryTransactions = [...state.categoryExpenses];
            if (categoryTransactions && categoryTransactions.length) {
                categoryTransactions = categoryTransactions.map((transaction) => {
                    if (transaction.id === action.payload.id) {
                        return { ...transaction, ...action.payload };
                    }
                    return transaction;
                });
            }
            return { ...state, categoryExpenses: categoryTransactions };
        case types.GET_MONTHLY_TOTALS_FAILURE:
            return { ...state, isFetchingTotals: false };
        case types.GET_MONTHLY_TOTALS_REQUEST:
            const isSwitchingTotalsStep = action.extra &&
                ((action.extra.step &&
                    action.extra.step !== undefined &&
                    action.extra.step !== state.monthlyTotalsFiltered?.step) ||
                    !isEqual(action.extra.accountIds, state.monthlyTotalsFiltered?.accountIds)) &&
                action.extra.reset?.filteredTotals;
            return {
                ...state,
                isFetchingTotals: true,
                isSwitchingTotalsStep: Boolean(isSwitchingTotalsStep),
                monthlyCategories: isSwitchingTotalsStep ? {} : state.monthlyCategories,
                monthlyMerchants: isSwitchingTotalsStep ? {} : state.monthlyMerchants,
            };
        case types.GET_MONTHLY_TOTALS_SUCCESS:
            const results = action.payload.totals || action.payload.payPeriods;
            if (!results)
                return state;
            const getMonthlyTotals = () => {
                if (action.extra.step || action.extra.accountIds) {
                    return state.monthlyTotals;
                }
                return {
                    ...(action.extra.reset?.totals ? {} : state.monthlyTotals),
                    [action.extra.fromTo]: results.reverse(),
                };
            };
            const getFiltered = () => {
                if (action.extra.reset?.filteredTotals &&
                    !action.extra.step &&
                    !action.extra.accountIds) {
                    return undefined;
                }
                if (action.extra.step || action.extra.accountIds) {
                    return {
                        step: action.extra.step,
                        accountIds: action.extra.accountIds,
                        results: {
                            ...(action.extra.reset?.filteredTotals
                                ? {}
                                : state.monthlyTotalsFiltered?.results),
                            [action.extra.fromTo]: results
                                .filter((total) => !total.isPayday || !action.extra.step)
                                .reverse(),
                        },
                    };
                }
                return state.monthlyTotalsFiltered;
            };
            return {
                ...state,
                isFetchingTotals: false,
                monthlyTotals: getMonthlyTotals(),
                isSwitchingTotalsStep: false,
                monthlyTotalsFiltered: getFiltered(),
                analyticsPosition: action.extra.firstItem !== undefined
                    ? action.extra.firstItem
                    : state.analyticsPosition,
                analyticsFirstItem: action.extra.firstItem !== undefined
                    ? action.extra.firstItem
                    : state.analyticsFirstItem,
            };
        case types.GET_CATEGORY_TOTALS_REQUEST:
            return {
                ...state,
                categoryTotals: action.extra.reset ? {} : state.categoryTotals,
            };
        case types.GET_CATEGORY_TOTALS_SUCCESS:
            let categoryTotalsResults = action.payload.totals || action.payload.payPeriods;
            if (!categoryTotalsResults)
                return state;
            if (action.extra.step && categoryTotalsResults) {
                categoryTotalsResults = categoryTotalsResults.filter((total) => !total.isPayday);
            }
            return {
                ...state,
                isFetchingTotals: false,
                categoryTotals: {
                    ...state.categoryTotals,
                    from: action.extra.from,
                    to: action.extra.to,
                    isValid: true,
                    balances: categoryTotalsResults,
                    graph: [...categoryTotalsResults].reverse(),
                },
            };
        case types.RESET_ERROR:
            return {
                ...state,
                isFetchingTotals: false,
                isFetchingCategoryExpenses: false,
            };
        case types.REFRESH_ON_START:
        case types.SET_CONNECTIONS_STATUS:
        case types.SET_INCOME_SUCCESS:
        case types.SET_PRIMARY_INCOME_SUCCESS:
        case types.DELETE_INCOME_SUCCESS:
        case types.HIDE_SUCCESS:
        case types.DELETE_ACCOUNT_SUCCESS:
        case types.CREATE_TRANSACTION_SUCCESS:
        case types.DELETE_TRANSACTION_SUCCESS:
        case types.UNSPLIT_TRANSACTION_SUCCESS:
        case types.SPLIT_TRANSACTION_SUCCESS:
        case types.CLOSED_CONNECTION_SUCCESS:
        case types.DELETE_CONNECTION_SUCCESS:
        case types.EDIT_CATEGORY_SUCCESS:
        case types.CANCEL_SUBSCRIPTIONS_SUCCESS:
        case types.EDIT_SUBSCRIPTIONS_SUCCESS:
        case types.DELETE_CATEGORY_SUCCESS:
        case types.SAVE_TOTAL_BUDGET_SUCCESS:
        case types.CHANGE_DATE_TRANSACTION_SUCCESS:
        case types.UPDATE_CATEGORY_SUCCESS:
        case types.CHOOSE_RETAINED_CONNECTIONS_SUCCESS:
            if ('payload' in action && action.payload === true)
                return state;
            const oldMonthlyCategories = state.monthlyCategories;
            if (!Object.keys(oldMonthlyCategories).length) {
                return { ...state };
            }
            const monthlyCategories = Object.keys(oldMonthlyCategories).reduce((prev, key) => {
                const categoryAtKey = oldMonthlyCategories[key];
                if (categoryAtKey !== undefined) {
                    return {
                        ...prev,
                        [key]: {
                            ...categoryAtKey,
                            isValid: false,
                        },
                    };
                }
                return {
                    ...prev,
                    [key]: categoryAtKey,
                };
            }, {});
            return {
                ...state,
                monthlyCategories,
                categoryTotals: { ...state.categoryTotals, isValid: false },
                // Patch locally instead of waiting for the API,
                // Don't remove from the list as the user could still be on the transaction screen and may change it back to the original category
                // The GET API should return shortly after and fix anyway
                categoryExpenses: action.type === types.EDIT_CATEGORY_SUCCESS
                    ? state.categoryExpenses.map((t) => Object.keys(action.extra.data).find((txnId) => txnId === String(t.id))
                        ? {
                            ...t,
                            category: action.extra.category,
                        }
                        : t)
                    : state.categoryExpenses,
            };
        case types.LOGGED_OUT:
            return initialState;
        default:
            return state;
    }
};
export default expenses;
