import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState, } from 'react';
import { Keyboard, StyleSheet, View } from 'react-native';
import getSymbolFromCurrency from 'currency-symbol-map';
import { format, parse, isBefore, isValid, addDays, parseISO } from 'date-fns';
import emoji from 'node-emoji';
import { addGoal, deleteGoal, editGoal, getAccountGoals } from 'actions';
import Alert from 'utils/packages/Alert';
import { rem } from 'design-system/values';
import { AnimatedKeyboardAwareScrollView } from 'design-system/AnimatedComponents';
import { AppView } from 'design-system/AppView';
import Button, { HEIGHT } from 'design-system/Button';
import NavigationHeaderRightIconButton from 'design-system/NavigationHeaderRightIconButton';
import { SettingsItemInput, SettingsItemInputMask, SettingsItemNavigate, } from 'design-system/SettingsItem';
import { SettingsSection } from 'design-system/SettingsSection';
import StatusBar from 'design-system/StatusBar';
import Text from 'design-system/Text';
import withAnimatedNavigationHeader from 'design-system/hoc/withAnimatedNavigationHeader';
import { IconCalendar, IconCoinStack, IconNote, IconTrash, IconWallet, } from 'design-system/icons';
import useAccountPicker from 'features/invest/hooks/useAccountPicker';
import { useRequiredBenefitCallbackWithSpaces } from 'features/premium/hooks/useRequiredBenefitCallback';
import { muteAll, useDateTimePicker, useFetchOld, useKeyboardAdjustPan, } from 'hooks';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import useColors from 'hooks/useColors';
import { useMarginBottom } from 'hooks/useMarginBottom';
import { selectAccountById, selectAccounts, selectActiveConnections, selectDefaultCurrency, } from 'reducers/selectors';
import { useAppDispatch, useAppSelector, useAppStore } from 'store/hooks';
import { formatAmount } from 'utils/formatting';
import getListAccounts from 'utils/api/getListAccounts';
import SettingsItemSelectable from 'design-system/SettingsItemSelectable';
import { Spacer } from 'design-system/Spacer';
import { isWeb } from '../../constants';
const goalToState = (goal, dateFormat) => {
    let date = goal?.targetDate;
    if (isWeb && date) {
        date = format(parseISO(date), dateFormat);
    }
    return {
        name: goal?.name,
        targetAmount: Math.ceil(goal?.targetAmount || 0),
        targetDate: date,
    };
};
/**
 * Figma: https://www.figma.com/file/SMqeFyVH6qLbMzeoCHBp4A/Emma-Design-System?node-id=3488%3A126018
 */
const SavingGoalEditScreen = withAnimatedNavigationHeader(({ navigation, route, scrollHandler }) => {
    const dispatch = useAppDispatch();
    const store = useAppStore();
    useEffect(() => {
        if (!store.getState().accounts.loadedGoals) {
            dispatch(getAccountGoals());
        }
    }, []);
    const guessedHomeCountry = useAppSelector((store) => store.user.user.guessedHomeCountry);
    const monthFirst = guessedHomeCountry === 'US' || guessedHomeCountry === 'CA';
    const dateFormat = monthFirst ? 'MM/dd/yyyy' : 'dd/MM/yyyy';
    const colors = useColors();
    const accountId = route.params.accountId !== undefined
        ? Number(route.params.accountId)
        : undefined;
    const account = useAppSelector((store) => selectAccountById(store, accountId));
    const goal = useAppSelector((store) => accountId !== undefined ? store.accounts.goals[accountId] : undefined);
    const activeConnections = useAppSelector(selectActiveConnections);
    const accounts = useMemo(() => getListAccounts(activeConnections), [activeConnections]);
    const accountsByType = useAppSelector(selectAccounts);
    const storeGoals = useAppSelector((store) => store.accounts.goals);
    const [state, setState] = useState(() => {
        let cAccount = account;
        if (goal && !cAccount) {
            const cGoalAccountId = goal.accountId;
            const matchedAccount = accounts.find((el) => el.id === cGoalAccountId);
            if (matchedAccount) {
                cAccount = matchedAccount;
            }
        }
        return {
            ...goalToState(goal, dateFormat),
            selectedAccount: cAccount,
        };
    });
    useEffect(() => {
        if (goal && !state.name) {
            setState((s) => ({
                ...s,
                ...goalToState(goal, dateFormat),
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [goal]);
    useEffect(() => {
        const matchedAccount = accounts.find((el) => el.id === accountId);
        if (!selectedAccount && matchedAccount) {
            setState((s) => ({
                ...s,
                selectedAccount: matchedAccount,
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accounts]);
    useKeyboardAdjustPan();
    const accountsTouchViewRef = useRef(null);
    const openAccountPicker = useAccountPicker();
    const openSelectAccount = async () => {
        try {
            const selectedAccount = await openAccountPicker(accountsByType?.SAVINGS?.array.filter((acc) => !storeGoals[acc.id]), {
                hideConnectNewAccountView: true,
                selectedIds: state.selectedAccount
                    ? [state.selectedAccount.id]
                    : undefined,
                emptyText: 'No savings accounts to choose from.',
            }, accountsTouchViewRef);
            if (selectedAccount) {
                setState((state) => ({ ...state, selectedAccount }));
            }
        }
        catch (e) {
            // noop
        }
    };
    useLayoutEffect(() => {
        const accountId = account?.id;
        navigation.setOptions({
            headerTintColor: colors.text.primary,
            headerRight: goal && accountId !== undefined
                ? () => (<NavigationHeaderRightIconButton onPress={() => {
                        Alert.alert(`Delete ${goal.name}?`, 'Would you like to delete this goal?', [
                            { text: 'Cancel', style: 'cancel' },
                            {
                                text: 'Delete',
                                onPress: () => {
                                    dispatch(deleteGoal(goal.id, accountId));
                                    navigation.goBack();
                                },
                                style: 'destructive',
                            },
                        ]);
                    }}>
                    <IconTrash colorKey="primary"/>
                  </NavigationHeaderRightIconButton>)
                : undefined,
        });
    }, [account?.id, colors.text.primary, goal]);
    const onSetName = (name = '') => {
        setState((state) => ({ ...state, name }));
    };
    const currentAmount = useMemo(() => {
        const { selectedAccount } = state;
        if (!selectedAccount || selectedAccount.isClosed)
            return 0;
        if (selectedAccount.availableBalance ||
            selectedAccount.availableBalance === 0)
            return selectedAccount.availableBalance;
        return selectedAccount.postedBalance || 0;
    }, [state]);
    const onSetTargetAmount = (targetBalance) => {
        const targetAmount = targetBalance
            ? parseFloat(targetBalance.replace(/[^\d.-]/g, ''))
            : 0;
        setState((state) => ({ ...state, targetAmount }));
    };
    const [, , , fetch] = useFetchOld(muteAll);
    const nameInputRef = useRef(null);
    const valueRef = useRef(null);
    const dateRef = useRef(null);
    const save = useCallback(() => {
        const { name, targetAmount, targetDate, selectedAccount } = state;
        if (!name || !targetAmount || !targetDate || !selectedAccount) {
            Alert.alert(emoji.emojify('Slow down :face_with_rolling_eyes:'), 'You must fill all the fields.');
        }
        else if (targetAmount <= currentAmount) {
            valueRef.current?.getElement().focus();
            Alert.alert(emoji.emojify('Slow down :face_with_rolling_eyes:'), 'Your target amount should be bigger than you current balance.');
        }
        else if (isWeb &&
            !isValid(parse(targetDate, dateFormat, new Date()))) {
            dateRef.current?.getElement().focus();
            Alert.alert(emoji.emojify('Slow down :face_with_rolling_eyes:'), '');
        }
        else if (isWeb && isBefore(parseISO(targetDate), new Date())) {
            dateRef.current?.getElement().focus();
            Alert.alert(emoji.emojify('Slow down :face_with_rolling_eyes:'), 'The date must be in the future.');
        }
        else if (selectedAccount?.id && storeGoals[selectedAccount.id]) {
            setState((state) => ({ ...state, isSaving: true }));
            fetch(editGoal(storeGoals[selectedAccount.id].id, {
                accountId: selectedAccount.id,
                name,
                targetAmount,
                targetDate: format(parseISO(targetDate), 'yyyy-MM-dd'),
            })).then(() => {
                fetch(getAccountGoals()).then(() => {
                    if (navigation.canGoBack()) {
                        navigation.goBack();
                    }
                    else {
                        navigation.navigate('Transactions', { id: selectedAccount.id });
                    }
                });
            });
        }
        else {
            setState((state) => ({ ...state, isSaving: true }));
            fetch(addGoal({
                accountId: selectedAccount.id,
                name,
                targetAmount,
                targetDate: format(parseISO(targetDate), 'yyyy-MM-dd'),
            })).then((response) => {
                if (response) {
                    fetch(getAccountGoals()).then(() => {
                        navigation.navigate('Success', {
                            subTitle: 'Your goal has been created!',
                            onContinue: () => {
                                if (navigation.canGoBack()) {
                                    navigation.pop(2);
                                }
                                else {
                                    navigation.navigate('Transactions', {
                                        id: selectedAccount.id,
                                    });
                                }
                                return true;
                            },
                        });
                    });
                }
                else {
                    setState((state) => ({ ...state, isSaving: false }));
                }
            });
        }
        Keyboard.dismiss();
    }, [currentAmount, state, storeGoals, dateFormat]);
    const onPressSave = useRequiredBenefitCallbackWithSpaces(save, 'savingGoals');
    const minDate = useMemo(() => addDays(new Date(), 1), []);
    const { showDateTimePicker } = useDateTimePicker({
        initialValue: state.targetDate ? parseISO(state.targetDate) : minDate,
        minimumDate: minDate,
        onSubmitDate: (date) => {
            setState((oldState) => ({
                ...oldState,
                targetDate: date.toISOString(),
            }));
        },
    });
    const { targetDate, selectedAccount } = state;
    const defaultCurrency = useAppSelector(selectDefaultCurrency);
    const { name, targetAmount, isSaving } = state;
    let displayDate = targetDate && format(parseISO(targetDate), 'dd-MM-yyyy');
    if (targetDate && monthFirst) {
        displayDate = format(parseISO(targetDate), 'MM-dd-yyyy');
    }
    const { paddingHorizontalStyle } = useAppFrameDimensions(isWeb);
    const contentContainerStyle = useMarginBottom('paddingBottom', HEIGHT + rem(16), paddingHorizontalStyle);
    const dateErrorText = useMemo(() => {
        if (isWeb && targetDate?.length === 10) {
            const parsedDate = parse(targetDate, dateFormat, new Date());
            if (!isValid(parsedDate)) {
                return 'Enter a valid date.';
            }
            if (isBefore(parsedDate, new Date())) {
                return 'The date must be in the future.';
            }
        }
        return undefined;
    }, [targetDate, dateFormat]);
    const formContent = (<>
          <SettingsItemSelectable>
            <SettingsItemInput icon={<IconNote />} value={name} onChangeText={onSetName} onClearTextInput={onSetName} placeholder="Add your note here" description="What are you saving?" ref={nameInputRef}/>
          </SettingsItemSelectable>
          <View ref={accountsTouchViewRef}>
            <SettingsItemSelectable>
              <SettingsItemNavigate icon={<IconWallet />} onPress={openSelectAccount} description="Account" title={selectedAccount?.name || 'Choose...'} isSelected/>
            </SettingsItemSelectable>
          </View>
          <SettingsItemSelectable>
            <SettingsItemInputMask ref={valueRef} icon={<IconCoinStack gradientKey="ultimate"/>} value={targetAmount?.toString()} shouldUsePlaceholderColor={!targetAmount} onChangeText={onSetTargetAmount} onClearTextInput={() => {
            setState((state) => ({ ...state, targetAmount: 0 }));
        }} description="Goal value" type="money" returnKeyLabel="Done" returnKeyType="done" options={{
            unit: getSymbolFromCurrency(defaultCurrency),
            separator: '.',
            delimiter: ',',
            precision: 0,
        }} keyboardType="number-pad" subDescription={state.selectedAccount ? (<>
                    Current balance{' '}
                    <Text Text-14 Secondary>
                      {formatAmount(currentAmount, state.selectedAccount.currency)}
                    </Text>
                  </>) : undefined}/>
          </SettingsItemSelectable>
          <SettingsItemSelectable errorText={dateErrorText}>
            {isWeb ? (<SettingsItemInputMask ref={dateRef} icon={<IconCalendar gradientKey="lightPink"/>} description="Finish saving on" placeholder="DD/MM/YYYY" value={targetDate} shouldUsePlaceholderColor={!targetDate} onChangeText={(text) => {
                setState((s) => ({
                    ...s,
                    targetDate: text,
                }));
            }} onClearTextInput={() => {
                setState((state) => ({ ...state, targetDate: '' }));
            }} type="datetime" returnKeyLabel="Done" returnKeyType="done" options={{
                format: dateFormat,
            }} keyboardType="number-pad"/>) : (<SettingsItemNavigate icon={<IconCalendar gradientKey="lightPink"/>} description="Finish saving on" onPress={showDateTimePicker} isSelected title={displayDate || 'Choose date...'}/>)}
          </SettingsItemSelectable>
        </>);
    return (<AppView withFrame={false}>
          <StatusBar barStyle="dark-content"/>
          <AnimatedKeyboardAwareScrollView style={styles.content} onScroll={scrollHandler} contentContainerStyle={contentContainerStyle}>
            {isWeb ? (<>
                <Spacer h={8}/>
                {formContent}
              </>) : (<SettingsSection>{formContent}</SettingsSection>)}
          </AnimatedKeyboardAwareScrollView>
          <Button brand floating onPress={onPressSave} title="Save" loading={isSaving}/>
        </AppView>);
}, ({ route, colors }) => ({
    title: route.params?.goalId ? 'Edit your goal' : 'Set a saving goal',
    headerColorOutputRange: [
        colors.background.dark,
        colors.cards.onDark,
    ],
}));
const styles = StyleSheet.create({
    content: {
        flex: 1,
    },
});
export default SavingGoalEditScreen;
