import React, { memo } from 'react';
import Logo from 'design-system/Logo';
import { Avatar } from 'design-system/avatar';
import useColors from 'hooks/useColors';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
const SubscriptionLogo = ({ name, url }) => {
    const colors = useColors();
    const styles = useStyles(styleSet);
    if (!url ||
        url === 'https://app.emma-cdn.com/images/icons/subscriptions.png') {
        return (<Avatar size={42} borderWidth={1} name={name || 'Emma'} style={styles.avatar} fontColor={colors.text.textOnBrand} color={colors.background.brandLight}/>);
    }
    return <Logo uri={url} resizeMode="contain" R44/>;
};
const styleSet = createStyleSheets((colors) => ({
    avatar: {
        borderColor: colors.borders.iconStroke,
    },
}));
export default memo(SubscriptionLogo);
