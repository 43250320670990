import React, { useId } from 'react';
import { Path } from 'react-native-svg';
import useColors from 'hooks/useColors';
import { IconWithGradient } from './Icon';
export const IconFilterBold = ({ color, dot, dotColor, ...props }) => {
    const colors = useColors();
    const id = useId();
    return (<IconWithGradient id={id} {...props} defaultGradientKey="white" viewBox="0 0 25 24">
      <Path fill={color ?? colors.text.white} fillRule="evenodd" d="M6.532 4.25c-2.404 0-3.672 2.82-2.064 4.59l4.008 4.41c.542.596.843 1.37.843 2.174v3.826c0 1.38 1.13 2.5 2.524 2.5 1.394 0 2.524-1.12 2.524-2.5v-3.756c0-.837.326-1.642.91-2.247l4.198-4.346c1.689-1.75.437-4.651-2.007-4.651H6.532Z" clipRule="evenodd"/>
      {dot && (<Path fill={dotColor ?? colors.elements.positive} stroke={colors.borders.iconStroke} d="M20 9.5a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9Z"/>)}
    </IconWithGradient>);
};
export default IconFilterBold;
