import { addDays, addMonths, format, parseISO, subMonths } from 'date-fns';
import { assertUnreachable } from 'utils';
import { FinancialsPeriod, LimitStopOrderExpiryDateType, } from '../types';
export const filterPortfolioByTotalValue = (symbols, positionsCache) => {
    filterDataByKey(symbols, positionsCache, 'amount');
};
export const filterPortfolioByGainLoss = (symbols, positionsCache) => {
    filterDataByKey(symbols, positionsCache, 'unrealizedProfitLoss');
};
export const filterPortfolioByReturnPercentage = (symbols, positionsCache) => {
    filterDataByKey(symbols, positionsCache, 'unrealizedProfitLossPct');
};
export const filterDataByKey = (symbols, positionsCache, key) => {
    symbols.sort((b, a) => {
        const amountA = typeof positionsCache[a]?.[key] === 'number'
            ? positionsCache[a]?.[key]
            : positionsCache[a]?.[key]?.value;
        const amountB = typeof positionsCache[b]?.[key] === 'number'
            ? positionsCache[b]?.[key]
            : positionsCache[b]?.[key]?.value;
        if (amountA !== undefined && amountB !== undefined) {
            return amountA - amountB;
        }
        if (amountA !== undefined) {
            return 1;
        }
        if (amountB !== undefined) {
            return -1;
        }
        return 0;
    });
};
const SI_SYMBOL = ['', 'k', 'M', 'B', 'T', 'P', 'E'];
export const abbreviateNumber = (number) => {
    if (!number)
        return '0.0';
    // what tier? (determines SI symbol)
    const tier = Math.floor(Math.log10(Math.abs(number)) / 3) || 0;
    // if zero, we don't need a suffix
    if (tier === 0)
        return number.toFixed(2).slice(0, -1);
    // get suffix and determine scale
    const suffix = SI_SYMBOL[tier];
    const scale = 10 ** (tier * 3);
    // scale the number
    const scaled = number / scale;
    // format number and add suffix
    return scaled.toFixed(2).slice(0, -1) + suffix;
};
export const abbreviateNumberWorklet = (number) => {
    'worklet';
    if (!number)
        return '0.0';
    // what tier? (determines SI symbol)
    const tier = Math.floor(Math.log10(Math.abs(number)) / 3) || 0;
    // if zero, we don't need a suffix
    if (tier === 0)
        return number.toFixed(2).slice(0, -1);
    // get suffix and determine scale
    const suffix = SI_SYMBOL[tier];
    const scale = 10 ** (tier * 3);
    // scale the number
    const scaled = number / scale;
    // format number and add suffix
    return scaled.toFixed(2).slice(0, -1) + suffix;
};
export const abbreviateNumberWithSymbol = (number, currencySymbol = '') => {
    if (!number)
        return `${currencySymbol}0.0`;
    const sign = number < 0 ? '-' : '';
    return sign + currencySymbol + abbreviateNumber(Math.abs(number));
};
export const getPeriodKey = (selection) => {
    switch (selection) {
        case FinancialsPeriod.ANNUAL:
            return 'annual';
        case FinancialsPeriod.QUARTERLY:
            return 'quarterly';
        default:
            // This case shouldn't arise:
            return 'annual';
    }
};
// formats the decimal number to with only 1 number after dot and adds %.
export const getMarginStr = (dividend, divisor) => `${(dividend / divisor).toFixed(2).slice(0, -1)}%`;
// tradingBlockExpiryDate is in UTC
export const formatBuyBlock = (tradingBlockExpiryDate) => format(parseISO(tradingBlockExpiryDate), "d MMM 'at' HH:mm");
const getPercent = (num) => Number(`${Math.round(parseFloat(`${num}e${2}`))}e-${2}`);
export const getParsedAnalystData = (analystInfo) => {
    if (analystInfo.numberOfBuy !== undefined &&
        analystInfo.numberOfStrongBuy !== undefined &&
        analystInfo.numberOfSell !== undefined &&
        analystInfo.numberOfStrongSell !== undefined &&
        analystInfo.numberOfHold !== undefined &&
        analystInfo.currency &&
        analystInfo.priceTargetLow !== undefined &&
        analystInfo.priceTargetHigh !== undefined &&
        analystInfo.priceTargetMean !== undefined) {
        const buyTotal = analystInfo.numberOfBuy + analystInfo.numberOfStrongBuy;
        const sellTotal = analystInfo.numberOfSell + analystInfo.numberOfStrongSell;
        const holdTotal = analystInfo.numberOfHold;
        const ratingsTotal = buyTotal + sellTotal + holdTotal;
        if (ratingsTotal) {
            return {
                buyTotal,
                sellTotal,
                holdTotal,
                ratingsTotal,
                currency: analystInfo.currency,
                priceTargetLow: analystInfo.priceTargetLow,
                priceTargetHigh: analystInfo.priceTargetHigh,
                priceTargetMean: analystInfo.priceTargetMean,
                buyPercent: getPercent((buyTotal / ratingsTotal) * 100),
                holdPercent: getPercent((holdTotal / ratingsTotal) * 100),
                sellPercent: getPercent((sellTotal / ratingsTotal) * 100),
            };
        }
    }
    return undefined;
};
export const getAnalystDetails = (analystInfo) => {
    const responseMap = analystInfo.reduce((prev, curr) => {
        const { date, numberOfStrongBuy, numberOfBuy, numberOfHold, numberOfSell, numberOfStrongSell } = curr;
        if (date &&
            numberOfStrongBuy !== undefined &&
            numberOfBuy !== undefined &&
            numberOfHold !== undefined &&
            numberOfSell !== undefined &&
            numberOfStrongSell !== undefined) {
            const buyTotal = numberOfBuy + numberOfStrongBuy;
            const sellTotal = numberOfSell + numberOfStrongSell;
            const holdTotal = numberOfHold;
            const ratingsTotal = buyTotal + sellTotal + holdTotal;
            if (ratingsTotal) {
                return {
                    ...prev,
                    [date]: {
                        date,
                        buyTotal,
                        sellTotal,
                        holdTotal,
                        ratingsTotal,
                        buyPercent: getPercent((buyTotal / ratingsTotal) * 100),
                        holdPercent: getPercent((holdTotal / ratingsTotal) * 100),
                        sellPercent: getPercent((sellTotal / ratingsTotal) * 100),
                    },
                };
            }
        }
        return prev;
    }, {});
    const analystDetails = [];
    for (let i = 0; i < 8; i++) {
        const date = format(subMonths(new Date(), i), 'yyyy-MM');
        analystDetails.push({
            date,
            data: responseMap[date],
        });
    }
    return analystDetails;
};
export const getLimitStopOrderExpiryText = (expiryDate) => {
    if (expiryDate.type === LimitStopOrderExpiryDateType.NINETY_DAYS) {
        return '90 days';
    }
    if (expiryDate.type === LimitStopOrderExpiryDateType.SIX_MONTHS) {
        return '6 months';
    }
    if (expiryDate.type === LimitStopOrderExpiryDateType.TODAY) {
        return 'End of the day';
    }
    if (expiryDate.type === LimitStopOrderExpiryDateType.CUSTOM) {
        return format(expiryDate.date, `d LLLL',' yyyy`);
    }
    assertUnreachable(expiryDate);
    return '';
};
const formatStr = `yyyy'-'MM'-'dd`;
export const getLimitStopOrderExpiryDate = (expiryDate) => {
    const date = new Date();
    if (expiryDate.type === LimitStopOrderExpiryDateType.NINETY_DAYS) {
        return format(addDays(date, 90), formatStr);
    }
    if (expiryDate.type === LimitStopOrderExpiryDateType.SIX_MONTHS) {
        return format(addMonths(date, 6), formatStr);
    }
    if (expiryDate.type === LimitStopOrderExpiryDateType.TODAY) {
        return format(date, formatStr);
    }
    if (expiryDate.type === LimitStopOrderExpiryDateType.CUSTOM) {
        return format(expiryDate.date, formatStr);
    }
    assertUnreachable(expiryDate);
    return '';
};
