import React, { useCallback } from 'react';
import { View, StyleSheet } from 'react-native';
import Modal from 'design-system/Modal';
import Banner from 'design-system/Banner';
import { Spacer, rem } from 'design-system';
import IconBlock from 'design-system/icons/IconBlock';
import SectionHeader from 'design-system/SectionHeader';
import { muteAll, useFetchOld } from 'hooks';
import { useMainStackNavigation } from 'utils/types/navigationV6';
import { editSubscription } from '../actions';
import SubscriptionLogo from './SubscriptionLogo';
import { isWeb } from '../../../constants';
const InactiveSubscriptionHeader = ({ name, hasData, subscription, }) => {
    const navigation = useMainStackNavigation();
    const [, , , fetch] = useFetchOld(muteAll);
    const changeToActive = useCallback(() => {
        const onConfirm = () => {
            fetch(editSubscription(subscription.id, {
                isActive: !subscription.isActive,
            }));
            Modal.hide();
            navigation.goBack();
        };
        Modal.showConfirmation('Incorrect type', `Is this recurring payment ${subscription.isActive ? 'inactive' : 'active'}?`, 'Yes, it is', 'No, thanks', onConfirm, Modal.hide);
    }, [subscription.id, subscription.isActive]);
    return (<>
      <View style={styles.logoBox}>
        <SubscriptionLogo name={name} url={subscription.merchantInfo?.iconUrl}/>
      </View>
      <Spacer h={30}/>
      <Banner noMarginBottom icon={<IconBlock colorKey="brand"/>} buttonTitle="Activate" onPress={changeToActive} title="This recurring payment is inactive" description="This can happen if there are no new payments. You are free to activate it, if you think this is a mistake" hideButton={isWeb}/>
      {hasData && (<>
          <SectionHeader horizontalMargins style={styles.cardSectionHeader}>
            Past payments
          </SectionHeader>
          <Spacer.H16 />
        </>)}
    </>);
};
export default InactiveSubscriptionHeader;
const styles = StyleSheet.create({
    logoBox: {
        alignItems: 'center',
    },
    cardSectionHeader: {
        marginBottom: 0,
        marginTop: rem(24),
    },
});
