import React, { useLayoutEffect } from 'react';
import { StyleSheet } from 'react-native';
import Animated, { useAnimatedScrollHandler } from 'react-native-reanimated';
import { AppView } from 'design-system/AppView';
import { HelpNavigationButton } from 'design-system/TextButton';
import { useSupportActionSheet } from 'features/settings/hooks';
import StatusBar from 'design-system/StatusBar';
import { Indicator } from 'design-system/Indicator';
import { rem } from 'design-system/values';
import withAnimatedNavigationHeader from 'design-system/hoc/withAnimatedNavigationHeader';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import { useAppSelector } from 'store/hooks';
import { AccountSettingsHeader } from './AccountSettingsHeader';
import { AccountAndPreference } from './AccountAndPreference';
import { AboutAndImportantStuff } from './AboutAndImportantStuff';
import { AppVersion } from './AppVersion';
import { isWeb } from '../../../constants';
const sections = [AccountAndPreference, AboutAndImportantStuff];
const strings = {
    header: {
        title: 'Settings',
    },
};
const keyExtractor = (item) => item.name;
const renderItem = ({ item: Section }) => <Section />;
export const HelpButton = () => {
    const { showSupportActionSheet } = useSupportActionSheet();
    return <HelpNavigationButton black onPress={showSupportActionSheet}/>;
};
export const AccountSettingsScreen = withAnimatedNavigationHeader(({ scrollY, navigation }) => {
    const userId = useAppSelector((state) => state.user.user.id);
    useLayoutEffect(() => {
        if (!isWeb) {
            navigation.setOptions({
                headerRight: () => <HelpButton />,
            });
        }
    }, []);
    const scrollHandler = useAnimatedScrollHandler((handle) => {
        // eslint-disable-next-line no-param-reassign
        scrollY.value = handle.contentOffset.y;
    });
    const { paddingHorizontalStyle } = useAppFrameDimensions(isWeb);
    if (userId === -1) {
        return (<AppView type="secondary" withFrame={false}>
          <Indicator dark/>
        </AppView>);
    }
    return (<AppView type="secondary" withFrame={false}>
        <StatusBar barStyle="dark-content" animated/>
        <Animated.FlatList data={sections} ListHeaderComponent={AccountSettingsHeader} scrollEventThrottle={16} ListFooterComponent={AppVersion} renderItem={renderItem} onScroll={scrollHandler} keyExtractor={keyExtractor} contentContainerStyle={paddingHorizontalStyle} ListHeaderComponentStyle={styles.header}/>
      </AppView>);
}, ({ colors }) => ({
    title: strings.header.title,
    hideTitleOnScroll: true,
    headerColorOutputRange: [
        colors.background.dark,
        colors.cards.onDark,
    ],
}));
const styles = StyleSheet.create({
    header: {
        paddingTop: rem(16),
    },
});
