import React, { useLayoutEffect } from 'react';
import { ScrollView } from 'react-native';
import { AppView } from 'design-system/AppView';
import Code404Card from 'design-system/Code404Card';
import useStyles from 'hooks/useStyles';
import { useMarginBottom } from 'hooks/useMarginBottom';
import createStyleSheets from 'utils/createStyleSheets';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import { useMainStackNavigation } from 'utils/types/navigationV6';
import { useIsSignedInWeb } from '../hooks/useIsSignedInWeb';
const NotFound = () => {
    const styles = useStyles(styleSet);
    const { isSignedIn } = useIsSignedInWeb();
    const { paddingHorizontalStyle } = useAppFrameDimensions(isSignedIn);
    const contentContainerStyle = useMarginBottom('paddingBottom', undefined, paddingHorizontalStyle, styles.container);
    const navigation = useMainStackNavigation();
    useLayoutEffect(() => {
        navigation.setOptions({
            title: 'Not Found',
            headerShown: false,
        });
    }, []);
    return (<AppView type="secondary" withFrame={false} avoidHeader>
      <ScrollView contentContainerStyle={contentContainerStyle}>
        <Code404Card />
      </ScrollView>
    </AppView>);
};
const styleSet = createStyleSheets(() => ({
    container: {
        paddingTop: '10%',
    },
}));
export default NotFound;
