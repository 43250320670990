import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState, } from 'react';
import { StyleSheet, View } from 'react-native';
import WebTabs from 'design-system/WebTabs.web';
import StatusBar from 'design-system/StatusBar';
import { AppView } from 'design-system/AppView';
import withAnimatedNavigationHeader from 'design-system/hoc/withAnimatedNavigationHeader';
import useColors from 'hooks/useColors';
import useFetchOld from 'hooks/useFetch';
import { getPreferences } from 'actions/user';
import PushNotificationSettings from './components/PushNotificationSettings';
import EmailNotificationSettings from './components/EmailNotificationSettings';
const tabs = ['Push', 'Email'];
const fetchConfig = {
    muteResult: true,
};
export const NotificationsScreen = withAnimatedNavigationHeader(({ navigation, scrollY }) => {
    const [, error, isFetching, fetch] = useFetchOld(fetchConfig);
    useEffect(() => {
        fetch(getPreferences());
    }, []);
    const colors = useColors();
    const [selectedItem, setSelectedItem] = useState('Push');
    useLayoutEffect(() => {
        navigation.setOptions({
            headerTintColor: colors.text.primary,
        });
    }, [colors.text.primary]);
    const selectTab = useCallback((tab) => {
        if (tab !== selectedItem) {
            setSelectedItem(tab);
        }
    }, [selectedItem]);
    const Tabs = useMemo(() => (<WebTabs tabs={tabs} activeTab={selectedItem} handleTabChange={selectTab}/>), [selectTab, selectedItem]);
    return (<AppView withFrame={false}>
          <StatusBar barStyle="dark-content" animated/>
          <View style={styles.container}>
            {selectedItem === 'Push' && (<PushNotificationSettings error={error} scrollYPush={scrollY} isFetching={isFetching} ListHeaderComponent={Tabs}/>)}
            {selectedItem === 'Email' && (<EmailNotificationSettings error={error} scrollYPush={scrollY} isFetching={isFetching} ListHeaderComponent={Tabs}/>)}
          </View>
        </AppView>);
}, ({ colors }) => ({
    title: 'Notifications',
    headerColorOutputRange: [
        colors.background.dark,
        colors.cards.onDark,
    ],
}));
const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
});
