import React, { useCallback, useLayoutEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { AppView } from 'design-system/AppView';
import Button from 'design-system/Button';
import { ButtonGroup } from 'design-system/ButtonGroup';
import { CardView } from 'design-system/CardView';
import NavigationHeaderRightIconButton from 'design-system/NavigationHeaderRightIconButton';
import StatusBar from 'design-system/StatusBar';
import { IconTrash } from 'design-system/icons';
import { formatAmount } from 'features/invest/utils';
import { useRequiredBenefitCallbackWithSpaces } from 'features/premium/hooks/useRequiredBenefitCallback';
import useColors from 'hooks/useColors';
import { useMarginBottom } from 'hooks/useMarginBottom';
import Alert from 'utils/packages/Alert';
import { isWeb } from '../../../constants';
import BudgetCategoryEditRow from '../components/BudgetCategoryEditRow';
import { useSaveBudget } from '../hooks/useSaveBudget';
const strings = {
    successTitle: 'Success!',
};
const DEFAULT_BUDGET_COLOR = 'rgb(124, 78, 255)';
const EditMerchantBudgetScreen = ({ navigation, route, }) => {
    const merchantId = route.params.id;
    const [isBudgetFetching, , , saveBudgetByMerchants] = useSaveBudget();
    const [colorSelected] = useState(DEFAULT_BUDGET_COLOR);
    const limit = route.params.budgetLimit || 0;
    const deleteBudget = useCallback(() => {
        Alert.alert('Delete Merchant Budget', 'Do you want to delete this merchant budget?', [
            { text: 'Cancel', style: 'cancel' },
            {
                text: 'Delete',
                onPress: async () => {
                    await saveBudgetByMerchants(false, null, merchantId, colorSelected);
                    navigation.pop(2);
                },
                style: 'destructive',
            },
        ]);
    }, [colorSelected, merchantId, saveBudgetByMerchants]);
    const colors = useColors();
    useLayoutEffect(() => {
        const { budgetLimit } = route.params;
        navigation.setOptions({
            title: 'Set budget',
            headerTintColor: colors.text.primary,
            headerRight: budgetLimit !== undefined && budgetLimit !== null
                ? () => (<NavigationHeaderRightIconButton onPress={deleteBudget}>
                <IconTrash gradientColors={[
                        colors.elements.primary,
                        colors.elements.primary,
                    ]}/>
              </NavigationHeaderRightIconButton>)
                : undefined,
        });
    }, [
        colors.background.light,
        colors.elements.primary,
        colors.text.primary,
        deleteBudget,
        route.params,
    ]);
    const { displayName } = route.params;
    const currency = route.params.currency || 'GBP';
    const [value, setValue] = useState(limit);
    const onSubmitCallback = useCallback(async () => {
        await saveBudgetByMerchants(false, value, merchantId, colorSelected);
        navigation.navigate('Success', {
            title: strings.successTitle,
            buttonText: 'Done',
            subTitle: `Your ${formatAmount({
                value,
                currency,
            })} ${displayName} budget has been created!`,
            onContinue: () => {
                navigation.pop(route.params.backCount === undefined ? 2 : route.params.backCount);
                return true;
            },
        });
    }, [
        colorSelected,
        currency,
        displayName,
        merchantId,
        route.params.backCount,
        saveBudgetByMerchants,
        value,
    ]);
    const handlePress = useRequiredBenefitCallbackWithSpaces(onSubmitCallback, 'merchantBudgets');
    const marginBottom = useMarginBottom();
    return (<AppView type="secondary" avoidHeader isInTab={isWeb}>
      <StatusBar animated barStyle="dark-content"/>
      <View style={styles.container}>
        <CardView webPadding={isWeb}>
          <BudgetCategoryEditRow budget={{
            ...route.params,
            budgetLimit: value,
            merchant: true,
            currency,
        }} key={merchantId} onChange={(_, value) => setValue(Number(value))} hasAverages={false} withInitialFocus={false}/>
        </CardView>
      </View>
      <ButtonGroup>
        <Button style={marginBottom} brand title="Save" onPress={handlePress} loading={isBudgetFetching} disabled={value === 0}/>
      </ButtonGroup>
    </AppView>);
};
const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
});
export default EditMerchantBudgetScreen;
