import React from 'react';
import { View, ScrollView } from 'react-native';
export const DraggableList = ({ data, renderItem, keyExtractor, contentContainerStyle, }) => (<View style={contentContainerStyle}>
    {data.map((item, index) => (<View key={keyExtractor(item)}>
        {renderItem({
            item,
            drag: () => { },
            isActive: false,
            getIndex: () => index,
        })}
      </View>))}
  </View>);
export const DraggableScrollContainer = ({ children, contentContainerStyle, scrollEventThrottle, onScrollOffsetChange, }) => (<ScrollView contentContainerStyle={contentContainerStyle} scrollEventThrottle={scrollEventThrottle} onScroll={onScrollOffsetChange}>
    {children}
  </ScrollView>);
