import React, { memo, useMemo } from 'react';
import { View } from 'react-native';
import SubscriptionAd from './transactions/SubscriptionAd';
import SubscriptionHeader from './transactions/SubscriptionHeader';
import InactiveSubscriptionHeader from './InactiveSubscriptionHeader';
import SubscriptionPrediction from './transactions/SubscriptionScreenHeader';
import { useSubscriptionScreenContext } from '../context/subscriptionScreenContext';
import { getMinAndMax } from '../screens/AdjustSubscriptionScreen';
const SubscriptionScreenListHeader = ({ onEdit, onDone, onPress, editPrediction, }) => {
    const { data: subscription, hasData, name } = useSubscriptionScreenContext();
    const minMax = useMemo(() => {
        const minMax = getMinAndMax(subscription);
        return {
            min: minMax[0],
            max: minMax[1],
        };
    }, [subscription]);
    if (!(subscription && subscription.predictions))
        return null;
    const { predictions, nativePrice, accountId, id, isActive } = subscription;
    if (!isActive) {
        return (<InactiveSubscriptionHeader name={name} hasData={hasData} subscription={subscription}/>);
    }
    const { amount, currency } = nativePrice;
    return (<View>
      <SubscriptionHeader name={name} id={subscription?.id} minAmount={minMax.min} maxAmount={minMax.max} currency={subscription?.currency} minMonthDay={subscription?.minMonthDay} maxMonthDay={subscription?.maxMonthDay} iconUrl={subscription.merchantInfo?.iconUrl} paymentFrequency={subscription?.paymentFrequency}/>
      <SubscriptionAd onCard onPress={onPress} merchant={subscription?.merchantInfo}/>
      <SubscriptionPrediction date={predictions[0]?.date} subscriptionId={id} onPress={editPrediction} amount={-amount} currency={currency} accountId={accountId} onEdit={onEdit} onDone={onDone}/>
    </View>);
};
export default memo(SubscriptionScreenListHeader);
