import { REHYDRATE } from 'redux-persist';
import * as types from '../actions/types';
const initialState = {
    isFetching: false,
    rewards: [],
    redemptions: {},
};
const rewards = (state = initialState, action) => {
    switch (action.type) {
        case REHYDRATE:
            const rewards = action.payload?.rewards;
            const returnState = JSON.parse(JSON.stringify(initialState));
            if (rewards) {
                return Object.assign(returnState, rewards, {
                    rewards: [],
                });
            }
            return state;
        case types.GET_REDEMPTIONS_SUCCESS:
            const filteredRedemptions = { ...action.payload };
            Object.keys(filteredRedemptions).forEach((key) => {
                const item = filteredRedemptions[key];
                if (item) {
                    filteredRedemptions[key] = {
                        ...item,
                        rewards: item.rewards.filter((reward) => reward.totalAmount),
                    };
                }
            });
            return { ...state, redemptions: filteredRedemptions };
        case types.GET_REWARDS_FAILURE:
            return { ...state, isFetching: false };
        case types.GET_REWARDS_REQUEST:
            return { ...state, isFetching: true };
        case types.GET_REWARDS_SUCCESS:
            return { ...state, rewards: action.payload.rewards, isFetching: false };
        case types.LOGIN_SUCCESS:
            return { ...state, rewards: [] };
        case types.LOGGED_OUT:
            return initialState;
        default:
            return state;
    }
};
export default rewards;
