import React, { memo, useMemo } from 'react';
import Spacer from 'design-system/Spacer';
import { useAppSelector } from 'store/hooks';
import { selectFeedPriority } from 'reducers/selectors/organise';
import Analytics from './analytics/Analytics';
import Subscriptions from './transactions/Subscriptions';
import Transactions from './transactions/Transactions';
const FeedContent = ({ latestTransactions }) => {
    const organizedFeedArr = useAppSelector(selectFeedPriority);
    const priorityItemMap = useMemo(() => ({
        transactions: latestTransactions?.length ? (<Transactions key="Transactions" data={latestTransactions}/>) : (<React.Fragment key="Transactions"/>),
        analytics: <Analytics key="Analytics"/>,
        subscriptions: <Subscriptions key="Subscriptions"/>,
        completeSetup: <React.Fragment key="CompleteSetup"/>,
        fraudDetection: <React.Fragment key="FraudDetectionCard"/>,
    }), [latestTransactions]);
    return (<>
      <Spacer h={8}/>
      {organizedFeedArr.priority.map((item) => priorityItemMap[item] || null)}
    </>);
};
export default memo(FeedContent);
