import React, { useCallback, useEffect, useLayoutEffect } from 'react';
import Animated from 'react-native-reanimated';
import StatusBar from 'design-system/StatusBar';
import { AppView } from 'design-system/AppView';
import Button, { HEIGHT } from 'design-system/Button';
import { rem, SCROLL_INDICATOR_INSETS } from 'design-system/values';
import withAnimatedNavigationHeader from 'design-system/hoc/withAnimatedNavigationHeader';
import useColors from 'hooks/useColors';
import { useMarginBottom } from 'hooks/useMarginBottom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import { selectMonthlyTotals } from 'features/analytics/selectors';
import { useOpenCategory } from 'features/analytics/hooks/useOpenCategory';
import { getBudgets } from '../actions';
import { isWeb } from '../../../constants';
import UnbudgetedCategoriesList from '../components/UnbudgetedCategoriesList';
const UnbudgetedSpendScreen = withAnimatedNavigationHeader(({ scrollHandler, navigation }) => {
    const colors = useColors();
    useLayoutEffect(() => {
        navigation.setOptions({
            headerTintColor: colors.text.primary,
        });
    }, [colors.background.dark, colors.text.primary]);
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(getBudgets());
    }, []);
    const handlePress = useCallback(() => {
        navigation.navigate('Budgets', {});
    }, []);
    const monthlyTotals = useAppSelector(selectMonthlyTotals);
    const openCategory = useOpenCategory(monthlyTotals, 0, 'MonthlyCategory');
    const handlePressCategory = useCallback((data, type) => {
        openCategory({ data, type });
    }, [openCategory]);
    const { paddingHorizontalStyle } = useAppFrameDimensions(isWeb);
    const contentContainerStyle = useMarginBottom('paddingBottom', HEIGHT + rem(24), paddingHorizontalStyle);
    return (<AppView withFrame={false}>
        <StatusBar barStyle="dark-content" animated/>
        <Animated.ScrollView scrollEventThrottle={16} onScroll={scrollHandler} scrollIndicatorInsets={SCROLL_INDICATOR_INSETS} contentContainerStyle={contentContainerStyle}>
          <UnbudgetedCategoriesList onPressCategory={handlePressCategory}/>
        </Animated.ScrollView>
        <Button brand floating title="Set remaining budgets" onPress={handlePress}/>
      </AppView>);
}, ({ colors }) => ({
    title: 'Other categories',
    headerColorOutputRange: [
        colors.background.dark,
        colors.cards.onDark,
    ],
}));
export default UnbudgetedSpendScreen;
