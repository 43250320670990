import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Animated, Platform, TextInput as RNTextInput, TouchableOpacity, View } from 'react-native';
import ReactNativeHapticFeedback from 'react-native-haptic-feedback';
import { AppView } from 'design-system/AppView';
import Button from 'design-system/Button';
import MoneyInputField from 'design-system/MoneyInputField';
import ShakeContainer from 'design-system/ShakeContainer';
import Spacer from 'design-system/Spacer';
import StatusBar from 'design-system/StatusBar';
import Text from 'design-system/Text';
import { IconArrow } from 'design-system/icons/IconArrow';
import { hitSlop, rem } from 'design-system/values';
import { DEFAULT_MAX_AMOUNT, formatAmountText, getInitialScale, getScale, } from 'utils/formatting';
import EmptyComponent from 'design-system/EmptyComponent';
import KeyboardAvoidingView from 'design-system/KeyboardAvoidingView';
import ScreenInfoHeader from 'design-system/ScreenInfoHeader';
import Squircle from 'design-system/Squircle';
import { Sign } from 'features/transactions/screens/AddAmountScreen';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import { useAutoFocusInput } from 'hooks/useAutoFocusInput';
import useColors from 'hooks/useColors';
import { useMarginBottom } from 'hooks/useMarginBottom';
import useStyles from 'hooks/useStyles';
import { selectAccountById, selectDefaultCurrency } from 'reducers/selectors';
import { useAppSelector } from 'store/hooks';
import createStyleSheets from 'utils/createStyleSheets';
import { isWeb } from '../../../constants';
import PickCurrency from '../components/PickCurrency';
import PoistiveNegativeBalanceInput from '../components/PositiveNegativeBalanceInput';
import useDummyAccountLogo from '../hooks/useDummyAccountLogo';
const strings = {
    next: 'Next',
    changeCurrency: 'Change Currency',
    balance: 'balance',
    account: 'account',
    property: 'property',
    vehicle: 'vehicle',
    asset: 'asset',
    value: 'value',
};
const propertyStringMap = {
    REAL_ESTATE: strings.property,
    VEHICLE: strings.vehicle,
    OTHER: strings.asset,
};
const incomeExpenseStrings = {
    [Sign.Positive]: 'Swap to negative',
    [Sign.Negative]: 'Swap to positive',
};
const getSign = (postedBalance, type) => {
    const isPrePopulatedBalanceNegative = (postedBalance || 0) < 0;
    if (postedBalance === undefined) {
        return type === 'CREDITCARD' || type === 'LOAN' ? Sign.Negative : Sign.Positive;
    }
    return isPrePopulatedBalanceNegative ? Sign.Negative : Sign.Positive;
};
const getFormatAmountTextResult = (nativeBalance, postedBalance) => {
    if (nativeBalance?.postedBalance !== undefined) {
        return formatAmountText(Math.abs(nativeBalance.postedBalance).toString(), null);
    }
    if (postedBalance !== undefined) {
        return formatAmountText(Math.abs(postedBalance).toString(), null);
    }
    return {
        amount: 0,
        fixedText: '',
        displayAmount: '0',
        maxLength: DEFAULT_MAX_AMOUNT.toString().length + 3, // 3 is for cents
    };
};
const AddBalanceScreen = ({ navigation, route }) => {
    const styles = useStyles(styleSet);
    const account = useAppSelector((store) => selectAccountById(store, route.params.id));
    const type = route.params.type || account?.type;
    const { nativeBalance, postedBalance } = account || {};
    const { isChangingBalance } = route.params;
    const colors = useColors();
    useLayoutEffect(() => {
        navigation.setOptions({
            headerTintColor: colors.text.primary,
            ...(isWeb && {
                title: 'Add balance',
                headerTitle: EmptyComponent,
            }),
        });
    }, [colors.text.primary]);
    const [state, setState] = useState(() => getFormatAmountTextResult(nativeBalance, postedBalance));
    const defaultValue = nativeBalance?.postedBalance || postedBalance;
    const [value, setValue] = useState(defaultValue?.toFixed(2) || '0.00');
    const [rawValue, setRawValue] = useState(defaultValue?.toString() || '0');
    const [sign, setSign] = useState(() => getSign(postedBalance, type));
    const [showCurrencyPicker, setShowCurrencyPicker] = useState(false);
    const defaultCurrency = useAppSelector(selectDefaultCurrency);
    const [currency, setCurrency] = useState(nativeBalance?.currency ?? defaultCurrency);
    useEffect(() => {
        if (defaultValue && state.amount === 0) {
            setValue(defaultValue?.toFixed(2) || '0.00');
            setRawValue(defaultValue.toString());
            setSign(getSign(postedBalance, type));
            setCurrency(nativeBalance?.currency ?? defaultCurrency);
        }
    }, [defaultValue]);
    const icon = useDummyAccountLogo(type);
    const paddingBottomStyle = useMarginBottom('paddingBottom');
    const shake = useRef();
    const textInputRef = useRef(null);
    useAutoFocusInput(textInputRef);
    const amountScale = useRef(new Animated.Value(getInitialScale(DEFAULT_MAX_AMOUNT, (nativeBalance?.postedBalance ?? postedBalance ?? 0).toString()))).current;
    useAutoFocusInput(textInputRef);
    const onOpenCurrencyPicker = useCallback(() => {
        setShowCurrencyPicker(true);
    }, []);
    const hideCurrencySelector = useCallback(() => {
        setShowCurrencyPicker(false);
    }, []);
    const onSelectCurrency = useCallback((value) => {
        setCurrency(value);
    }, []);
    const warnAboutWrongAmount = useCallback(() => {
        if (shake.current) {
            shake.current();
        }
        ReactNativeHapticFeedback.trigger('notificationError');
        textInputRef.current?.focus();
    }, []);
    const onChangeText = useCallback((text) => {
        const values = formatAmountText(text);
        if (values) {
            setState(values);
            Animated.timing(amountScale, {
                toValue: getScale(values.displayAmount),
                duration: 100,
                delay: 200,
                useNativeDriver: true,
            }).start();
        }
        else {
            warnAboutWrongAmount();
        }
    }, [amountScale, warnAboutWrongAmount]);
    const onSubmit = useCallback(() => {
        textInputRef.current?.blur();
        const keyBack = route.params.keyBack || (isWeb ? undefined : route.key);
        const data = route.params || {};
        const balance = isWeb ? Number(rawValue) : state.amount;
        if (isChangingBalance) {
            navigation.navigate('DummyAccountCreated', {
                ...data,
                keyBack,
                currency,
                balance: balance * (sign === Sign.Negative ? -1 : 1),
            });
        }
        else {
            navigation.navigate('DummyAccountSetup', {
                ...data,
                keyBack,
                currency,
                balance: balance * (sign === Sign.Negative ? -1 : 1),
            });
        }
    }, [route.params, route.key, rawValue, state.amount, isChangingBalance, currency, sign]);
    const onFlipIncomeExpense = useCallback(() => {
        setSign((currentSign) => {
            if (currentSign === Sign.Negative) {
                return Sign.Positive;
            }
            return Sign.Negative;
        });
    }, []);
    const controls = useMemo(() => (<View style={paddingBottomStyle}>
        <TouchableOpacity onPress={onFlipIncomeExpense} hitSlop={hitSlop} style={styles.centered}>
          <Text Text-16 OnBrand>
            {incomeExpenseStrings[sign]}
          </Text>
        </TouchableOpacity>
        <Spacer h={16}/>
        <View style={styles.btnContainer}>
          {!isChangingBalance && !isWeb && (<>
              <Button hug brandReversed title={currency || ''} style={styles.currencyBtn} onPress={onOpenCurrencyPicker} rightIcon={<IconArrow direction="down" color={colors.text.textOnBrand}/>}/>
              <Spacer w={8}/>
            </>)}
          <Button flex brand title={strings.next} onPress={onSubmit}/>
        </View>
      </View>), [
        colors.text.textOnBrand,
        currency,
        isChangingBalance,
        onFlipIncomeExpense,
        onOpenCurrencyPicker,
        onSubmit,
        paddingBottomStyle,
        sign,
        styles.btnContainer,
        styles.centered,
        styles.currencyBtn,
    ]);
    const title = useMemo(() => {
        let firstText = strings.balance;
        let secondText = strings.account;
        if (type === 'REAL_ESTATE' || type === 'VEHICLE' || type === 'OTHER') {
            firstText = strings.value;
            secondText = propertyStringMap[type];
        }
        return `What's the current ${firstText}\nfor this ${secondText}?`;
    }, [type]);
    const { paddingHorizontalStyle } = useAppFrameDimensions(isWeb);
    const onChangeMaskedText = useCallback((value, raw) => {
        setValue(value);
        if (typeof raw !== 'undefined') {
            setRawValue(raw);
        }
    }, []);
    if (isWeb) {
        return (<AppView type="secondary" isInTab avoidHeader>
        <ScreenInfoHeader title={title.replace('\n', ' ')}/>
        <View style={styles.container}>
          <StatusBar animated barStyle="dark-content"/>
          <PoistiveNegativeBalanceInput value={value} currency={currency} maxLength={DEFAULT_MAX_AMOUNT.toString().length + 3} onSubmitEditing={onSubmit} onChangeText={onChangeMaskedText} sign={sign}/>
        </View>
        <KeyboardAvoidingView style={[styles.buttonContainer, paddingHorizontalStyle]} behavior="position" pointerEvents="box-none">
          {controls}
        </KeyboardAvoidingView>
      </AppView>);
    }
    return (<AppView type="primary" style={styles.container}>
      <StatusBar animated barStyle="dark-content"/>
      <Squircle height={66} width={66} color={colors.cards.onLight}>
        {icon}
      </Squircle>
      <Spacer h={16}/>
      <Text TextThin-20 Secondary centered>
        {title}
      </Text>
      <ShakeContainer style={styles.amountContainer} registerCallback={(callback) => {
            shake.current = callback;
        }} shakeOnMount={false}>
        <Animated.View style={[styles.amountScalableContainer, { transform: [{ scale: amountScale }] }]}>
          <MoneyInputField color={colors.text.primary} currency={currency}>
            {`${sign}${state.displayAmount}`}
          </MoneyInputField>
        </Animated.View>
        <RNTextInput ref={textInputRef} style={styles.transparentTextInput} value={state.fixedText} allowFontScaling={false} blurOnSubmit={false} // on iOS there is no way to submit, and on android if user submits 0 then we would have an issue
     caretHidden contextMenuHidden enablesReturnKeyAutomatically keyboardType="decimal-pad" maxLength={state.maxLength} onChangeText={onChangeText} onSubmitEditing={onSubmit} testID="input"/>
      </ShakeContainer>
      {Platform.OS === 'android' ? (<View style={[styles.buttonContainer, paddingHorizontalStyle]}>{controls}</View>) : (<KeyboardAvoidingView style={[styles.buttonContainer, paddingHorizontalStyle]} behavior="position" pointerEvents="box-none">
          {controls}
        </KeyboardAvoidingView>)}
      <PickCurrency onSelectedCurrency={onSelectCurrency} onPickCancel={hideCurrencySelector} visible={showCurrencyPicker}/>
    </AppView>);
};
const styleSet = createStyleSheets(() => ({
    container: isWeb
        ? {
            flex: 1,
            paddingHorizontal: rem(16),
        }
        : {
            flex: 1,
            alignItems: 'center',
            paddingTop: rem(100),
        },
    amountContainer: {
        marginTop: rem(40),
        width: '100%',
        height: rem(120),
        alignItems: 'center',
        justifyContent: 'center',
    },
    amountScalableContainer: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        marginHorizontal: -1000,
    },
    transparentTextInput: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        color: 'transparent',
    },
    buttonContainer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        top: 0,
        justifyContent: 'flex-end',
        paddingHorizontal: rem(16),
    },
    centered: {
        alignSelf: 'center',
    },
    btnContainer: {
        flexDirection: 'row',
    },
    currencyBtn: {
        paddingHorizontal: rem(24),
    },
}));
export default AddBalanceScreen;
