import React, { useRef, useState, useCallback, useLayoutEffect, useEffect, } from 'react';
import { View } from 'react-native';
import emoji from 'node-emoji';
import Text from 'design-system/Text';
import Button from 'design-system/Button';
import Spacer from 'design-system/Spacer';
import { rem } from 'design-system/values';
import StatusBar from 'design-system/StatusBar';
import { AppView } from 'design-system/AppView';
import { ButtonGroup } from 'design-system/ButtonGroup';
import KeyboardAvoidingContainer from 'design-system/KeyboardAvoidingContainer';
import useStyles from 'hooks/useStyles';
import useColors from 'hooks/useColors';
import Alert from 'utils/packages/Alert';
import TextInput from 'utils/packages/TextInput';
import createStyleSheets from 'utils/createStyleSheets';
import { useAutoFocusInput } from 'hooks/useAutoFocusInput';
import { useRequiredBenefitCallbackWithSpaces } from 'features/premium/hooks/useRequiredBenefitCallback';
import { isWeb } from '../../../constants';
import { useTransaction } from '../hooks/useTransaction';
const getTxnName = (transaction) => {
    if (transaction) {
        return transaction.customName
            ? transaction.customName
            : transaction.counterpartName;
    }
    return '';
};
const RenameTransactionScreen = ({ navigation, route, transaction, }) => {
    const colors = useColors();
    const [name, setName] = useState(getTxnName(transaction));
    useLayoutEffect(() => {
        const nameToDisplay = transaction?.customName ||
            transaction?.counterpartName ||
            'Rename transaction';
        navigation.setOptions({
            headerTitle: 'Rename transaction',
            title: nameToDisplay,
            headerTintColor: colors.text.primary,
        });
    }, [colors.text.primary, transaction]);
    const onPressSubmit = useRequiredBenefitCallbackWithSpaces(useCallback(async () => {
        if (!name || !name.length) {
            Alert.alert(emoji.emojify('Slow down :face_with_rolling_eyes:'), 'You must enter a merchant.');
            return;
        }
        navigation.navigate('EditMultipleTransactionsRename', {
            isSelectedAll: true,
            keyBack: route.key,
            transaction: { ...transaction, customName: name },
            customName: name,
        });
    }, [name, route.key, transaction]), 'renameTransactions');
    const showOriginalName = transaction?.customName != null && name !== transaction?.counterpartName;
    const onPressRevertToOriginal = useCallback(() => {
        setName(transaction.counterpartName);
    }, [transaction]);
    const textInputRef = useRef(null);
    useAutoFocusInput(textInputRef);
    const styles = useStyles(styleSet);
    return (<AppView isInTab={isWeb} avoidHeader={isWeb}>
      <StatusBar barStyle="dark-content" animated/>
      <KeyboardAvoidingContainer>
        <View style={styles.container}>
          {isWeb ? <Spacer h={72}/> : <Spacer flex={0.9}/>}
          <TextInput value={name} ref={textInputRef} testID="nameInput" autoCorrect={false} returnKeyType="done" onChangeText={setName} allowFontScaling={false} style={styles.textInput} onSubmitEditing={onPressSubmit} focusStyle={styles.textInputFocus} underlineColorAndroid="transparent" selectionColor={colors.text.selection} placeholderTextColor={colors.text.placeholder}/>
          <Text Secondary TextThin-14 style={[styles.originalName, !showOriginalName && styles.hidden]}>
            {transaction.counterpartName}
          </Text>
          <Spacer flex/>
        </View>
        <ButtonGroup margins={false}>
          <Button transparent onPress={onPressRevertToOriginal} title="Revert to original" disabled={!showOriginalName} style={!showOriginalName && styles.hidden}/>
          <Button brand testID="saveButton" title="Save this name" onPress={onPressSubmit}/>
        </ButtonGroup>
      </KeyboardAvoidingContainer>
    </AppView>);
};
const RenameTransactionScreenWrapper = (props) => {
    const { id } = props.route.params;
    const { transaction, LoadingOr404 } = useTransaction(id);
    useEffect(() => {
        if (!transaction) {
            props.navigation.setOptions({
                title: 'Rename transaction',
                headerTitle: '',
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    if (!transaction) {
        return LoadingOr404;
    }
    return <RenameTransactionScreen {...props} transaction={transaction}/>;
};
const styleSet = createStyleSheets((colors) => ({
    container: {
        flex: 1,
        justifyContent: 'center',
    },
    textInput: {
        ...(isWeb
            ? {
                paddingVertical: rem(16),
                color: colors.text.secondary,
            }
            : {
                fontSize: rem(25),
                textAlign: 'center',
                color: colors.text.primary,
                fontFamily: 'Montserrat-Light',
            }),
    },
    textInputFocus: {
        color: colors.text.primary,
    },
    originalName: {
        textAlign: 'center',
        marginTop: rem(8),
    },
    hidden: {
        opacity: 0,
    },
    indicatorContainer: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
}));
export default RenameTransactionScreenWrapper;
