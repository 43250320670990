import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import Button from 'design-system/Button';
import EmojiIcon from 'design-system/emojiPicker/EmojiIcon';
import Logo from 'design-system/Logo';
import StatusBar from 'design-system/StatusBar';
import Text from 'design-system/Text';
import { rem } from 'design-system/values';
import { createAccount, editAccount, getAccountGoals, getTransactions } from 'actions';
import { muteErrorAndFetching, useFetchOld } from 'hooks/useFetch';
import useRefreshAccounts from 'hooks/useRefreshAccounts';
import { getTwitterData } from 'actions/twitter';
import { AppView } from 'design-system/AppView';
import { ButtonGroup } from 'design-system/ButtonGroup';
import CardRow from 'design-system/CardRow';
import { CardView } from 'design-system/CardView';
import { Spacer } from 'design-system/Spacer';
import Squircle from 'design-system/Squircle';
import { useRequiredBenefitCallbackWithSpaces } from 'features/premium/hooks/useRequiredBenefitCallback';
import useColors from 'hooks/useColors';
import useStyles from 'hooks/useStyles';
import { selectAccountById } from 'reducers/selectors';
import { useAppSelector, useAppStore } from 'store/hooks';
import { getBalanceInNativeCurrency } from 'utils/accounts';
import Amplitude from 'utils/amplitude';
import createStyleSheets from 'utils/createStyleSheets';
import { formatAmount } from 'utils/formatting';
import { goBackWithKey } from 'utils/navigationv6';
import { isWeb } from '../../../constants';
import { DUMMY_ACCOUNT_TEXT_MAP } from '../constants';
import useDummyAccountLogo from '../hooks/useDummyAccountLogo';
import useInvestmentGraphBalanceHistory from '../hooks/useInvestmentGraphBalanceHistory';
const strings = {
    confirmOfflineAccount: 'Confirm offline account',
    confirmProperty: 'Confirm property',
    goBack: 'Go back',
};
const DummyAccountCreatedScreen = ({ navigation, route }) => {
    const colors = useColors();
    const [, , , fetch] = useFetchOld({ throws: true });
    const userCurrency = useAppSelector((store) => store.user.user.currency);
    const account = useAppSelector((store) => selectAccountById(store, route.params.id));
    const name = route.params.name || account?.name;
    const type = route.params.type || account?.type;
    const balance = route.params.balance !== undefined ? route.params.balance : undefined;
    const currency = route.params.currency || account?.currency;
    const emoji = route.params.emoji || account?.emoji;
    const { iconProviderHandle, iconProvider } = route.params;
    useLayoutEffect(() => {
        navigation.setOptions({
            headerTintColor: colors.text.primary,
            title: 'Offline account',
        });
    }, [colors.text.primary]);
    const [twitterData, , , fetchTwitter] = useFetchOld(muteErrorAndFetching);
    useEffect(() => {
        if (iconProviderHandle) {
            fetchTwitter(getTwitterData(iconProviderHandle));
        }
    }, [fetchTwitter, iconProviderHandle]);
    const logoFallback = useDummyAccountLogo(type, 24);
    const [loading, setLoading] = useState(false);
    const refreshAccounts = useRefreshAccounts();
    const store = useAppStore();
    const onGoBack = useCallback(() => {
        if (route.params.id === undefined && isWeb) {
            navigation.navigate('OfflineAccounts');
            return;
        }
        if (route.params.keyBack && goBackWithKey(navigation, route.params.keyBack)) {
            return;
        }
        if (route.params.id !== undefined) {
            navigation.navigate('Transactions', { id: route.params.id });
        }
    }, [route.params.id, route.params.keyBack]);
    const loadInvestmentGraphBalanceHistory = useInvestmentGraphBalanceHistory();
    const onSubmitCallback = useCallback(async () => {
        const { id } = route.params;
        const body = {};
        if (route.params.currency)
            body.currency = currency;
        if (route.params.type)
            body.type = type;
        if (route.params.emoji)
            body.emoji = emoji;
        if (route.params.iconProvider) {
            body.iconProvider = iconProvider;
            body.iconProviderHandle = iconProviderHandle;
        }
        if (balance !== undefined)
            body.balance = balance;
        if (id) {
            if (route.params.name) {
                body.customName = name;
            }
            try {
                setLoading(true);
                await fetch(editAccount(id, body));
                setLoading(false);
                if (body.balance !== undefined && store.getState().accounts.goals[Number(id)]) {
                    fetch(getAccountGoals());
                }
                if (type) {
                    refreshAccounts(type);
                    if (type === 'INVESTMENT') {
                        loadInvestmentGraphBalanceHistory(id);
                    }
                }
                fetch(getTransactions(Number(id), null, null, undefined, undefined, null, 1, null, null, false));
                Amplitude.logEvent('Premium.DummyAccountUpdated');
                onGoBack();
            }
            catch {
                // error will be shown by fetch
                setLoading(false);
            }
        }
        else {
            if (route.params.name) {
                body.name = name;
            }
            try {
                setLoading(true);
                await fetch(createAccount(body));
                setLoading(false);
                if (type) {
                    refreshAccounts(type);
                }
                Amplitude.logEvent('Premium.DummyAccountCreated');
                onGoBack();
            }
            catch {
                // error will be shown by fetch
                setLoading(false);
            }
        }
    }, [
        route.params,
        currency,
        type,
        emoji,
        balance,
        iconProvider,
        iconProviderHandle,
        name,
        onGoBack,
        refreshAccounts,
        loadInvestmentGraphBalanceHistory,
    ]);
    const onSubmit = useRequiredBenefitCallbackWithSpaces(onSubmitCallback, 'offlineAccounts');
    const [title, buttonText, displayBalance] = useMemo(() => {
        let buttonText = strings.confirmOfflineAccount;
        if (type === 'REAL_ESTATE' || type === 'VEHICLE' || type === 'OTHER') {
            buttonText = strings.confirmProperty;
        }
        return [
            type !== 'CRYPTO' ? DUMMY_ACCOUNT_TEXT_MAP[type] || '' : '',
            buttonText,
            getBalanceInNativeCurrency(account, userCurrency, balance, currency),
        ];
    }, [type, account, userCurrency, balance, currency]);
    const logo = useMemo(() => {
        if (iconProvider === 'TWITTER') {
            return <Logo R44 uri={twitterData?.iconUrl}/>;
        }
        if (emoji) {
            return <EmojiIcon>{emoji}</EmojiIcon>;
        }
        if (route.params.id !== undefined && account?.iconUrl) {
            return <Logo R44 uri={account.iconUrl}/>;
        }
        return logoFallback;
    }, [iconProvider, route.params.id, account?.iconUrl, emoji, logoFallback, twitterData?.iconUrl]);
    const styles = useStyles(styleSet);
    return (<AppView type="secondary" paddingBottom isInTab={isWeb} avoidHeader>
      <StatusBar barStyle="dark-content" animated/>
      <View style={styles.container}>
        <Spacer h={8}/>
        <CardView withPadding={false}>
          <View style={styles.cardHeader}>
            <Text Text-20>{name}</Text>
            <Squircle height={44} width={44} color={colors.cards.onLight}>
              {logo}
            </Squircle>
          </View>
          <CardRow style={styles.row} small title="Account type" info={title}/>
          <CardRow style={styles.row} small title="Balance" info={formatAmount(Number(displayBalance.amount), displayBalance.currency)}/>
        </CardView>
      </View>
      <ButtonGroup>
        <Button brandReversed title={strings.goBack} onPress={onGoBack}/>
        <Button brand loading={loading} title={buttonText} onPress={onSubmit}/>
      </ButtonGroup>
    </AppView>);
};
const styleSet = createStyleSheets(() => ({
    container: {
        flex: 1,
    },
    cardHeader: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: rem(16),
    },
    row: {
        flex: undefined,
    },
    centered: {
        alignSelf: 'center',
    },
}));
export default DummyAccountCreatedScreen;
