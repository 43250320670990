import { CALL_API } from '../middleware/api';
import * as types from './types';
export const postAdditionalUserInfo = (body, muteAlert = false) => ({
    [CALL_API]: {
        endpoint: '/user-additional-info',
        method: 'PUT',
        muteAlert,
        body: JSON.stringify(body),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.POST_ADDITIONAL_USER_INFO_REQUEST,
            types.POST_ADDITIONAL_USER_INFO_SUCCESS,
            types.POST_ADDITIONAL_USER_INFO_FAILURE,
        ],
    },
});
export const getAdditionalUserInfo = () => ({
    [CALL_API]: {
        endpoint: '/user-additional-info',
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.GET_ADDITIONAL_USER_INFO_REQUEST,
            types.GET_ADDITIONAL_USER_INFO_SUCCESS,
            types.GET_ADDITIONAL_USER_INFO_FAILURE,
        ],
    },
});
/**
 * @returns {{"[CALL_API]": {headers: {Accept: string, Content-Type: string}, endpoint: string, types: (string)[], method: string}}}
 */
export const getExportData = (body) => ({
    [CALL_API]: {
        endpoint: body.from && body.to
            ? `/data-export?from=${body.from}&to=${body.to}`
            : '/data-export',
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.GET_EXPORT_DATA_REQUEST,
            types.GET_EXPORT_DATA_SUCCESS,
            types.GET_EXPORT_DATA_FAILURE,
        ],
    },
});
export const refreshOnStart = () => ({
    type: types.REFRESH_ON_START,
});
export const setLastSession = (time, state) => ({
    type: types.SET_LAST_SESSION,
    payload: { time, state },
});
export const sessionCount = () => ({
    type: types.SESSION_COUNT,
});
export const registerUser = (payload) => ({
    [CALL_API]: {
        endpoint: '/me',
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        extra: payload,
        types: [
            types.REGISTER_USER_REQUEST,
            types.REGISTER_USER_SUCCESS,
            types.REGISTER_USER_FAILURE,
        ],
    },
});
export const postUserNationalities = (countries) => ({
    [CALL_API]: {
        endpoint: '/me/nationalities',
        method: 'POST',
        body: JSON.stringify({ countries }),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.POST_USER_NATIONALITIES_REQUEST,
            types.POST_USER_NATIONALITIES_SUCCESS,
            types.POST_USER_NATIONALITIES_FAILURE,
        ],
        extra: {
            countries,
        },
    },
});
export const postProfilePicture = (
// eslint-disable-next-line no-undef
body) => ({
    [CALL_API]: {
        endpoint: '/profile-image',
        method: 'POST',
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        body,
        types: [
            types.POST_PROFILE_PIC_REQUEST,
            types.POST_PROFILE_PIC_SUCCESS,
            types.POST_PROFILE_PIC_FAILURE,
        ],
    },
});
export const deleteProfilePicture = () => ({
    [CALL_API]: {
        endpoint: '/profile-image',
        method: 'DELETE',
        types: [
            types.POST_PROFILE_PIC_REQUEST,
            types.POST_PROFILE_PIC_SUCCESS,
            types.POST_PROFILE_PIC_FAILURE,
        ],
    },
});
export const deactivateUser = () => ({
    [CALL_API]: {
        endpoint: '/me/deactivate',
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.DEACTIVATE_USER_REQUEST,
            types.DEACTIVATE_USER_SUCCESS,
            types.DEACTIVATE_USER_FAILURE,
        ],
        muteAlert: true,
    },
});
export const getUser = (muteAlertForToken) => ({
    [CALL_API]: {
        endpoint: '/me',
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
        types: [types.USER_REQUEST, types.USER_SUCCESS, types.USER_FAILURE],
        muteAlertForToken,
    },
});
export const updatePreferences = (preferences) => ({
    [CALL_API]: {
        endpoint: '/user-preferences',
        method: 'POST',
        body: JSON.stringify(preferences),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.UPDATE_PREFERENCES_REQUEST,
            types.UPDATE_PREFERENCES_SUCCESS,
            types.UPDATE_PREFERENCES_FAILURE,
        ],
    },
});
export const updateBalancePreference = (enabledAccountIds, disabledAccountIds) => ({
    [CALL_API]: {
        endpoint: '/user-preferences/info',
        method: 'POST',
        body: JSON.stringify({
            'alerts.daily_balance.push': {
                enabledAccountIds,
                disabledAccountIds,
            },
        }),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.UPDATE_PREFERENCES_REQUEST,
            types.UPDATE_PREFERENCES_SUCCESS,
            types.UPDATE_PREFERENCES_FAILURE,
        ],
    },
});
export const getPreferences = () => ({
    [CALL_API]: {
        endpoint: '/user-preferences',
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        types: [
            types.PREFERENCES_REQUEST,
            types.PREFERENCES_SUCCESS,
            types.PREFERENCES_FAILURE,
        ],
    },
});
export const linkFacebook = (data) => ({
    [CALL_API]: {
        endpoint: '/oauth/link-provider/facebook',
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify('userId' in data
            ? {
                token: data.accessToken,
                user_id: data.userId,
            }
            : data),
        types: [
            types.LINK_FACEBOOK_REQUEST,
            types.LINK_FACEBOOK_SUCCESS,
            types.LINK_FACEBOOK_FAILURE,
        ],
    },
});
export const linkGoogle = (token) => ({
    [CALL_API]: {
        endpoint: '/oauth/link-provider/google',
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            token,
        }),
        types: [
            types.LINK_GOOGLE_REQUEST,
            types.LINK_GOOGLE_SUCCESS,
            types.LINK_GOOGLE_FAILURE,
        ],
    },
});
export const linkApple = (code, applicationType, firstName, lastName) => ({
    [CALL_API]: {
        endpoint: '/oauth/link-provider/apple',
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            code,
            application_type: applicationType,
            first_name: firstName,
            last_name: lastName,
        }),
        types: [
            types.LINK_APPLE_REQUEST,
            types.LINK_APPLE_SUCCESS,
            types.LINK_APPLE_FAILURE,
        ],
    },
});
export const requestEmailVerification = (newEmail) => ({
    [CALL_API]: {
        endpoint: '/me/email/request-verification',
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            newEmail,
        }),
        extra: {
            newEmail,
        },
        types: [
            types.REQUEST_EMAIL_VERIFICATION_REQUEST,
            types.REQUEST_EMAIL_VERIFICATION_SUCCESS,
            types.REQUEST_EMAIL_VERIFICATION_FAILURE,
        ],
    },
});
export const submitEmailVerificationCode = (newEmail, changeEmailOtp) => ({
    [CALL_API]: {
        endpoint: '/me/email/submit-verification-code',
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            newEmail,
            changeEmailOtp,
        }),
        extra: {
            newEmail,
        },
        types: [
            types.SUBMIT_EMAIL_VERIFICATION_CODE_REQUEST,
            types.SUBMIT_EMAIL_VERIFICATION_CODE_SUCCESS,
            types.SUBMIT_EMAIL_VERIFICATION_CODE_FAILURE,
        ],
    },
});
export const requestLogin = () => ({
    type: types.LOGIN_REQUEST,
});
export const requestLoginCompleted = () => ({
    type: types.LOGIN_REQUEST_COMPLETED,
});
export const receiveLogin = () => ({
    type: types.LOGIN_SUCCESS,
});
export const setPasscodeInKeychainSuccess = (pinLastChangedAt) => ({
    type: types.SET_PASSCODE_IN_KEYCHAIN_SUCCESS,
    payload: {
        pinLastChangedAt,
    },
});
export const setPasscode = (newPinCode, oldPinCode, turnPasscodeOff) => ({
    [CALL_API]: {
        endpoint: `/me/pin`,
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ newPinCode, oldPinCode }),
        types: [
            types.SET_PASSCODE_REQUEST,
            types.SET_PASSCODE_SUCCESS,
            types.SET_PASSCODE_FAILURE,
        ],
        extra: {
            turnPasscodeOff,
        },
    },
});
export const verifyPasscode = (pinCode) => ({
    [CALL_API]: {
        endpoint: `/me/pin/validate`,
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ pinCode }),
        types: [
            types.VERIFY_PASSCODE_REQUEST,
            types.VERIFY_PASSCODE_SUCCESS,
            types.VERIFY_PASSCODE_FAILURE,
        ],
        muteAlert: true,
    },
});
export const resetPasscode = (sendEmailOtp, emailOtp) => ({
    [CALL_API]: {
        endpoint: `/me/pin/reset`,
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: sendEmailOtp !== undefined || emailOtp !== undefined
            ? JSON.stringify({ sendEmailOtp, emailOtp })
            : undefined,
        types: [
            types.RESET_PASSCODE_REQUEST,
            types.RESET_PASSCODE_SUCCESS,
            types.RESET_PASSCODE_FAILURE,
        ],
    },
});
export const passCodeSetupFailed = () => ({
    type: types.PASSCODE_SETUP_FAILED,
});
export const setSetupPasscode = () => ({
    type: types.SET_SETUP_PASSCODE,
});
export const passcodeOff = () => ({
    type: types.PASSCODE_OFF,
});
export const passCodeOn = () => ({
    type: types.PASSCODE_ON,
});
export const setTouchId = (isTouchActive) => ({
    type: types.SET_TOUCH_ID,
    payload: isTouchActive,
});
export const logout = () => ({
    type: types.LOG_OUT,
});
export const loggedOut = () => ({
    type: types.LOGGED_OUT,
});
export const getCategories = () => ({
    [CALL_API]: {
        endpoint: '/categories',
        method: 'GET',
        addSpaceIdHeader: true,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.GET_CATEGORIES_REQUEST,
            types.GET_CATEGORIES_SUCCESS,
            types.GET_CATEGORIES_FAILURE,
        ],
    },
});
export const setUserHandle = (handle) => ({
    [CALL_API]: {
        endpoint: `/user-handle`,
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ handle }),
        types: [
            types.SET_USER_HANDLE_REQUEST,
            types.SET_USER_HANDLE_SUCCESS,
            types.SET_USER_HANDLE_FAILURE,
        ],
    },
});
export const linkNumberRequestCode = (phone_number, country_code, via) => ({
    [CALL_API]: {
        endpoint: `/oauth/link-provider/phone/request_code`,
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ phone_number, country_code, via }),
        types: [
            types.LINK_NUMBER_REQUEST_CODE_REQUEST,
            types.LINK_NUMBER_REQUEST_CODE_SUCCESS,
            types.LINK_NUMBER_REQUEST_CODE_FAILURE,
        ],
    },
});
export const linkNumberVerifyCode = (phone_number, country_code, verification_code) => ({
    [CALL_API]: {
        endpoint: `/oauth/link-provider/phone/verify_code`,
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ phone_number, country_code, verification_code }),
        types: [
            types.LINK_NUMBER_VERIFY_CODE_REQUEST,
            types.LINK_NUMBER_VERIFY_CODE_SUCCESS,
            types.LINK_NUMBER_VERIFY_CODE_FAILURE,
        ],
        muteAlert: true,
    },
});
export const linkNumberComplete = (phoneNumber) => ({
    type: types.LINK_NUMBER_COMPLETE,
    payload: {
        phoneNumber,
    },
});
