import { format, parseISO, subYears, isBefore } from 'date-fns';
import { useState } from 'react';
import { toZonedTime } from 'date-fns-tz';
import { useDateTimePicker } from './useDateTimePicker';
const defaultDobDate = parseISO('2000-01-01');
const getMaximumDobDate = (minAge) => subYears(new Date(), minAge);
export const DOB_DISPLAY_FORMAT = 'dd/MM/yyyy';
/**
 * returns dob in DD/MM/YYYY string format
 */
const useDateTimePickerDOB = (user, minAge = 18) => {
    const [dob, setDob] = useState(() => user?.dateOfBirth
        ? format(toZonedTime(user.dateOfBirth, 'UTC'), 'yyyy-MM-dd')
        : '');
    const maxDobObj = getMaximumDobDate(minAge);
    const [maximumDob] = useState(maxDobObj);
    const dobDate = dob ? new Date(dob) : undefined;
    // eslint-disable-next-line no-nested-ternary
    const initialValueObj = dobDate
        ? isBefore(dobDate, maxDobObj)
            ? dobDate
            : defaultDobDate
        : defaultDobDate;
    const { showDateTimePicker } = useDateTimePicker({
        initialValue: initialValueObj,
        maximumDate: maximumDob,
        onSubmitDate: (date) => {
            setDob(format(date, 'yyyy-MM-dd'));
        },
    });
    /**
     * any date sent as UTC 00:00:00 should be converted to UTC to display correctly in different timezones
     * moment.utc(dateOfBirth).format('DD/MM/YYYY')
     * ore remove TZ info first and format with moment
     */
    return { showDateTimePicker, dob };
};
export default useDateTimePickerDOB;
