import React, { memo, useCallback, useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { CardView, rem, Spacer, TextButtonLink } from 'design-system';
import Section from 'design-system/Section';
import { isNotLoadedState } from 'reducers/types';
import IconRotate from 'design-system/icons/IconRotate';
import AlertView, { AlertButton } from 'design-system/AlertView';
import { useAppSelector } from 'store/hooks';
import { useMainStackNavigation } from 'utils/types/navigationV6';
import { selectBudgetedCategories, selectCategoriesLoadingState, selectUnbudgetedCategoriesForCurrentPeriod, } from '../selectors';
import UnbudgetedCategoryRow from './UnbudgetedCategoryRow';
import BudgetingCategoryRow from './BudgetingCategoryRow';
const BudgetingCategoryList = ({ isCurrentPeriod, onPressManageCategories, onPressCategory, }) => {
    const navigation = useMainStackNavigation();
    const categories = useAppSelector(selectBudgetedCategories);
    const unbudgetedCategories = useAppSelector(selectUnbudgetedCategoriesForCurrentPeriod);
    const loadingState = useAppSelector(selectCategoriesLoadingState);
    const totalSpending = useMemo(() => unbudgetedCategories.reduce((prev, category) => prev + category.total, 0), [unbudgetedCategories]);
    const handlePress = useCallback(() => {
        navigation.navigate('UnbudgetedSpend');
    }, []);
    if (!categories.length) {
        if (isNotLoadedState(loadingState)) {
            return null;
        }
        const setup = () => onPressManageCategories(true);
        return (<AlertView containerStyles={styles.card} title="No category budgets" icon={<IconRotate />} type="info" subTitle={isCurrentPeriod
                ? 'Set up category spend limits.'
                : 'There were no category budgets for this period.'}>
        {isCurrentPeriod && <AlertButton onPress={setup}>Set up</AlertButton>}
      </AlertView>);
    }
    const handleEditPress = () => {
        navigation.navigate('Budgets', {});
    };
    return (<>
      <Spacer.H8 />
      <Section style={styles.section} title="Category budgets" button={categories.length > 0 && isCurrentPeriod ? (<TextButtonLink text="Edit" onPress={handleEditPress} testID="editCategoryBudgets"/>) : null}>
        <CardView noMarginTop noMarginBottom webPadding>
          {categories.map((item) => {
            const onPress = () => onPressCategory({ data: item, type: 'category' });
            return (<BudgetingCategoryRow key={item.id} category={item} onPress={onPress}/>);
        })}

          {unbudgetedCategories.length > 0 && (<UnbudgetedCategoryRow total={totalSpending} currency={unbudgetedCategories[0].currency} onPress={handlePress}/>)}
        </CardView>
      </Section>
    </>);
};
const styles = StyleSheet.create({
    card: {
        marginTop: rem(0),
        marginBottom: rem(24),
    },
    section: {
        marginBottom: rem(24),
    },
});
export default memo(BudgetingCategoryList);
