import { REHYDRATE } from 'redux-persist';
import * as types from 'actions/types';
import { getTransactionsIdObj } from 'utils/getTransactions';
import * as localTypes from '../actions/types';
export const initialState = {
    committed: undefined,
    subscriptionsSearch: [],
    isSearchingSubscription: false,
    isFetchingSubscriptions: false,
    subscriptions: { data: [], downloaded: false },
    activeSubscriptionId: undefined,
    subscriptionTransactions: [],
    isFetchingSubscriptionsTransactions: false,
};
const subscriptions = (state = initialState, action) => {
    switch (action.type) {
        case REHYDRATE:
            const { subscriptions } = action.payload || {};
            if (subscriptions)
                return {
                    ...initialState,
                    committed: subscriptions.committed || initialState.committed,
                };
            return state;
        case types.LOGGED_OUT: {
            return initialState;
        }
        case localTypes.COMMITTED_SUCCESS:
            return { ...state, committed: action.payload };
        case types.RENAME_TRANSACTION_SUCCESS: {
            const { data } = action.extra;
            const idObj = getTransactionsIdObj(data);
            return {
                ...state,
                subscriptionTransactions: state.subscriptionTransactions.map((item) => {
                    if (idObj[item.id]) {
                        return { ...item, customName: action.extra.counterPartName };
                    }
                    return item;
                }),
            };
        }
        case localTypes.DELETE_TXN_FROM_SUBSCRIPTION_SUCCESS: {
            return {
                ...state,
                subscriptionTransactions: state.subscriptionTransactions.filter((txn) => !action.extra.ids.includes(txn.id)),
            };
        }
        case types.SET_TAGS_SUCCESS:
        case types.SAVE_NOTE_SUCCESS:
            let subscriptionTransactions = [...state.subscriptionTransactions];
            if (subscriptionTransactions.length) {
                subscriptionTransactions = subscriptionTransactions.map((transaction) => {
                    if (transaction.id === action.payload.id) {
                        return { ...transaction, ...action.payload };
                    }
                    return transaction;
                });
            }
            return {
                ...state,
                subscriptionTransactions,
            };
        case localTypes.EDIT_SUBSCRIPTIONS_SUCCESS: {
            const isDataOfFocusedSubscription = action.extra.subscriptionId === state.activeSubscriptionId;
            return {
                ...state,
                committed: {
                    ...state.committed,
                    subscriptions: state.committed?.subscriptions?.map((subscription) => {
                        if (subscription.id === action.payload.subscription.id) {
                            return {
                                ...subscription,
                                ...action.payload.subscription,
                            };
                        }
                        return subscription;
                    }) || [],
                },
                subscriptions: {
                    data: state.subscriptions.data.map((subscription) => {
                        if (subscription.id === action.payload.subscription.id) {
                            return {
                                ...action.payload.subscription,
                                predictions: action.payload.subscription.predictions?.length
                                    ? action.payload.subscription.predictions
                                    : subscription.predictions,
                            };
                        }
                        return subscription;
                    }),
                    downloaded: true,
                },
                subscriptionTransactions: isDataOfFocusedSubscription
                    ? action.payload.subscription.transactions
                    : state.subscriptionTransactions,
            };
        }
        case localTypes.ADD_SUBSCRIPTIONS_SUCCESS:
            if (!state.subscriptions.data.length)
                return state;
            return {
                ...state,
                subscriptions: {
                    data: [...state.subscriptions.data, action.payload.subscription],
                    downloaded: true,
                },
            };
        case localTypes.CLEAN_SUBSCRIPTIONS:
            return { ...state, subscriptionsSearch: [] };
        case localTypes.SEARCH_SUBSCRIPTION_REQUEST:
            return { ...state, isSearchingSubscription: true };
        case localTypes.SEARCH_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                subscriptionsSearch: action.payload.transactions,
                isSearchingSubscription: false,
            };
        case localTypes.CANCEL_SUBSCRIPTIONS_SUCCESS:
            return {
                ...state,
                subscriptions: {
                    ...state.subscriptions,
                    data: state.subscriptions.data.filter(({ id }) => id !== action.payload.subscriptionId),
                },
                committed: {
                    ...state.committed,
                    subscriptions: state.committed?.subscriptions
                        ? state.committed.subscriptions.filter(({ id }) => id !== action.payload.subscriptionId)
                        : [],
                },
            };
        case localTypes.SUBSCRIPTIONS_REQUEST:
            return { ...state, isFetchingSubscriptions: true };
        case localTypes.SUBSCRIPTIONS_SUCCESS:
            return {
                ...state,
                subscriptions: {
                    data: action.payload.subscriptions,
                    thisMonth: action.payload.thisMonth,
                    thisPayPeriod: action.payload.thisPayPeriod,
                    downloaded: true,
                },
                isFetchingSubscriptions: false,
            };
        case localTypes.SUBSCRIPTIONS_ID_REQUEST: {
            const isDataOfFocusedSubscription = action.extra.subscriptionId === state.activeSubscriptionId;
            return {
                ...state,
                isFetchingSubscriptionsTransactions: true,
                activeSubscriptionId: action.extra.subscriptionId,
                subscriptionTransactions: isDataOfFocusedSubscription
                    ? state.subscriptionTransactions
                    : [],
            };
        }
        case localTypes.SUBSCRIPTIONS_ID_SUCCESS:
            const isDataOfFocusedSubscription = action.extra.subscriptionId === state.activeSubscriptionId;
            let wasPatched = false;
            const patchedArray = state.subscriptions.data.map((subscription) => {
                if (subscription.id === action.payload.id) {
                    wasPatched = true;
                    return action.payload;
                }
                return subscription;
            });
            return {
                ...state,
                subscriptionTransactions: isDataOfFocusedSubscription
                    ? action.payload.transactions
                    : state.subscriptionTransactions,
                isFetchingSubscriptionsTransactions: isDataOfFocusedSubscription
                    ? false
                    : state.isFetchingSubscriptionsTransactions,
                subscriptions: {
                    ...state.subscriptions,
                    data: wasPatched
                        ? patchedArray
                        : // We don't need to worry about sorting because we don't set download to true
                            // So they'll get loaded fresh if the user opens the subscriptions list screen
                            [...state.subscriptions.data, action.payload],
                },
            };
        case localTypes.RESET_SUBSCRIPTION_TRANSACTION:
            return {
                ...state,
                isFetchingSubscriptionsTransactions: true,
                subscriptionTransactions: [],
            };
        case types.EDIT_CATEGORY_SUCCESS:
            const { data } = action.extra;
            subscriptionTransactions = [...state.subscriptionTransactions];
            if (subscriptionTransactions.length && action.extra) {
                subscriptionTransactions = state.subscriptionTransactions.map((transaction) => {
                    if (data[transaction.id]) {
                        return { ...transaction, category: action.extra.category };
                    }
                    return transaction;
                });
            }
            return { ...state, subscriptionTransactions };
        case localTypes.ADD_TXNS_TO_SUBSCRIPTION_SUCCESS: {
            return {
                ...state,
                subscriptionTransactions: action.payload
                    .transactions,
            };
        }
        default:
            return state;
    }
};
export default subscriptions;
