export var CompleteSetupStepId;
(function (CompleteSetupStepId) {
    CompleteSetupStepId["DEFAULT"] = "DEFAULT";
    CompleteSetupStepId["ACTIVATE_TRIAL"] = "ACTIVATE_TRIAL";
    CompleteSetupStepId["SECOND_BANK_ACCOUNT"] = "SECOND_BANK_ACCOUNT";
    CompleteSetupStepId["SET_UP_BUDGETING"] = "SET_UP_BUDGETING";
    CompleteSetupStepId["START_INVESTING"] = "START_INVESTING";
    CompleteSetupStepId["START_SAVING"] = "START_SAVING";
    CompleteSetupStepId["RENT_REPORTING"] = "RENT_REPORTING";
    CompleteSetupStepId["FRAUD_DETECTION"] = "FRAUD_DETECTION";
})(CompleteSetupStepId || (CompleteSetupStepId = {}));
