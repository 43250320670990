import { createSelector } from 'reselect';
import { Tier } from 'features/premium/entities';
import { isUnlocked } from 'features/premium/iap';
import { hasCompletedSetupSelector } from 'features/feed/components/completeSetup/selectors';
export const defaultFeedPriorityArr = [
    'transactions',
    'analytics',
    'subscriptions',
    'fraudDetection',
    'completeSetup',
];
export const selectFeedPriority = createSelector([
    (state) => isUnlocked(state.user.user, Tier.pro),
    (state) => state.deviceSettings.feedPersonalization,
    (state) => state.feed.walkthrough?.status,
    hasCompletedSetupSelector,
], (canOrganizeFeed, feedPersonalization, walkthroughStatus, hasCompletedSetup) => {
    if (canOrganizeFeed &&
        feedPersonalization &&
        walkthroughStatus !== 'in-progress') {
        return organizeFeed(feedPersonalization, hasCompletedSetup);
    }
    return {
        priority: hasCompletedSetup
            ? defaultFeedPriorityArr.filter((item) => item !== 'completeSetup')
            : defaultFeedPriorityArr,
        removed: [],
    };
});
export const organizeFeed = (feedPersonalization, hasCompletedSetup) => {
    const finalArr = [];
    const removedArr = [];
    defaultFeedPriorityArr.forEach((content) => {
        if (content === 'completeSetup' && hasCompletedSetup) {
            return;
        }
        if (feedPersonalization[content] !== null) {
            finalArr.push(content);
        }
        else {
            removedArr.push(content);
        }
    });
    finalArr.sort((a, b) => {
        const orderOfA = feedPersonalization[a];
        const orderOfB = feedPersonalization[b];
        if (orderOfA === null || orderOfA === undefined) {
            return 1;
        }
        if (orderOfB === null || orderOfB === undefined) {
            return -1;
        }
        return orderOfA - orderOfB;
    });
    removedArr.sort((a, b) => {
        const orderOfA = feedPersonalization[a];
        const orderOfB = feedPersonalization[b];
        if (orderOfA === null || orderOfA === undefined) {
            return 1;
        }
        if (orderOfB === null || orderOfB === undefined) {
            return -1;
        }
        return orderOfA - orderOfB;
    });
    return {
        priority: finalArr,
        removed: removedArr,
    };
};
