import React from 'react';
import { StyleSheet, View } from 'react-native';
import Amount from 'design-system/Amount';
import Text from 'design-system/Text';
import { formatAmount } from 'utils/formatting';
import BudgetingCircleChart from 'features/feed/components/analytics/BudgetingCircleChart';
import { interpolate } from 'utils/interpolations';
import { rem } from 'design-system/values';
export const BudgetingCircleProgressBar = ({ onLight, committed, currency, spending, totalBudget, hasCircle, scrambled, gradientColors, }) => {
    const interpolateConfig = {
        inputRange: [0, totalBudget || 100],
        outputRange: [0, 100],
    };
    const budgetingCommitted = hasCircle && committed
        ? interpolate(committed + spending, interpolateConfig)
        : undefined;
    const positive = spending > 0;
    const leftOfBudget = positive
        ? totalBudget
        : totalBudget - Math.abs(spending);
    const budgetingSpent = interpolate(positive ? 0 : Math.abs(spending), interpolateConfig);
    return (<View style={styles.graphContainer}>
        <View style={styles.graphText}>
          <Amount Numbers-24 value={{
            value: Math.abs(leftOfBudget),
            currency,
        }} showFraction centered adjustsFontSizeToFit numberOfLines={1}/>
          <Text TextThin-14 centered Gray adjustsFontSizeToFit>
            {leftOfBudget >= 0 ? 'left of' : 'over your budget\nof'}{' '}
            {formatAmount(totalBudget, currency, 2, scrambled)}
          </Text>
        </View>
        <BudgetingCircleChart large onLight={onLight} gradientColors={gradientColors} committedPercent={budgetingCommitted} spendingPercent={budgetingSpent}/>
      </View>);
};
const styles = StyleSheet.create({
    graphContainer: {
        justifyContent: 'center',
        alignSelf: 'center',
    },
    graphText: {
        position: 'absolute',
        alignSelf: 'center',
        justifyContent: 'center',
        maxWidth: rem(140),
    },
});
