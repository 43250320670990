import { CALL_API } from 'middleware/api';
import { DELETE_RENT_REPORTING_BY_ID_FAILURE, DELETE_RENT_REPORTING_BY_ID_REQUEST, DELETE_RENT_REPORTING_BY_ID_SUCCESS, DIMISSED_UPGRADE_FOR_MORE_RENT_REPORTING_AGENCIES, GET_RENT_REPORTING_BY_ID_FAILURE, GET_RENT_REPORTING_BY_ID_REQUEST, GET_RENT_REPORTING_BY_ID_SUCCESS, GET_RENT_REPORTING_FAILURE, GET_RENT_REPORTING_REQUEST, GET_RENT_REPORTING_SUCCESS, GET_RENT_REPORTING_SUGGESTED_PAYMENTS_FAILURE, GET_RENT_REPORTING_SUGGESTED_PAYMENTS_REQUEST, GET_RENT_REPORTING_SUGGESTED_PAYMENTS_SUCCESS, PATCH_RENT_REPORTING_BY_ID_FAILURE, PATCH_RENT_REPORTING_BY_ID_REQUEST, PATCH_RENT_REPORTING_BY_ID_SUCCESS, SET_UP_RENT_REPORTING_FAILURE, SET_UP_RENT_REPORTING_REQUEST, SET_UP_RENT_REPORTING_SUCCESS, } from './types';
const BASE_URL = '/rent-reporting';
export const getRentReporting = ({ withRentTransactions, isActive } = {
    withRentTransactions: false,
    isActive: true,
}) => ({
    [CALL_API]: {
        endpoint: `${BASE_URL}?withTenancyAddress=true&withReportingAgencies=true&withTrackingStatus=true&withRentTransactions=${withRentTransactions}${isActive ? '&isActive=true' : ''}`,
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        extra: {
            isActive,
            withRentTransactions,
        },
        types: [
            GET_RENT_REPORTING_REQUEST,
            GET_RENT_REPORTING_SUCCESS,
            GET_RENT_REPORTING_FAILURE,
        ],
    },
});
export const getRentReportingById = (id) => ({
    [CALL_API]: {
        endpoint: `${BASE_URL}/${id}`,
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        extra: { id },
        types: [
            GET_RENT_REPORTING_BY_ID_REQUEST,
            GET_RENT_REPORTING_BY_ID_SUCCESS,
            GET_RENT_REPORTING_BY_ID_FAILURE,
        ],
    },
});
export const deleteRentReportingById = (id) => ({
    [CALL_API]: {
        endpoint: `${BASE_URL}/${id}`,
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            DELETE_RENT_REPORTING_BY_ID_REQUEST,
            DELETE_RENT_REPORTING_BY_ID_SUCCESS,
            DELETE_RENT_REPORTING_BY_ID_FAILURE,
        ],
    },
});
export const editRentReportingById = (id, body) => ({
    [CALL_API]: {
        endpoint: `${BASE_URL}/${id}`,
        method: 'PATCH',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
        types: [
            PATCH_RENT_REPORTING_BY_ID_REQUEST,
            PATCH_RENT_REPORTING_BY_ID_SUCCESS,
            PATCH_RENT_REPORTING_BY_ID_FAILURE,
        ],
    },
});
export const setUpRentReporting = (body) => ({
    [CALL_API]: {
        endpoint: BASE_URL,
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
        types: [
            SET_UP_RENT_REPORTING_REQUEST,
            SET_UP_RENT_REPORTING_SUCCESS,
            SET_UP_RENT_REPORTING_FAILURE,
        ],
    },
});
export const getSuggestedRentPayments = ({ amount, accountId, page, }) => ({
    [CALL_API]: {
        endpoint: `/transactions/suggest-rent-payments?amount=${amount}&accountId=${accountId}&perPage=15&page=${page}`,
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            GET_RENT_REPORTING_SUGGESTED_PAYMENTS_REQUEST,
            GET_RENT_REPORTING_SUGGESTED_PAYMENTS_SUCCESS,
            GET_RENT_REPORTING_SUGGESTED_PAYMENTS_FAILURE,
        ],
        extra: { page },
    },
});
export const dismissedUpgradeForMoreRentReportingAgencies = () => ({
    type: DIMISSED_UPGRADE_FOR_MORE_RENT_REPORTING_AGENCIES,
});
