import React, { useLayoutEffect } from 'react';
import Button from 'design-system/Button';
import Squircle from 'design-system/Squircle';
import { IconBox } from 'design-system/icons/IconBox';
import { ButtonGroup } from 'design-system/ButtonGroup';
import { RequestActionView } from 'design-system/RequestActionView';
import EmptyComponent from 'design-system/EmptyComponent';
import { isWeb } from '../../../constants';
const HolidaySelectionScreen = ({ navigation, route, }) => {
    const { period } = route.params;
    const { onSavePeriod } = route.params;
    useLayoutEffect(() => {
        if (isWeb) {
            navigation.setOptions({
                title: 'Holiday',
                headerTitle: EmptyComponent,
            });
        }
    }, []);
    const savePeriod = (onHoliday) => {
        const resultPeriod = {
            ...period,
            onHoliday,
        };
        onSavePeriod(resultPeriod);
    };
    return (<RequestActionView primary renderIcon={() => (<Squircle>
          <IconBox colorKey="brand"/>
        </Squircle>)} subTitle="If the date doesn't fall on a working day, when do you want the period to reset?">
      <ButtonGroup type="floating">
        <Button title="After" brandBorder onPress={() => {
            savePeriod('AFTER');
        }}/>
        <Button title="Before" brandReversed onPress={() => {
            savePeriod('BEFORE');
        }}/>
        <Button title="No Difference" brand onPress={() => {
            savePeriod('SAME');
        }}/>
      </ButtonGroup>
    </RequestActionView>);
};
export default HolidaySelectionScreen;
