import React, { useCallback, useEffect, useLayoutEffect, useRef } from 'react';
import { InteractionManager, } from 'react-native';
import { interpolateColor, useAnimatedStyle, useSharedValue, } from 'react-native-reanimated';
import SearchBar from 'design-system/SearchBar';
import useColors from 'hooks/useColors';
import { getSearchTransactions } from 'actions';
import { AppView } from 'design-system/AppView';
import { NavigationHeader } from 'design-system/NavigationHeader';
import { useAppDispatch, useAppStore } from 'store/hooks';
import { isWeb } from '../../../constants';
import MerchantsList from '../components/MerchantsList';
import { useOnSearch } from '../hooks/useOnSearch';
const strings = {
    search: 'Search...',
};
const SelectMerchantScreen = ({ navigation, }) => {
    const colors = useColors();
    useLayoutEffect(() => {
        navigation.setOptions({
            title: 'Select Merchant',
            headerTintColor: colors.text.primary,
        });
    }, [colors.text.primary]);
    const { filteredMerchants, handleSearch, handleReset } = useOnSearch();
    const dispatch = useAppDispatch();
    const store = useAppStore();
    useEffect(() => {
        InteractionManager.runAfterInteractions(() => {
            const { merchants } = store.getState().transactions;
            if (!merchants || !Object.keys(merchants).length) {
                dispatch(getSearchTransactions());
            }
        });
    }, []);
    const scrollY = useSharedValue(0);
    const refSearchInput = useRef(null);
    const onScroll = useCallback((e) => {
        // eslint-disable-next-line no-param-reassign
        scrollY.value = e.nativeEvent.contentOffset.y;
    }, [scrollY]);
    const textInputStyle = useAnimatedStyle(() => ({
        backgroundColor: interpolateColor(scrollY.value, [0, 40], [
            colors.cards.onDark,
            colors.background.dark,
        ]),
    }), [colors.background.dark, colors.cards.onDark, scrollY.value]);
    return (<AppView type="secondary" withFrame={false} isInTab={isWeb}>
      <NavigationHeader scrollY={scrollY} withFrame isInTab={isWeb}>
        <SearchBar ref={refSearchInput} onCancel={handleReset} placeholder={strings.search} forSearchHeader onChangeSearchTerm={handleSearch} isInTab={isWeb} textInputBgStyles={textInputStyle}/>
      </NavigationHeader>
      <MerchantsList onScroll={onScroll} searchResults={filteredMerchants}/>
    </AppView>);
};
export default SelectMerchantScreen;
