import Config from 'utils/packages/configs';
import { format, add, sub, isSameYear, startOfDay, startOfHour, startOfMonth, isToday, isYesterday, isTomorrow, formatISO, startOfWeek, subWeeks, endOfWeek, parseISO, } from 'date-fns';
import * as utils from 'utils/formatting';
import { SHARE_QUANTITY_DECIMALS, PORTFOLIO_CHART_DEFAULT_PERIOD, TRADING_CHART_DEFAULT_PERIOD } from '../constants';
import { Exchange, RecurringBuyInterval } from '../types';
export { default as UnableToSwitchToAnotherPeriodError } from './errors/unableToSwitchToAnotherPeriodError';
/**
 * Use this function to log anything useful. These logs will only be shown when ENABLE_INVEST_LOGS is true.
 * @param value Any value you need to log
 * @param stringify Use false if the value is already a string, true otherwise
 * @param color Color your logs, because why not!
 */
export function logInvest(value, stringify, color) {
    if (Config.ENABLE_INVEST_LOGS) {
        if (!stringify) {
            log(`[INVEST] ${value}`, false, color);
        }
        else {
            log(value, true, color);
        }
    }
}
export const getRisingFalling = (percent, amount, withAmount) => {
    if (withAmount) {
        const rising = percent > 0 || amount.value > 0;
        const falling = percent < 0 || amount.value < 0;
        if (rising && !falling) {
            return [true, false];
        }
        if (!rising && falling) {
            return [false, true];
        }
        return [false, false]; // if values conflict with each other - show it in grey, at least to avoid lying.
    }
    if (percent > 0) {
        return [true, false];
    }
    if (percent < 0) {
        return [false, true];
    }
    return [false, false];
};
export const formatAmount = (amount, // declaring this as optional for edge cases that may only happen while development (faking account status)
digits) => {
    if (!amount)
        return utils.formatAmount(0, 'GBP', digits ?? 2);
    const power = digits ?? 2;
    const factor = 10 ** power;
    const result = utils.formatAmount(Math.round(amount.value * factor) / factor, amount.currency, digits ?? 2);
    if (result.match(/\./)) {
        // if there is a period, then check for stuff like $1.1 and convert it to $1.10
        if (result.substr(-2, 1) === '.') {
            return `${result}0`;
        }
    }
    else if (digits === 2) {
        return `${result}.00`;
    }
    return result;
};
export const formatAmountWithoutCurrency = (amount, digits) => {
    if (!amount)
        return utils.formatAmount(0, undefined, digits ?? 2);
    const power = digits ?? 2;
    const factor = 10 ** power;
    const result = utils.formatAmount(Math.round(amount * factor) / factor, undefined, digits ?? 2);
    if (result.match(/\./)) {
        // if there is a period, then check for stuff like $1.1 and convert it to $1.10
        if (result.substr(-2, 1) === '.') {
            return `${result}0`;
        }
    }
    else if (digits === 2) {
        return `${result}.00`;
    }
    return result;
};
export const formatFXConversion = (fxConversion) => {
    const { rateFrom, rateTo, rate } = fxConversion;
    const onePound = formatAmount({ value: 1, currency: rateFrom });
    const inDollars = formatAmount({ value: rate, currency: rateTo }, 4);
    return `${onePound} = ${inDollars}`;
};
export const formatDate = (date) => {
    const dateObj = parseISO(date);
    if (isToday(dateObj)) {
        return format(dateObj, "H:mm 'today', d MMMM");
    }
    if (isYesterday(dateObj)) {
        return format(dateObj, "H:mm 'yesterday', d MMMM");
    }
    if (isTomorrow(dateObj)) {
        return format(dateObj, "H:mm 'tomorrow', d MMMM");
    }
    return format(dateObj, 'H:mm EEEE, d MMMM');
};
export const formatDateForItem = (date) => utils.formatDateForItem(date);
export const formatDateForSectionHeader = (date) => {
    const m = date instanceof Date ? date : parseISO(date);
    if (isSameYear(m, new Date())) {
        return format(m, 'MMMM');
    }
    return format(m, 'MMMM yyyy');
};
export const formatDateForStatement = (item) => {
    const m = new Date(Number(item.year), Number(item.month) - 1);
    return format(m, 'MMMM yyyy');
};
/**
 * @param date
 * @returns an array [day, month, year]
 */
export const formatDateForReceipt = (date) => format(date instanceof Date ? date : parseISO(date), 'd MMM, yyyy').split(' ');
export const formatTimeForReceipt = (date) => format(date instanceof Date ? date : parseISO(date), 'HH:mm');
export const formatDateForLatestPrice = (date) => format(parseISO(date), 'HH:mm, dd MMM');
export const formatDateForLatestPriceTooltip = (date) => format(parseISO(date), 'HH:mm:ss, dd MMM');
export const roundQuantity = (quantity, fractionDigits = SHARE_QUANTITY_DECIMALS) => {
    const multiplier = 10 ** fractionDigits;
    return Math.round(quantity * multiplier) / multiplier;
};
export const formatNumberOfShares = (quantity, withWord = true, useCapitalS = false, fractionDigits = null, // null means "keep fractions as they are", so 0.04 will stay 0.04, and 0.1 will be 0.1
scrambled = false) => {
    if (quantity === 1 && !scrambled && !fractionDigits) {
        if (withWord) {
            if (useCapitalS) {
                return '1 Share';
            }
            return '1 share';
        }
        return '1';
    }
    const roundedQuantity = roundQuantity(quantity); // showing only 4 digits after dot
    if (withWord) {
        if (useCapitalS) {
            return `${utils.formatAmount(roundedQuantity, undefined, fractionDigits, scrambled)} Shares`;
        }
        return `${utils.formatAmount(roundedQuantity, undefined, fractionDigits, scrambled)} shares`;
    }
    return `${utils.formatAmount(roundedQuantity, undefined, fractionDigits, scrambled)}`;
};
export const formatMTimestamp = (mTimestamp) => {
    const date = new Date(mTimestamp * 60000);
    if (isToday(date)) {
        return format(date, "H:mm 'Today', d MMMM");
    }
    if (isYesterday(date)) {
        return format(date, "H:mm 'Yesterday', d MMMM");
    }
    return format(date, 'H:mm EEEE, d MMMM');
};
export const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];
export const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
export const daysShort = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const generateFakeSymbol = (symbols, searchTerm) => {
    const items = symbols ?? ['AAPL', 'TSLA', 'BABA', 'SPCE', 'TAL', 'GOOG', 'MSFT', 'FB', 'AMZN', 'SNAP', 'NVDA'];
    if (!searchTerm) {
        return items[Math.floor(Math.random() * items.length)];
    }
    const matchingItems = items.filter((item) => {
        const regexp = new RegExp(searchTerm);
        return getSymbolName(item).match(regexp) || item.match(regexp);
    });
    if (matchingItems.length > 0) {
        return matchingItems[Math.floor(Math.random() * matchingItems.length)];
    }
    return null;
};
const getSymbolName = (symbol) => {
    const names = {
        AAPL: 'Apple',
        TSLA: 'Tesla',
        BABA: 'Alibaba',
        SPCE: 'Virgin Galactic',
        TAL: 'TAL Education Group',
        GOOG: 'Google',
        MSFT: 'Microsoft',
        FB: 'Facebook',
        AMZN: 'Amazon',
        SNAP: 'Snapchat',
        NVDA: 'NVIDIA',
    };
    return symbol in names ? names[symbol] : '';
};
function* fakePortfolioDataGenerator(period) {
    let N = 200;
    const startingEquity = 100;
    let currentEquity = startingEquity;
    let currentRollingTopUps = 0;
    let currentRollingWithdraws = 0;
    let range = 5;
    let start = sub(startOfMonth(new Date()), { months: N });
    let p = 'minutes';
    let factor = 1;
    switch (period) {
        case '1D':
            N = 24 * 12;
            range = 5;
            start = sub(startOfHour(new Date()), { minutes: N * 10 });
            p = 'minutes';
            factor = 5;
            break;
        case '1W':
            N = 7 * 24;
            range = 20;
            start = sub(startOfHour(new Date()), { hours: N });
            p = 'hours';
            break;
        case '1M':
            N = 30;
            range = 50;
            start = sub(startOfDay(new Date()), { days: N });
            p = 'days';
            break;
        case '1Y':
            N = 365;
            range = 50;
            start = sub(startOfDay(new Date()), { days: N });
            p = 'days';
            break;
        case 'ALL':
            N = 365 * 5;
            range = 50;
            start = sub(startOfDay(new Date()), { days: N });
            p = 'days';
            break;
        default:
            range = 100;
    }
    for (let i = 0; i < N; i += 1) {
        const randomProfitForPeriod = (currentEquity * (Math.random() * range - range / 2)) / 100;
        const randomTopUpForThisPeriod = Math.random() < 0.02 ? currentEquity : 0;
        const randomWithdrawForThisPeriod = Math.random() < 0.01 ? currentEquity / 2 : 0;
        currentRollingTopUps += randomTopUpForThisPeriod;
        currentRollingWithdraws += randomWithdrawForThisPeriod;
        currentEquity += randomProfitForPeriod + randomTopUpForThisPeriod - randomWithdrawForThisPeriod;
        const timestamp = add(start, { [p]: i * factor }).getTime() / 1000;
        yield {
            timestamp,
            equity: currentEquity,
            profitLoss: currentEquity - startingEquity - currentRollingTopUps + currentRollingWithdraws,
            profitLossPct: (currentEquity - startingEquity - currentRollingTopUps + currentRollingWithdraws) / startingEquity,
        };
    }
}
export const generateFakePortfolioData = (params) => {
    const result = {
        chart: { timestamp: [], equity: [], profitLoss: [], profitLossPct: [] },
    };
    const generator = fakePortfolioDataGenerator(params.period);
    // eslint-disable-next-line no-restricted-syntax
    for (const data of generator) {
        result.chart.timestamp.push(data.timestamp);
        result.chart.equity.push(data.equity);
        result.chart.profitLoss.push(data.profitLoss);
        result.chart.profitLossPct.push(data.profitLossPct);
    }
    return result;
};
export const generateFakeOrder = (i, symbols) => {
    const price = Math.random() * 1000;
    const quantity = Math.floor(Math.random() * 1000000) / 10000;
    const status = 'queued';
    const operation = Math.floor(Math.random() * 2) === 0 ? 'buy' : 'sell';
    const symbol = generateFakeSymbol(symbols);
    if (!symbol)
        return null;
    const currentDate = new Date();
    return {
        id: i.toString(),
        assetId: `assetId${i.toString()}`,
        symbol,
        timestamp: sub(currentDate, { days: i + Math.random() }).toISOString(),
        status,
        operation,
        requested: {
            quantity,
            price: {
                currency: 'USD',
                value: price,
            },
            priceTimestamp: sub(currentDate, { hours: i }).toISOString(),
            fxConversion: {
                from: 'GBP',
                rateFrom: 'GBP',
                to: 'USD',
                rateTo: 'USD',
                rate: 1.4079,
                base: 1.4079,
                fee: { value: 0, currency: 'USD' },
                pair: 'GBPUSD',
            },
        },
        estimated: {
            amount: {
                currency: 'USD',
                value: price * quantity,
            },
            quantity,
            price: {
                currency: 'USD',
                value: price,
            },
            priceConvertedToUserCurrency: {
                currency: 'GBP',
                value: price / 1.4079,
            },
            priceTimestamp: sub(currentDate, { hours: i }).toISOString(),
            fxConversion: {
                from: 'GBP',
                rateFrom: 'GBP',
                to: 'USD',
                rateTo: 'USD',
                rate: 1.4079,
                base: 1.4079,
                fee: { value: 0, currency: 'USD' },
                pair: 'GBPUSD',
            },
        },
        commission: { value: 0, currency: 'GBP' },
    };
};
/* eslint-disable no-nested-ternary */
const generateFakeActivity = (i, params) => {
    const random = Math.random();
    const quantity = Math.floor(Math.random() ** 3 * 1000000) / 10000;
    const status = random < 0.5 ? 'completed' : random < 0.8 ? 'pending' : random < 0.9 ? 'cancelled' : 'failed';
    let result = {
        id: Math.random().toString(),
        status,
        amount: { value: random * random * 10000, currency: 'GBP' },
        timestamp: sub(new Date(), {
            days: (i / 3) * i - 1,
            hours: Math.random() * 4,
        }).toISOString(),
        type: 'deposit',
    };
    const anotherRandom = Math.random();
    const symbol = params.symbols
        ? params.symbols[Math.floor(Math.random() * params.symbols.length)]
        : generateFakeSymbol();
    if (!symbol)
        return null;
    switch (true) {
        case anotherRandom < 0.1 && !params.symbols:
            result.type = 'withdraw';
            break;
        case anotherRandom < 0.2 && !params.symbols:
            break;
        case anotherRandom < 0.5:
            result = {
                ...result,
                type: 'buy',
                symbol,
                quantity,
            };
            break;
        case anotherRandom < 0.9:
            result = {
                ...result,
                type: 'sell',
                symbol,
                quantity,
            };
            break;
        default:
            result = {
                ...result,
                type: 'dividend',
                symbol,
                quantity,
            };
            break;
    }
    return result;
};
/* eslint-enable no-nested-ternary */
export const generateFakeActivities = (params) => {
    const page = 'page' in params.paging ? params.paging.page : 1;
    // const perPage = 'perPage' in params.paging ? params.paging.perPage : 15;
    const perPage = 300;
    const result = {
        activities: [],
        paging: {
            token: '',
            page,
            perPage,
            count: page * perPage,
        },
    };
    for (let i = (page - 1) * perPage; i < page * perPage; i += 1) {
        const fakeActivity = generateFakeActivity(i, params);
        if (fakeActivity) {
            result.activities.push(fakeActivity);
        }
    }
    return result;
};
export const generateFakeNews = () => [
    {
        id: '1',
        headline: 'Apple stock rises after tech giant looks to expand production outside China',
        source: 'Yahoo Finance Video',
        mTimestamp: sub(new Date(), { hours: 2 }).getTime() / 60000,
        url: 'https://finance.yahoo.com/video/apple-stock-rises-tech-giant-153459676.html',
        image: 'https://s.yimg.com/uu/api/res/1.2/eXkWdHqxXpQiiFt7MWPmmw--~B/Zmk9ZmlsbDtweW9mZj0wO3c9MjYwO2g9MTQ0O3NtPTE7YXBwaWQ9eXRhY2h5b24-/https://s.yimg.com/os/creatr-uploaded-images/2022-05/e2877bd0-daad-11ec-bb4a-c0c7290fcb45',
    },
    {
        id: '2',
        headline: 'Market check: Stocks rally after teasing bear market',
        source: 'Yahoo Finance Video',
        mTimestamp: sub(new Date(), { hours: 3 }).getTime() / 60000,
        url: 'https://finance.yahoo.com/video/market-check-stocks-rally-teasing-141240403.html',
        image: 'https://s.yimg.com/uu/api/res/1.2/eXkWdHqxXpQiiFt7MWPmmw--~B/Zmk9ZmlsbDtweW9mZj0wO3c9MjYwO2g9MTQ0O3NtPTE7YXBwaWQ9eXRhY2h5b24-/https://s.yimg.com/os/creatr-uploaded-images/2022-05/e2877bd0-daad-11ec-bb4a-c0c7290fcb45',
    },
    {
        id: '3',
        headline: 'Apple’s VR headset compromises power for portability - report',
        source: 'Yahoo Finance Video',
        mTimestamp: sub(new Date(), { hours: 4 }).getTime() / 60000,
        url: 'https://www.independent.co.uk/tech/apple-virtual-reality-headset-powerful-b2085131.html',
        image: 'https://s.yimg.com/uu/api/res/1.2/eXkWdHqxXpQiiFt7MWPmmw--~B/Zmk9ZmlsbDtweW9mZj0wO3c9MjYwO2g9MTQ0O3NtPTE7YXBwaWQ9eXRhY2h5b24-/https://s.yimg.com/os/creatr-uploaded-images/2022-05/e2877bd0-daad-11ec-bb4a-c0c7290fcb45',
    },
];
export const generateFakeNewsResponse = () => [
    {
        datetime: sub(new Date(), { hours: 1 }).toISOString(),
        summary: '',
        related: [],
        lang: 'en',
        hasPaywall: false,
        headline: 'Apple stock rises after tech giant looks to expand production outside China',
        source: 'Yahoo Finance Video',
        url: 'https://finance.yahoo.com/video/apple-stock-rises-tech-giant-153459676.html',
        image: 'https://s.yimg.com/uu/api/res/1.2/eXkWdHqxXpQiiFt7MWPmmw--~B/Zmk9ZmlsbDtweW9mZj0wO3c9MjYwO2g9MTQ0O3NtPTE7YXBwaWQ9eXRhY2h5b24-/https://s.yimg.com/os/creatr-uploaded-images/2022-05/e2877bd0-daad-11ec-bb4a-c0c7290fcb45',
    },
    {
        datetime: sub(new Date(), { hours: 2 }).toISOString(),
        summary: '',
        related: [],
        lang: 'en',
        hasPaywall: false,
        headline: 'Market check: Stocks rally after teasing bear market',
        source: 'Yahoo Finance Video',
        url: 'https://finance.yahoo.com/video/market-check-stocks-rally-teasing-141240403.html',
        image: 'https://s.yimg.com/uu/api/res/1.2/eXkWdHqxXpQiiFt7MWPmmw--~B/Zmk9ZmlsbDtweW9mZj0wO3c9MjYwO2g9MTQ0O3NtPTE7YXBwaWQ9eXRhY2h5b24-/https://s.yimg.com/os/creatr-uploaded-images/2022-05/e2877bd0-daad-11ec-bb4a-c0c7290fcb45',
    },
    {
        datetime: sub(new Date(), { hours: 3 }).toISOString(),
        summary: '',
        related: [],
        lang: 'en',
        hasPaywall: false,
        headline: 'Apple’s VR headset compromises power for portability - report',
        source: 'Yahoo Finance Video',
        url: 'https://www.independent.co.uk/tech/apple-virtual-reality-headset-powerful-b2085131.html',
        image: 'https://s.yimg.com/uu/api/res/1.2/eXkWdHqxXpQiiFt7MWPmmw--~B/Zmk9ZmlsbDtweW9mZj0wO3c9MjYwO2g9MTQ0O3NtPTE7YXBwaWQ9eXRhY2h5b24-/https://s.yimg.com/os/creatr-uploaded-images/2022-05/e2877bd0-daad-11ec-bb4a-c0c7290fcb45',
    },
];
export const generateFakeSearchResults = (searchTerm, page) => {
    if (page && page > 1) {
        return { assets: [], count: 100 };
    }
    const perPage = 15;
    const assets = [];
    const symbols = new Set();
    for (let i = ((page ?? 1) - 1) * perPage; i < (page ?? 1) * perPage; i += 1) {
        const symbol = generateFakeSymbol(undefined, searchTerm);
        if (symbol && !symbols.has(symbol)) {
            assets.push({
                id: String(i),
                exchange: Exchange.NYSE,
                symbol,
                name: getSymbolName(symbol),
                fractionable: true,
                sharePrice: Math.random() * 1000,
                sharePriceChange: Math.random() * 5 - 2.5,
                sharePriceChangePct: Math.random() - 0.5,
                icon: 'https://storage.yandexcloud.net/cheze/apple.png',
            });
            symbols.add(symbol);
        }
    }
    return {
        assets,
        count: 100,
    };
};
export const formatDateForSectionHeaderForStatement = (date) => {
    const m = date instanceof Date ? date : parseISO(date);
    const mPlus1 = m.getDate() < 15 ? m : add(m, { months: 1 });
    const currentYear = new Date();
    if (isSameYear(mPlus1, currentYear)) {
        return {
            title: format(mPlus1, 'MMMM'),
            month: format(m, 'MMMM'),
            year: format(m, 'yyyy'),
        };
    }
    return {
        title: format(mPlus1, 'MMMM yyyy'),
        month: format(m, 'MMMM'),
        year: format(m, 'yyyy'),
    };
};
export const createActivitySections = (items, options) => {
    if (!items.length)
        return [];
    const groupedMap = {};
    for (let i = 0; i < items.length; i++) {
        if (items[i]) {
            if (items[i].type === 'statement' && options?.withStatements) {
                const timestampKeyForI = items[i].timestamp;
                const { title, month, year } = formatDateForSectionHeaderForStatement(timestampKeyForI);
                if (groupedMap[title]) {
                    const grouped = groupedMap[title];
                    grouped.statements.push({
                        year,
                        month,
                        ...items[i],
                        isFirstInSection: undefined,
                        isLastInSection: undefined,
                    });
                }
                else {
                    const m = parseISO(timestampKeyForI);
                    groupedMap[title] = {
                        key: title,
                        month: m.getMonth(),
                        year: m.getFullYear(),
                        statements: [{ ...items[i], month, year }],
                        activityData: [],
                    };
                }
            }
            else {
                const timestampKeyForI = items[i].timestamp;
                const title = formatDateForSectionHeader(timestampKeyForI);
                if (groupedMap[title]) {
                    const grouped = groupedMap[title];
                    grouped.activityData.push({
                        ...items[i],
                        isFirstInSection: undefined,
                        isLastInSection: undefined,
                    });
                }
                else {
                    const m = parseISO(timestampKeyForI);
                    groupedMap[title] = {
                        key: title,
                        month: m.getMonth(),
                        year: m.getFullYear(),
                        activityData: [{ ...items[i] }],
                        statements: [],
                    };
                }
            }
        }
    }
    const grouped = Object.values(groupedMap);
    const sectionedFlashListData = [];
    grouped.forEach((group) => {
        const { activityData, statements } = group;
        const finalData = options?.withStatements ? [...activityData, ...statements] : activityData;
        const finalDataLength = finalData.length;
        if (finalDataLength) {
            finalData[0].isFirstInSection = true;
            finalData[finalDataLength - 1].isLastInSection = true;
            sectionedFlashListData.push({
                id: group.key,
                sectionTitle: group.key,
            });
            sectionedFlashListData.push(...finalData);
        }
    });
    return sectionedFlashListData;
};
export const generateFakeAccount = () => {
    const cashTotal = 114890;
    const cashReserved = 3890.43;
    const longPositionsMarketValue = 89389.43;
    const result = {
        currency: 'GBP',
        availableToInvest: { value: cashTotal - cashReserved, currency: 'GBP' },
        cashWithdrawable: { value: cashTotal - cashReserved, currency: 'GBP' },
        cashUnsettled: { value: cashTotal - cashReserved, currency: 'GBP' },
        cashReserved: { value: cashReserved, currency: 'GBP' },
        cashTotal: { value: cashTotal, currency: 'GBP' },
        longPositionsMarketValue: {
            value: longPositionsMarketValue,
            currency: 'GBP',
        },
        status: 'ACTIVE',
        timestamp: formatISO(new Date(Date.UTC(2021, 7, 11))), // date/time when account was created (in ISO format)
    };
    return result;
};
export const generateFakeOrderPreview = (symbol, amount, operation) => {
    const fxConversion = {
        from: 'GBP',
        rateFrom: 'GBP',
        to: 'USD',
        rateTo: 'USD',
        rate: 1.4079,
        base: 1.4079,
        fee: { value: 0, currency: 'USD' },
        pair: 'GBPUSD',
        fromAmount: amount,
        toAmount: amount * 1.4079,
    };
    const price = {
        value: Math.random() * 1000,
        currency: 'USD',
    };
    const priceTimestamp = new Date().toISOString();
    const requested = operation === 'buy'
        ? {
            amount: { value: amount, currency: 'GBP' },
            price,
            priceTimestamp,
            fxConversion,
        }
        : {
            quantity: amount,
            price,
            priceTimestamp,
            fxConversion,
        };
    const result = {
        symbol,
        operation,
        requested,
        estimated: {
            amount: { value: amount, currency: 'GBP' },
            quantity: (amount * fxConversion.rate) / price.value,
            price,
            priceConvertedToUserCurrency: {
                value: price.value / fxConversion.rate,
                currency: 'GBP',
            },
            priceTimestamp,
            fxConversion,
        },
        commission: { value: 0, currency: 'GBP' },
    };
    return result;
};
export const getChartColor = (data, colors) => {
    if (data && 'points' in data) {
        const { points } = data;
        const first = points[0];
        const last = points[points.length - 1];
        if (last.y - first.y >= 0) {
            return colors.elements.positive;
        }
        return colors.elements.negative;
    }
    if (data && 'value' in data) {
        if (data.value >= 0) {
            return colors.elements.positive;
        }
        return colors.elements.negative;
    }
    return colors.elements.positive;
};
export const filterActivities = (activity) => activity.type === 'statement' || (activity.status !== 'failed' && activity.status !== 'pending');
export const mapStockToPortfolioChartPeriod = (stockChartPeriod) => {
    switch (stockChartPeriod ?? TRADING_CHART_DEFAULT_PERIOD) {
        case '1D':
            return '1D';
        case '1W':
            return '1W';
        case '1M':
            return '1M';
        case '1Y':
            return '1Y';
        case 'MAX':
            return 'ALL';
        default:
            return PORTFOLIO_CHART_DEFAULT_PERIOD;
    }
};
export const mapPortfolioToStockChartPeriod = (portfolioChartPeriod) => {
    switch (portfolioChartPeriod ?? PORTFOLIO_CHART_DEFAULT_PERIOD) {
        case '1D':
            return '1D';
        case '1W':
            return '1W';
        case '1M':
            return '1M';
        case '1Y':
            return '1Y';
        case 'ALL':
            return 'MAX';
        default:
            return TRADING_CHART_DEFAULT_PERIOD;
    }
};
export const mapStockChartPeriodToDateRange = (stockChartPeriod) => {
    switch (stockChartPeriod) {
        case '1D':
            return 'RANGE_1D';
        case '1W':
            return 'RANGE_1W';
        case '1M':
            return 'RANGE_1M';
        case '1Y':
            return 'RANGE_1Y';
        case 'MAX':
            return 'RANGE_5Y';
        default:
            return mapStockChartPeriodToDateRange(TRADING_CHART_DEFAULT_PERIOD);
    }
};
export const getPriceChange = (response) => {
    const open = response.closePrices[0];
    if (open !== null) {
        let close = response.closePrices[response.closePrices.length - 1];
        if (close !== null) {
            return {
                value: close - open,
                percent: ((close - open) / open) * 100,
            };
        }
        let i = response.closePrices.length - 1;
        while (close === null) {
            i -= 1;
            close = response.closePrices[i];
        }
        return {
            value: close - open,
            percent: ((close - open) / open) * 100,
        };
    }
    return undefined;
};
export class AlternativePeriodError extends Error {
    period;
    constructor(period, ...params) {
        // Pass remaining arguments (including vendor specific ones) to parent constructor
        super(...params);
        // Maintains proper stack trace for where our error was thrown (only available on V8)
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, AlternativePeriodError);
        }
        this.name = 'AlternativePeriodError';
        // Custom debugging information
        this.period = period;
    }
}
/**
 * A function to find an alternative period to show on Stock chart, when selected period fails to load.
 * @param cache Cache for chart data, mapped by period key
 * @param badPeriod Period that failed to be fetched
 *
 * Throws and error that you need to catch and fetch another period;
 */
export function findAlternativePeriod(cache, badPeriod) {
    let suggestedPeriods;
    const suggestedPeriodsMap = {
        '1D': ['1W', '1M', '1Y', 'MAX'],
        '1W': ['1M', '1Y', 'MAX', '1D'],
        '1M': ['1Y', '1W', 'MAX', '1D'],
        '1Y': ['MAX', '1M', '1W', '1D'],
        MAX: ['1Y', '1M', '1W', '1D'],
    };
    suggestedPeriods = suggestedPeriodsMap[badPeriod];
    if (!suggestedPeriods) {
        throw new RangeError('Invalid period provided');
    }
    for (let i = 0; i < suggestedPeriods.length; i++) {
        const period = suggestedPeriods[i];
        if (cache[period])
            return period;
    }
    throw new AlternativePeriodError(suggestedPeriods[0]);
}
export const getReadeablePeriodFromInterval = (period) => {
    switch (period) {
        case RecurringBuyInterval.WEEKLY:
            return 'Weekly';
        case RecurringBuyInterval.MONTHLY:
            return 'Monthly';
        case RecurringBuyInterval.QUARTERLY:
            return 'Quarterly';
        default:
            return '';
    }
};
export const formatNextBuyDate = (date) => {
    const dateObj = parseISO(date);
    const lastWeek = [startOfWeek(subWeeks(new Date(), 1)), endOfWeek(subWeeks(new Date(), 1))];
    if (isToday(dateObj))
        return 'Today';
    if (isTomorrow(dateObj))
        return 'Tomorrow';
    if (isYesterday(dateObj))
        return 'Yesterday';
    if (dateObj >= lastWeek[0] && dateObj <= lastWeek[1])
        return format(dateObj, 'EEEE');
    return format(dateObj, 'MMM do');
};
export const formatExpiryDate = (date) => {
    const parsedDate = parseISO(date);
    if (isToday(parsedDate)) {
        return 'Today';
    }
    if (isTomorrow(parsedDate)) {
        return 'Tomorrow';
    }
    return format(parsedDate, 'd LLL');
};
export const formatDateTime = (date) => {
    const dateObj = parseISO(date);
    const lastWeek = [startOfWeek(subWeeks(new Date(), 1)), endOfWeek(subWeeks(new Date(), 1))];
    if (isToday(dateObj))
        return `today ${format(dateObj, 'h:mmaaa')}`;
    if (isTomorrow(dateObj))
        return `tomorrow ${format(dateObj, 'h:mmaaa')}`;
    if (isYesterday(dateObj))
        return `yesterday ${format(dateObj, 'h:mmaaa')}`;
    if (dateObj >= lastWeek[0] && dateObj <= lastWeek[1])
        return format(dateObj, 'EEEE h:mmaaa');
    return format(dateObj, 'MMM do h:mmaaa');
};
export const getHasSourceOfFundingInfo = ({ annualIncomeMin, annualIncomeMax, liquidNetWorthMin, liquidNetWorthMax, fundingSource, employmentStatus, }) => !!(annualIncomeMin !== undefined &&
    annualIncomeMin !== null &&
    annualIncomeMax !== undefined &&
    annualIncomeMax !== null &&
    liquidNetWorthMin !== undefined &&
    liquidNetWorthMin !== null &&
    liquidNetWorthMax !== undefined &&
    liquidNetWorthMax !== null &&
    fundingSource &&
    employmentStatus);
