import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState, } from 'react';
import { View, Keyboard, Platform } from 'react-native';
import Animated from 'react-native-reanimated';
import Spacer from 'design-system/Spacer';
import { CardView } from 'design-system/CardView';
import Indicator from 'design-system/Indicator';
import { SCROLL_INDICATOR_INSETS, rem } from 'design-system/values';
import StatusBar from 'design-system/StatusBar';
import { AppView } from 'design-system/AppView';
import ListEmptyView from 'design-system/ListEmptyView';
import withAnimatedNavigationHeader from 'design-system/hoc/withAnimatedNavigationHeader';
import useStyles from 'hooks/useStyles';
import useColors from 'hooks/useColors';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useReSelector } from 'utils/hooksApi';
import createStyleSheets from 'utils/createStyleSheets';
import { useMarginBottom } from 'hooks/useMarginBottom';
import { selectListAccounts } from 'reducers/selectors';
import { useFetchOld } from 'hooks';
import StackHeader from 'design-system/hoc/StackHeader';
import { addTransactionToCache } from 'actions';
import NavigationHeaderRightIconButton from 'design-system/NavigationHeaderRightIconButton';
import Amplitude from '../../../utils/amplitude';
import { makeSelectSubscriptionById } from '../selectors';
import { useSubscriptionMenu } from '../hooks/useSubscriptionMenu';
import { getSubscriptionsById, removeTxnsFromSubscription } from '../actions';
import { SubscriptionScreenContext } from '../context/subscriptionScreenContext';
import SubscriptionScreenListHeader from '../components/SubscriptionScreenListHeader';
import RowSubscriptionTransaction, { ROW_SUB_TRANSACTION_HEIGHT, } from '../components/transactions/RowSubscriptionTransaction';
import { isWeb } from '../../../constants';
import IconMore from '../icons/IconMore';
const keyExtractor = (item) => item.id.toString();
const SubscriptionScreen = withAnimatedNavigationHeader(({ navigation, route, onScroll }) => {
    const touchViewRef = useRef(null);
    const colors = useColors();
    const dispatch = useAppDispatch();
    const styles = useStyles(styleSet);
    const paddingBottom = useMarginBottom('paddingBottom');
    const { paddingHorizontalStyle } = useAppFrameDimensions(isWeb);
    useEffect(() => {
        if (route.params?.id) {
            dispatch(getSubscriptionsById(route.params.id));
        }
    }, [route.params?.id]);
    const [, , , fetch] = useFetchOld({
        throws: true,
        muteError: false,
        muteFetching: false,
    });
    const [editing, setIsEditing] = useState(false);
    const [txnToDelete, setTxnToDelete] = useState([]);
    const memoSelectSubscriptionById = useMemo(makeSelectSubscriptionById, []);
    const subscription = useAppSelector((store) => memoSelectSubscriptionById(store, route.params.id));
    const subscriptionTransactions = useReSelector((store) => store.subscriptions.subscriptionTransactions);
    const isFetching = useAppSelector((store) => store.subscriptions.isFetchingSubscriptionsTransactions);
    const accounts = useAppSelector(selectListAccounts);
    const listToShow = useMemo(() => {
        if (!txnToDelete.length)
            return subscriptionTransactions;
        return subscriptionTransactions.filter((txn) => !txnToDelete.includes(txn.id));
    }, [subscriptionTransactions, txnToDelete]);
    const [openSubscriptionMenu] = useSubscriptionMenu(navigation);
    const showOptions = useCallback((data) => {
        Keyboard.dismiss();
        openSubscriptionMenu(subscription, data, touchViewRef);
        Amplitude.logEvent('Subscription.OpenMenu');
    }, [openSubscriptionMenu, subscription]);
    useLayoutEffect(() => {
        const headerTitle = subscription?.customName || subscription?.merchantInfo?.displayName;
        navigation.setOptions({
            headerRight: () => (<View ref={touchViewRef}>
            <NavigationHeaderRightIconButton onPress={() => {
                    showOptions();
                }}>
              <IconMore color={colors.elements.primary}/>
            </NavigationHeaderRightIconButton>
          </View>),
            headerTitle: () => (<StackHeader width={250} title={headerTitle || ''}/>),
            title: isWeb ? headerTitle : undefined,
        });
    }, [
        showOptions,
        colors.elements.primary,
        subscription?.customName,
        subscription?.merchantInfo?.displayName,
    ]);
    const onPress = useCallback((routeWithParams) => {
        navigation.navigate(routeWithParams);
        Amplitude.logEvent('Subscription.openCompare', {
            type: routeWithParams.name,
        });
    }, []);
    const editPrediction = useCallback(() => {
        if (subscription) {
            navigation.navigate('CorrectedAmount', {
                subscription,
            });
        }
    }, [subscription]);
    const onEdit = useCallback(() => {
        if (!subscription?.id)
            return;
        setIsEditing(true);
    }, [subscription?.id]);
    const onDone = useCallback(async () => {
        if (!subscription?.id)
            return;
        setIsEditing(false);
        if (!txnToDelete.length)
            return;
        try {
            await fetch(removeTxnsFromSubscription(subscription?.id, txnToDelete));
            setTxnToDelete([]);
        }
        catch (e) {
            setTxnToDelete([]);
        }
    }, [subscription?.id, txnToDelete]);
    const addTxnToDelete = useCallback((txnId) => {
        setTxnToDelete((txnIds) => [...txnIds, txnId]);
    }, []);
    const ListHeader = useMemo(() => (<SubscriptionScreenListHeader onDone={onDone} onEdit={onEdit} onPress={onPress} editPrediction={editPrediction}/>), [editPrediction, onDone, onEdit, onPress]);
    const openTransaction = useCallback((item) => {
        const itemWithSubscriptionId = {
            ...item,
            subscriptionId: subscription?.id || null,
        };
        dispatch(addTransactionToCache(itemWithSubscriptionId));
        navigation.navigate('Item', {
            id: item.id,
            skipGetSubscription: true,
        });
    }, [subscription?.id]);
    const renderItem = useCallback(({ item, index }) => (<View style={[
            styles.renderItem,
            index === 0 &&
                (editing || !subscription?.isActive) &&
                styles.itemContainerFirst,
            index === listToShow.length - 1 && styles.itemContainerLast,
        ]}>
          <RowSubscriptionTransaction data={item} accounts={accounts} isEditing={editing} onPress={openTransaction} addTxnToDelete={addTxnToDelete}/>
        </View>), [
        styles.renderItem,
        styles.itemContainerFirst,
        styles.itemContainerLast,
        editing,
        subscription?.isActive,
        listToShow.length,
        accounts,
        openTransaction,
        addTxnToDelete,
    ]);
    const renderListEmpty = useCallback(() => {
        if (isFetching) {
            return null;
        }
        return (<>
          {!editing && <Spacer h={16}/>}
          <CardView noMarginTop>
            <ListEmptyView compact title="No transactions" style={styles.listEmptyView} subTitle="All of your transactions will appear here."/>
          </CardView>
        </>);
    }, [editing, isFetching, styles.listEmptyView]);
    const contentContainerStyles = useMemo(() => [styles.listContent, paddingBottom, paddingHorizontalStyle], [paddingBottom, paddingHorizontalStyle, styles.listContent]);
    const getItemLayout = useCallback((_data, index) => ({
        index,
        length: ROW_SUB_TRANSACTION_HEIGHT,
        offset: ROW_SUB_TRANSACTION_HEIGHT * index,
    }), []);
    const contextValue = useMemo(() => ({
        editing,
        isFetching,
        data: subscription,
        hasData: !!subscriptionTransactions.length,
        name: subscription?.customName || subscription?.merchantInfo?.displayName,
    }), [editing, isFetching, subscription, subscriptionTransactions.length]);
    return (<AppView withFrame={false}>
        <SubscriptionScreenContext.Provider value={contextValue}>
          <View style={styles.content}>
            <StatusBar barStyle="dark-content" animated/>
            {isFetching &&
            !(subscription?.maxAmount && subscription?.minAmount) &&
            !subscription?.transactions?.length ? (<View style={styles.indicatorContainer}>
                <Indicator dark/>
              </View>) : (<Animated.FlatList data={listToShow} onScroll={onScroll} initialNumToRender={4} renderItem={renderItem} scrollEventThrottle={16} keyExtractor={keyExtractor} getItemLayout={getItemLayout} ListHeaderComponent={ListHeader} keyboardShouldPersistTaps="handled" ListEmptyComponent={renderListEmpty} contentContainerStyle={contentContainerStyles} scrollIndicatorInsets={SCROLL_INDICATOR_INSETS}/>)}
          </View>
        </SubscriptionScreenContext.Provider>
      </AppView>);
}, ({ colors }) => ({
    hasCustomTitle: true,
    headerColorOutputRange: [
        colors.background.dark,
        colors.cards.onDark,
    ],
}));
const styleSet = createStyleSheets((colors) => ({
    content: {
        flex: 1,
    },
    listEmptyView: {
        paddingVertical: rem(24),
    },
    listContent: {
        paddingTop: rem(24),
    },
    renderItem: {
        marginHorizontal: rem(16),
        backgroundColor: colors.cards.onDark,
        ...(isWeb && {
            padding: rem(8),
        }),
    },
    itemContainerFirst: {
        borderTopLeftRadius: rem(16),
        borderTopRightRadius: rem(16),
        ...(Platform.OS === 'ios' && {
            overflow: 'hidden',
        }),
    },
    itemContainerLast: {
        borderBottomLeftRadius: rem(16),
        borderBottomRightRadius: rem(16),
        ...(Platform.OS === 'ios' && {
            overflow: 'hidden',
        }),
    },
    indicatorContainer: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
}));
export default SubscriptionScreen;
