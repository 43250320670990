import React, { useState, useEffect, useLayoutEffect } from 'react';
import { ScrollView, StyleSheet } from 'react-native';
import upperFirst from 'lodash/upperFirst';
import { Indicator } from 'design-system/Indicator';
import { rem } from 'design-system/values';
import { SettingsSection } from 'design-system/SettingsSection';
import { AppView } from 'design-system/AppView';
import { SettingsItem, SettingsItemSwitch } from 'design-system/SettingsItem';
import * as Icons from 'design-system/icons';
import { SelectAppIconModal } from 'features/app-icons';
import withAnimatedNavigationHeader from 'design-system/hoc/withAnimatedNavigationHeader';
import useColorScheme from 'hooks/useColorScheme';
import useColors from 'hooks/useColors';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import { useAppSelector } from 'store/hooks';
import { useMainStackNavigation } from 'utils/types/navigationV6';
import { AppearanceModal } from '../appearance-settings/AppearanceModal';
import i18n from '../../../i18n';
import { usePreferences } from '../hooks';
import { HelpButton } from './AccountSettingsScreen';
import { isDesktop, isWeb } from '../../../constants';
const strings = {
    sectionName: 'Preferences',
    notifications: {
        title: 'Notifications',
        description: 'Get notified about important stuff',
    },
    appearance: {
        title: 'Appearance',
    },
    emmaIcons: {
        title: 'Emma Icons',
        description: 'Choose your favorite Emma icon',
    },
    emmaReports: {
        title: 'Emma Reports',
        description: 'Receive insights from Emma',
    },
    emmaQuiz: {
        title: 'Emma Quiz',
        description: 'Join our weekly trivia quiz',
    },
};
export const PreferenceSettingsList = withAnimatedNavigationHeader(({ navigation, onScroll }) => {
    const userId = useAppSelector((state) => state.user.user.id);
    useLayoutEffect(() => {
        if (!isWeb) {
            navigation.setOptions({
                headerRight: () => <HelpButton />,
            });
        }
    }, []);
    const { paddingHorizontalStyle } = useAppFrameDimensions(isWeb);
    const { navigate } = useMainStackNavigation();
    const colors = useColors();
    const { isQuizActive, isReportsActive, updateIsQuizActive, updateIsReportsActive, } = usePreferences();
    const colorScheme = useColorScheme();
    const [localIsReportsActive, setLocalIsReportsActive] = useState(isReportsActive);
    const [localIsQuizActive, setLocalIsQuizActive] = useState(isQuizActive);
    useEffect(() => {
        if (typeof isReportsActive !== 'undefined') {
            setLocalIsReportsActive(isReportsActive);
        }
    }, [isReportsActive]);
    useEffect(() => {
        if (typeof isQuizActive !== 'undefined') {
            setLocalIsQuizActive(isQuizActive);
        }
    }, [isQuizActive]);
    const handleUpdateIsReportsActive = async (newValue) => {
        setLocalIsReportsActive(newValue);
        try {
            await updateIsReportsActive(newValue);
        }
        catch (error) {
            setLocalIsReportsActive(!newValue);
        }
    };
    const handleUpdateIsQuizActive = async (newValue) => {
        setLocalIsQuizActive(newValue);
        try {
            await updateIsQuizActive(newValue);
        }
        catch (error) {
            setLocalIsQuizActive(!newValue);
        }
    };
    if (userId === -1) {
        return (<AppView type="secondary" withFrame={false}>
          <Indicator dark/>
        </AppView>);
    }
    return (<AppView withFrame={false}>
        <ScrollView onScroll={onScroll} style={paddingHorizontalStyle} scrollEventThrottle={16}>
          <SettingsSection noMarginTop style={styles.marginTop}>
            <SettingsItem title={strings.notifications.title} description={strings.notifications.description} icon={<Icons.IconBell />} onPress={() => navigate('Notifications')}/>
            {!isWeb && (<SettingsItem title={strings.appearance.title} description={colorScheme ? upperFirst(colorScheme) : ''} icon={<Icons.IconPaint />} onPress={() => AppearanceModal.show(colors)}/>)}
            {!isDesktop && !isWeb && (<SettingsItem title={strings.emmaIcons.title} description={i18n.t('chooseFavouriteIcon')} icon={<Icons.IconEmma />} onPress={SelectAppIconModal.show}/>)}
            <SettingsItemSwitch value={localIsReportsActive} title={strings.emmaReports.title} onValueChange={handleUpdateIsReportsActive} description={strings.emmaReports.description} icon={<Icons.IconActivity />}/>
            <SettingsItemSwitch value={localIsQuizActive} onValueChange={handleUpdateIsQuizActive} title={strings.emmaQuiz.title} description={strings.emmaQuiz.description} icon={<Icons.IconTrophy />}/>
          </SettingsSection>
        </ScrollView>
      </AppView>);
}, ({ colors }) => ({
    title: strings.sectionName,
    headerColorOutputRange: [
        colors.background.dark,
        colors.cards.onDark,
    ],
}));
const styles = StyleSheet.create({
    marginTop: {
        marginTop: rem(16),
    },
});
